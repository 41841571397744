
    var riot = require('riot')
    
riot.tag2('userpanel', '<div class="row"> <div class="col-sm-12"> <panel heading="Users" style="min-height:500px;height:calc(100vh - 180px)" class="row3"> <userlist stores="{parent.opts.stores}" onselect="{parent.handleClassSelect}" path="user" page="{parent.opts.page}"></userlist> </panel> </div> </div> <routehandler></routehandler>', '', '', function(opts) {
this.selected = false;

this.handleClassSelect = (function(_this) {
  return function(selectedUser) {
    _this.selected = selectedUser;
    return _this.update();
  };
})(this);
});
    
  