module.exports = 

  class QuestionStore

    constructor:(riot,Resource)->
      riot.observable(@)
      @data = null
      @cache = []
      @vidcache = []
      @filtered = []
      @data_state = []
      @resource = new Resource("/duocms/api/questions")
      @on 'question_sync',@sync
      @on 'question_get',@get

    load:(video_id)->
      if @vidcache[video_id]?
        @data = @vidcache[video_id]
        #setTimeout => # force async for ie - don't do, breaks maths input.
        @trigger 'question_changed',@data
      else if !@data_state[video_id]? # prevent reload
        @data_state[video_id] = 'loading'
        @resource.get {video_id:video_id},(@data)=>
          @vidcache[video_id] = @data
          @data.forEach (question,qid)=> 
            question.answer.forEach (answer,aid)=>
              if answer
                answer.text = @escapeBraces(answer.text)
                answer.prefix = @escapeBraces(answer.prefix)
                answer.suffix = @escapeBraces(answer.suffix)
          @trigger 'question_changed',@data

    get:(id)-> #get single question by id
      return if !id
      if @cache[id]?
        @data = @cache[id]
        @trigger 'question_one',@data
      @resource.get {id:id},(@data)=>
        if @data
          id = @data.id
          @cache[id] = @data
        @trigger 'question_one',@data   

    sync:(video_id)->
      return if !video_id
      return @load(video_id)

    escapeBraces:(str)->
      return str.replace(/(^|[^\\])((\\\\)*\{)/g,'$1\\$2') if str && typeof str == "string"
      return str