module.exports = 

  class LogStore

    constructor:(riot,Resource,@utils)->
      riot.observable(@)
      @resource = new Resource("/duocms/api/logs")
      @logs = {}
      @on 'log_sync',@sync
      @on 'log_save',@save
      @on 'log_load',@load

    load:(name,params={})->
      params.filter = name
      cacheKey = name + JSON.stringify(params) || ''
      return if !name
      @logs[cacheKey] = 'loading'
      @resource.get params,(data)=>
        @logs[cacheKey] = data
        @sync(name,params)

    sync:(name,params={})->
      params.filter = name # needs to be done here so cache keys are the same..
      cacheKey = name + JSON.stringify(params) || ''
      return @load(name,params) if !@logs[cacheKey]?
      return if @logs[cacheKey]=='loading'
      @trigger 'logs_changed_'+name,@logs[cacheKey]

    save:(data)->
      @resource.save data,(@data)=>
        @trigger 'log_saved',@data #closes modal