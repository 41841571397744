
    var riot = require('riot')
    
riot.tag2('classrestoremodal', '<modal id="classmodal" bssize="lg" esckey="{true}" title="{\'Restore Deleted Classes - \'+(loading ? \'Loading...\' : gridrows.length)}" onclose="{handleClose}" onsave="{handleSave}" savetext="Restore Selected"> <grid columns="{parent.gridColumns}" multiselect="{true}" data="{parent.gridrows}" height="300px" onselect="{parent.handleSelect}"></grid> </modal>', '', '', function(opts) {
var Resource, RiotControl, request, resourceUtils;

request = require('superagent');

RiotControl = require('riotcontrol');

resourceUtils = require('../libs/resourceUtils.coffee');

Resource = require('../libs/resource.coffee')(request, RiotControl, resourceUtils);

this.gridColumns = [
  {
    field: 'id',
    label: '#',
    width: '10%'
  }, {
    field: 'name',
    label: 'Class Name',
    width: '40%'
  }, {
    field: 'year',
    label: 'Year',
    width: '10%'
  }, {
    field: 'deleted_by',
    label: 'Deleted By',
    width: '20%'
  }, {
    field: 'deleted_at',
    label: 'Deleted At',
    width: '20%',
    tag: "griddate"
  }
];

this.gridrows = [];

this.selected = [];

this.multiselect = [];

this.handleClose = (function(_this) {
  return function() {
    _this.saving = false;
    return _this.tags.modal.hide();
  };
})(this);

this.show = (function(_this) {
  return function() {
    _this.saving = false;
    _this.tags.modal.show();
    _this.loading = true;
    _this.update();
    return new Resource("/duocms/api/classrooms?section=deleted").get(function(data) {
      _this.gridrows = data;
      _this.loading = false;
      return _this.update();
    });
  };
})(this);

this.handleSelect = (function(_this) {
  return function(row, rows) {
    _this.selected = row;
    _this.multiselect = rows;
    return _this.update();
  };
})(this);

this.handleSave = (function(_this) {
  return function() {
    var classroom;
    if (_this.saving) {
      return;
    }
    _this.saving = true;
    classroom = {
      id: _this.multiselect.map(function(u) {
        return u.id;
      }).join(","),
      deleted_at: null,
      restore: true
    };
    return opts.stores.trigger('class_save', classroom, _this.handleClose);
  };
})(this);
});
    
  