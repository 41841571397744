
    var riot = require('riot')
    
riot.tag2('renewform-form', '<form onsubmit="{opts.submit}" __disabled="{opts.sending}" class="form-horizontal"> <div class="row"> <input type="hidden" name="_csrf" value="{window.csrf}"> <input type="hidden" name="username" value="{opts.user.username}"> <div class="col-xs-12 col-md-6"> <table class="table table-striped"> <thead> <tr> <th class="col-xs-7">Product</th> <th class="col-xs-3"> Price</th> <th style="text-align:center" class="col-xs-2"> Order</th> </tr> </thead> <tbody class="ticks"> <tr each="{opts.types}"> <td>{label}</td> <td>£{price} <small>(+vat)</small></td> <td style="text-align:center"> <input type="checkbox" name="{name}" __checked="{parent.opts.org[name]}" onchange="{parent.opts.recalc}"> </td> </tr> <tr> <td class="text-right">Subtotal</td> <td colspan="2">£{opts.subtotal} <small>(+vat)</small><br><span if="{opts.saving}" class="label label-success">£{opts.saving} saving</span></td> </tr> </tbody> </table> </div> <div class="col-xs-12 col-md-6"> <input type="hidden" name="quoted" value="£{opts.subtotal} +vat"> <forminput type="text" name="billing_contact" label="Billing Contact" value="{opts.org.billing_contact}"></forminput> <forminput type="text" name="org_name" label="Organisation Name" value="{opts.org.name}"></forminput> <formtextarea type="text" name="address" label="Address" value="{opts.org.address}"></formtextarea> <forminput type="text" name="postcode" label="Postcode" value="{opts.org.postcode}"></forminput> <forminput type="text" name="billing_email" label="Billing Email" value="{opts.org.users[0].email}"></forminput> <forminput type="text" name="purchase_order" label="Order Number" value=""></forminput> </div> </div> <div class="row"> <div class="col-xs-12"> <hr> <button type="submit" class="btn btn-primary pull-right"> <span if="{opts.sending}" class="fa fa-spinner fa-spin"> </span> Submit Order</button> </div> </div> </form>', '', '', function(opts) {
});
riot.tag2('renewform-thankyou', '<h3>Thank you</h3> <p>{opts.message}.</p>', '', '', function(opts) {
});
riot.tag2('renewform', '<panel heading="Order Form"> <div if="{parent.error}" class="alert alert-danger"> <p>Your message was not sent. {parent.error.message}</p> </div> <div if="{org}"> <h3>Please submit this form to renew your original package or bundle.</h3> <p>If you would like to order any additional packages please tick the appropriate boxes.</p> <hr> </div> <subtag tag="{parent.tag}" user="{user}" org="{org}" types="{parent.types}" subtotal="{parent.subtotal}" saving="{parent.saving}" recalc="{parent.recalc}" submit="{parent.submit}" message="{message}" sending="{parent.sending}"></subtag> </panel>', '', '', function(opts) {

/*
AS Level = £150 +VAT
GCSE = £375 + VAT
GCSE (WJEC) = £375 + VAT
KS3 = £150 + VAT
PRIMARY = £99 + VAT

AS Level + GCSE = £450 + VAT
AS Level + GCSE (WJEC) = £450 + VAT
GCSE + KS3 = £450 + VAT
GCSE (WJEC) + KS3 = £450 + VAT

AS Level + GCSE + KS3 = £500 + VAT
AS Level + GCSE (WJEC) + KS3 = £500 + VAT
 */
var request;

request = require('superagent');

this.types = [
  {
    name: "aslevel",
    label: "AS Level package",
    price: "150"
  }, {
    name: "gcse",
    label: "GCSE package",
    price: "375"
  }, {
    name: "wjec",
    label: "GCSE (WJEC) package",
    price: "375"
  }, {
    name: "igcse_cambridge",
    label: "IGCSE (cambridge) package",
    price: "375"
  }, {
    name: "igcse_edexcel",
    label: "IGCSE (Edexcel) package",
    price: "375"
  }, {
    name: "ks3",
    label: "KS3 package",
    price: "150"
  }, {
    name: "primary",
    label: "Primary package",
    price: "100"
  }, {
    name: "wonde_id",
    label: "Data Integration via Wonde",
    price: "30"
  }
];

this.tag = "renewform-form";

this.message = "";

this.on('mount', function() {
  this.subtotal = 0;
  this.saving = 0;
  this.user = {};
  if (opts.stores) {
    opts.stores.on('session_changed', this.sessionUpdated);
    return opts.stores.trigger('session_sync');
  }
});

this.on('unmount', function() {
  if (opts.stores) {
    return opts.stores.off('session_changed', this.sessionUpdated);
  }
});

this.sessionUpdated = (function(_this) {
  return function(data) {
    _this.update({
      user: data.user,
      org: data.org
    });
    return setTimeout((function() {
      return _this.recalc();
    }), 500);
  };
})(this);

this.getFormData = (function(_this) {
  return function(node) {
    var formdata, inputs;
    inputs = [].slice.call(node.querySelectorAll('[name]'));
    formdata = {};
    inputs.filter(function(input) {
      return input.name;
    }).forEach(function(input) {
      if (input.type === "checkbox") {
        return formdata[input.name] = input.checked;
      } else {
        return formdata[input.name] = input.value;
      }
    });
    return formdata;
  };
})(this);

this.recalc = (function(_this) {
  return function() {
    var fullprice, productcount, selected, ticks;
    _this.saving = 0;
    ticks = [].slice.call(_this.root.querySelectorAll('.ticks input'));
    selected = ticks.filter(function(elem) {
      return elem.checked;
    }).map(function(elem) {
      return elem.name;
    });
    if (selected.length === 0) {
      _this.subtotal = 0;
    } else {
      _this.subtotal = _this.types.filter(function(type) {
        return selected.indexOf(type.name) > -1;
      }).map(function(type) {
        return type.price;
      }).reduce((function(a, b) {
        return +a + +b;
      }), 0);
    }
    fullprice = _this.subtotal;
    productcount = (selected.filter(function(p) {
      return p !== "primary" && p !== "wonde_id";
    })).length;
    if (productcount > 1) {
      _this.subtotal -= 75;
    }
    if (productcount > 2) {
      _this.subtotal -= 100;
    }
    _this.saving = fullprice - _this.subtotal;
    return _this.update();
  };
})(this);

this.disableForm = (function(_this) {
  return function() {
    var inputs;
    inputs = [].slice.call(_this.root.querySelectorAll('[name]'));
    return inputs.forEach(function(tag) {
      return tag.setAttribute('disabled', 'disabled');
    });
  };
})(this);

this.enableForm = (function(_this) {
  return function() {
    var inputs;
    inputs = [].slice.call(_this.root.querySelectorAll('[name]'));
    return inputs.forEach(function(tag) {
      return tag.removeAttribute('disabled');
    });
  };
})(this);

this.submit = (function(_this) {
  return function(e) {
    var data;
    if (_this.subtotal < 1) {
      return _this.showError({
        message: 'Please select some products'
      });
    }
    _this.update({
      sending: true
    });
    _this.disableForm();
    data = _this.getFormData(_this.root);
    return request.post("/duocms/api/contact/order").send(data).csrf().end(function(err, res) {
      if (err) {
        _this.showError(res.body);
        _this.enableForm();
        return;
      }
      if (!err) {
        return _this.update({
          tag: "renewform-thankyou",
          message: res.body.message,
          sending: false
        });
      }
    });
  };
})(this);

this.showError = (function(_this) {
  return function(error) {
    error.message += ".";
    _this.update({
      error: error,
      sending: false
    });
    return setTimeout((function() {
      return _this.update({
        error: false
      });
    }), 3000);
  };
})(this);
});
    
  