
    var riot = require('riot')
    
riot.tag2('linechart', '<canvas name="canv" height="{opts.height || 400}" width="{opts.width || 400}"></canvas>', 'linechart{display:block;}', '', function(opts) {
var Chart;

Chart = require('chart.js');

this.chart = null;

this.on('mount', function() {
  var ctx;
  Chart.defaults.global.responsive = true;
  ctx = this.canv.getContext("2d");
  return this.chart = new Chart(ctx, {
    type: "line",
    data: opts.data,
    options: opts.options
  });
});

this.on('update', (function(_this) {
  return function() {
    if (_this.chart) {
      _this.chart.data.datasets.length = opts.data.datasets.length;
      opts.data.datasets.forEach(function(dataset, idx) {
        return _this.chart.data.datasets[idx] = dataset;
      });
      return _this.chart.update();
    }
  };
})(this));
});
    
  