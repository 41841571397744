module.exports = 

  class VideoStore

    constructor:(riot,Resource,@groupby,@utils,@natsort)->
      riot.observable(@)
      @data = null
      @filtered = []
      @resource = new Resource("/duocms/api/videos")
      @on 'video_load',@load
      @on 'video_sync',@sync

    load:->
      @data_state = 'loading'
      @resource.get (data)=> 
        @data = data.sort (a,b)=> @natsort(a['clip_id'],b['clip_id'])
        @sync()
        @data_state = 'loaded'

    sync:->
      return @load() if !@data_state
      return if !@data
      setTimeout => # force async for ie
        @trigger 'video_changed',@data
  
    groupSort:(collection,field)->
      @groupby(collection,field)
        .sort (a,b)->a[field] > b[field]
        .map (video)-> label:video[field],value:video[field]