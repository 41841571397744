module.exports = 
  
  class Rotate extends require './transform.coffee'

    dragMove:(e)=>
      e.preventDefault()
      e.stopPropagation()
      base = @elem.parentNode.getBoundingClientRect()
      elemOffset = @elem.getBoundingClientRect()
      if e.touches
        touch = e.touches[0]
        mousePoint = left:touch.pageX-base.left,top:touch.pageY-base.top
      else
        mousePoint = left:e.pageX-base.left,top:e.pageY-base.top
      if @options?.center?
        pivot= {left:@options.center.left+@elem.offsetLeft,top: @options.center.top+@elem.offsetTop}
      else
        pivot = {left:@elem.offsetLeft,top:@elem.offsetTop}

      # correct for scrolling (cross browser stuff)
      supportPageOffset = window.pageXOffset != undefined
      isCSS1Compat = ((document.compatMode || "") == "CSS1Compat")      
      x = if supportPageOffset then window.pageXOffset else if isCSS1Compat then document.documentElement.scrollLeft else  document.body.scrollLeft
      y = if supportPageOffset then window.pageYOffset else if isCSS1Compat then document.documentElement.scrollTop  else document.body.scrollTop
      pivot.top += y
      pivot.left += x

      if @options.helpers?
        # tools to help position center - these could be removed?
        dot = document.querySelector('#dot')
        if !dot
          dot = document.createElement('div')
          dot.setAttribute('id','dot')
          dot.setAttribute('style','border:1px solid red;z-index:100000;position:absolute;')
          @elem.parentNode.appendChild(dot)
        @elem.parentNode.style.border = "1px solid red"
        dot.style.left = pivot.left+"px"
        dot.style.top = pivot.top+"px"
      
      radians = Math.atan((pivot.left - mousePoint.left) / (mousePoint.top - pivot.top))
      radians += Math.PI if (mousePoint.top - pivot.top) < 0
      radians += Math.PI / 2

      if !@start?.offsetRadians
        @start.offsetRadians = radians-@getAngle(@elem).radians 
      else
        radians -= @start.offsetRadians 
        radians = Math.round(radians*100)/100
        @update(radians)
        @options.onRotate(radians) if @options?.onRotate

    update:(radians)=>
      @elem.style.webkitTransform  = "rotate("+radians+"rad)"
      @elem.style.mozTransform  = "rotate("+radians+"rad)"
      @elem.style.transform  = "rotate("+radians+"rad)"





