
    var riot = require('riot')
    
riot.tag2('grid', '<div riot-style="padding-right:{headPad}px" class="gridheader"> <div each="{opts.columns}" riot-style="width:{width}" onclick="{handleReorder}" class="gridcell {field==orderBy &amp;&amp; \'order\'} {orderDir}"> <tip tip="{tip}">{label}</tip> </div> </div> <div onscroll="{scrolling}" riot-style="height:{parseInt(opts.height,10)-30}px" class="gridbody"> <div riot-style="position:relative;height:{rowheight*opts.data.length}px;" class="scrollblock"> <div each="{row, i in visibleRows}" no-reorder riot-style="top:{parent.rowheight*(i+parent.scrollTop)}px" ondblclick="{handleDblClick}" onclick="{handleClick}" class="gridrow {active:parent.activeRows.indexOf(row)&gt;-1}"> <div each="{parent.opts.columns}" no-reorder riot-style="width:{width}" class="gridcell"> <grid-celltag row="{parent.row}" field="{field}" dateformat="{dateformat}" tick="{tick}" tag="{tag}"></grid-celltag> </div> </div> </div> </div>', 'grid { position: relative; display: block; border: 1px solid #ccc; } grid .gridheader { background: #ddd; position: absolute; top: 0; right: 0; left: 0; border-bottom: 1px solid #ccc; height: 40px; } grid .gridheader .gridcell { position: relative; cursor: pointer; line-height: 30px; } grid .gridheader .order:before { position: absolute; top: 3px; right: 10px; font-family: FontAwesome; -webkit-font-smoothing: antialiased; content: "\\f0d8"; } grid .gridheader .order.desc:before { content: "\\f0d7"; } grid .gridcell { display: inline-block; padding: 0 10px 0 10px; line-height: 30px; white-space: nowrap; overflow: hidden; border-right: 1px solid rgba(0,0,0,0.1); color: transparent; text-shadow: 0 0 0 #1c4c7d; } grid feedback-icon { text-shadow: none; } grid .gridbody { margin-top: 55px; display: block; overflow: auto; } grid .gridrow { cursor: pointer; position: absolute; overflow: hidden; height: 30px; left: 0; right: 0; } grid .gridrow:nth-of-type(odd) { background: #eee; } grid .gridrow.active { background: #888; color: #fff; }', '', function(opts) {
var natsort;

natsort = require("../libs/natsort.js");

this.active = false;

this.rowheight = opts.rowheight || 30;

this.scrollTop = 0;

this.scrollBottom = 10;

this.prevScrollTop = -1;

this.mountcount = 0;

this.prevHeadPad = 0;

this.headPad = 0;

this.activeRows = [];

this.orderBy = null;

this.orderDir = null;

this.on('mount', (function(_this) {
  return function() {
    return _this.selectAndScroll(opts.active, opts.activeid);
  };
})(this));

this.on('update', function() {
  this.gridbody = this.root.querySelector(".gridbody");
  if (!opts.data || !this.gridbody) {
    return;
  }
  this.orderData = this.sort(opts.data);
  this.selectAndScroll(opts.active, opts.activeid);
  this.scrollTop = Math.round((this.gridbody.scrollTop / this.rowheight) / 2) * 2 - 10;
  if (this.scrollTop < 0) {
    this.scrollTop = 0;
  }
  this.scrollBottom = this.scrollTop + Math.round((this.gridbody.offsetHeight / this.rowheight) / 2) * 2 + 20;
  return this.visibleRows = this.orderData.slice(this.scrollTop, this.scrollBottom);
});

this.on('updated', function() {
  var idx, ref, ref1, ref2, scrollTop;
  this.headPad = ((ref = this.root.querySelector('.gridheader')) != null ? ref.offsetWidth : void 0) - ((ref1 = this.root.querySelector('.gridrow')) != null ? ref1.offsetWidth : void 0);
  if ((this.headPad != null) && this.headPad !== this.prevHeadPad && !isNaN(this.headPad)) {
    this.prevHeadPad = this.headPad;
    this.update();
  }
  if (this.scrollTo) {
    this.scrollTo = null;
    idx = (ref2 = this.orderData) != null ? ref2.indexOf(this.active) : void 0;
    if (idx > -1) {
      scrollTop = this.rowheight * idx;
      if (scrollTop < this.gridbody.scrollTop || scrollTop > this.gridbody.scrollTop + this.gridbody.offsetHeight) {
        return this.gridbody.scrollTop = scrollTop;
      }
    }
  }
});

this.selectAndScroll = (function(_this) {
  return function(active, active_id) {
    var ref;
    if (!active && !active_id) {
      return;
    }
    _this.active = active ? active : active_id ? (ref = _this.orderData.filter(function(row) {
      return +row.id === +active_id;
    })) != null ? ref[0] : void 0 : null;
    if (!_this.active || !_this.orderData || (_this.active === _this.prev_active && _this.prev_rowcount === _this.orderData.length)) {
      return;
    }
    _this.prev_active = _this.active;
    _this.prev_rowcount = _this.orderData.length;
    _this.activeRows = [_this.active];
    return _this.scrollTo = true;
  };
})(this);

this.scrolling = (function(_this) {
  return function(e) {
    e.preventUpdate = true;
    return _this.update();
  };
})(this);

this.handleClick = (function(_this) {
  return function(e) {
    var ref;
    e.preventUpdate = true;
    if (!opts.onselect) {
      return;
    }
    if (!e.metaKey && !e.shiftKey && !e.ctrlKey) {
      _this.deselect();
    }
    if (e.metaKey || e.ctrlKey) {
      _this.toggleRow(e.item.row);
    } else if (e.shiftKey && opts.multiselect) {
      _this.selectBetween(e.item.row);
      if ((ref = document.getSelection()) != null) {
        if (typeof ref.removeAllRanges === "function") {
          ref.removeAllRanges();
        }
      }
    } else {
      _this.selectRow(e.item.row);
    }
    return opts.onselect(_this.active, _this.activeRows);
  };
})(this);

this.selectRow = (function(_this) {
  return function(row) {
    _this.active = row;
    if (opts.multiselect) {
      return _this.activeRows.push(row);
    } else {
      return _this.activeRows = [row];
    }
  };
})(this);

this.toggleRow = (function(_this) {
  return function(row) {
    if (_this.active !== row) {
      _this.active = row;
    } else {
      _this.active = null;
    }
    if (_this.activeRows.indexOf(row) > -1) {
      return _this.deselectRow(row);
    } else {
      return _this.selectRow(row);
    }
  };
})(this);

this.selectBetween = (function(_this) {
  return function(row) {
    var i, index1, index2, key, ref, ref1, results;
    index1 = null;
    if (typeof _this.active === "number") {
      _this.orderData.forEach(function(row, idx) {
        return _this.active === row.id && (index1 = idx);
      });
    } else {
      index1 = _this.orderData.indexOf(_this.active);
    }
    index2 = _this.orderData.indexOf(row);
    results = [];
    for (key = i = ref = Math.min(index1, index2), ref1 = Math.max(index1, index2); ref <= ref1 ? i <= ref1 : i >= ref1; key = ref <= ref1 ? ++i : --i) {
      if (_this.activeRows.indexOf(_this.orderData[key]) < 0) {
        results.push(_this.activeRows.push(_this.orderData[key]));
      } else {
        results.push(void 0);
      }
    }
    return results;
  };
})(this);

this.deselect = (function(_this) {
  return function() {
    _this.active = null;
    return _this.activeRows.length = 0;
  };
})(this);

this.deselectRow = (function(_this) {
  return function(row) {
    return _this.activeRows.splice(_this.activeRows.indexOf(row), 1);
  };
})(this);

this.handleDblClick = (function(_this) {
  return function(e) {
    if (!opts.onedit) {
      return;
    }
    _this.active = e.item.row;
    return setTimeout(function() {
      if ((opts.onedit != null) && typeof opts.onedit === "function") {
        return opts.onedit(e.item.row);
      }
    }, 50);
  };
})(this);

this.sort = (function(_this) {
  return function(data) {
    var orderdata;
    if (!_this.orderBy) {
      return data;
    }
    orderdata = data.slice();
    if (!_this.orderDir || _this.orderDir === "asc") {
      orderdata = orderdata.sort(function(a, b) {
        return natsort(a[_this.orderBy], b[_this.orderBy]);
      });
    } else {
      orderdata = orderdata.sort(function(a, b) {
        return natsort(b[_this.orderBy], a[_this.orderBy]);
      });
    }
    return orderdata;
  };
})(this);

this.handleReorder = (function(_this) {
  return function(e) {
    if (_this.orderBy === e.item.field && _this.orderDir === "asc") {
      _this.orderDir = "desc";
    } else if (_this.orderBy === e.item.field && _this.orderDir === "desc") {
      _this.orderDir = null;
      _this.orderBy = null;
    } else {
      _this.orderDir = "asc";
      _this.orderBy = e.item.field;
    }
    return _this.update();
  };
})(this);
});
riot.tag2('grid-celltag', '<div riot-tag="{opts.tag}">{outputCell(opts.row,opts.field,opts.dateformat,opts.tick)}</div>', '', '', function(opts) {
var Formatter;

Formatter = require('riot-kit/utils/dateformat.coffee');

this.prevtag = null;

this.on('mount', function() {
  if (!opts.tag) {
    return;
  }
  this.prevtag = opts.tag;
  return this.mountedTag = riot.mount(this.root.querySelector('div'), opts.tag, opts)[0];
});

this.on('update', function() {
  if (this.prevtag && this.prevtag !== opts.tag) {
    this.prevtag = opts.tag;
    this.mountedTag.unmount(true);
    return this.mountedTag = riot.mount(this.root.querySelector('div'), opts.tag, opts)[0];
  } else if (this.mountedTag) {
    this.mountedTag.opts = opts;
    return this.mountedTag.update();
  }
});

this.on('unmount', function() {
  if (this.mountedTag) {
    return this.mountedTag.unmount(true);
  }
});

this.outputCell = (function(_this) {
  return function(row, field, dateformat, tick, messages) {
    if (dateformat && row[field] && row[field] instanceof Date) {
      return new Formatter(row[field]).format(dateformat);
    }
    if (tick) {
      if (row[field]) {
        return tick;
      } else {
        return "";
      }
    }
    return row[field];
  };
})(this);
});
riot.tag2('gridfeedbacktitle', '<feedback-icon added="{opts.row.feedback_added}" unread="{opts.row.feedback_unread}" style="float:right"></feedback-icon><span>{opts.row[opts.field]} </span><span if="{opts.row.link_ids.length}" title="linked to &#13;{opts.row.links.join(\' &#13;\')}"><span style="color:rgba(254, 198, 0,1);text-shadow:none" class="fa fa-circle"></span><span style="color:rgba(254, 198, 0,0.5);text-shadow:none;margin-left:-5px" class="fa fa-circle"></span></span>', '', '', function(opts) {
});
riot.tag2('gridlock', '<div if="{opts.row[opts.field]}" title="This assignment has been locked, answers can no longer be updated." class="fa fa-lock"></div>', '', '', function(opts) {
});
riot.tag2('gridlocklink', '<div if="{opts.row[opts.field]}" title="This assignment has been locked. Click padlock to unlock." class="fa fa-lock"></div>', '', '', function(opts) {
});
riot.tag2('gridtick', '<div class="text-center"><span if="{opts.row[opts.field]}" title="This user is linked to Wonde" class="fa fa-check"></span></div>', '', '', function(opts) {
});
riot.tag2('gridunread', '<div style="color:#5ce25c" class="text-center"><span if="{!opts.row[opts.field]}" title="This message is unread" class="fa fa-circle"></span></div>', '', '', function(opts) {
});
riot.tag2('griddate', '<feedback-icon added="{opts.row.feedback_added}" unread="{opts.row.feedback_unread}" style="float:right"></feedback-icon><span>{val}</span>', '', '', function(opts) {
this.on("update", function() {
  var dt;
  dt = opts.row[opts.field];
  return this.val = dt ? dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear() : "";
});
});
    
  