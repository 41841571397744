module.exports = 

  class PrefsStore

    constructor:(riot)->
      riot.observable(@)
      try
        @prefs = JSON.parse(window.sessionStorage.getItem('prefs')) || {}
      catch err
        console.error(err)
        @prefs = {}
      @on 'prefs_save',@save
      @on 'prefs_sync',@sync

    save:({name,value})->
      @prefs[name] = value 
      window.sessionStorage.setItem("prefs",JSON.stringify(@prefs))
      @sync()

    sync:->
      @trigger 'prefs_updated',@prefs