  <div class="toolwrap">
    <slot />
    <div class="tooltip fade {placement} {tipclass}" id="toooltip"  name='pop' style='display:block;'>
      <div class="tooltip-arrow"></div>
      <div class="tooltip-inner">
        {tip}
      </div>  
    </div>
  </div>
        
  <script>export let tip = "",
  placement = "top",
  tipclass = "";</script>

<style>
  .tooltip{
    display:inline-block;
    opacity:0;
    pointer-events: none;
  }
  .toolwrap:hover .tooltip{
    opacity:1;
  }

  .tooltip:global(.bottom){
    left:50%;
    transform: translate(-50%);
  }

  .tooltip .tooltip-inner{
    border-radius:5px;
    box-shadow:0 2px 10px rgba(0,0,0,0.5);
    display:block;
  }
</style>