
    var riot = require('riot')
    
riot.tag2('classmodal', '<modal id="classmodal" onclose="{handleClose}" onsave="{handleSave}" title="{opts.params.class_id != \'new\' ? \'Edit Class Details\' : \'Add New Class\'}"> <form class="form-horizontal"> <forminput id="name" onupdate="{parent.handleChange}" value="{parent.classroom.name}" label="Class Name" width1="3" width2="9"></forminput> <forminput id="year" onupdate="{parent.handleChange}" value="{parent.classroom.year}" label="Year" width1="3" width2="9"></forminput> <formselect id="qualification" label="Qualification" if="{parent.labels}" options="{parent.qualifications}" value="{parent.classroom.qualification}" onupdate="{parent.handleChange}" width1="3" width2="9"></formselect> <formselect id="level" show="{parent.levels.length}" label="Default Level" if="{parent.labels}" options="{parent.levels}" value="{parent.classroom.level}" onupdate="{parent.handleChange}" width1="3" width2="9"></formselect> <formtextarea id="description" rows="7" onupdate="{parent.handleChange}" value="{parent.classroom.description}" label="Description" width1="3" width2="9"></formtextarea> <forminput id="wonde_id" onupdate="{parent.handleChange}" value="{parent.classroom.wonde_id}" label="Wonde Id" if="{parent.isAdmin}" width1="3" width2="9"></forminput> <div class="form-group"> <label class="control-label col-sm-3">Students ({parent.classroom.users.length})</label> <div class="col-sm-9 addClassList"> <grid columns="{parent.gridColumns}" multiselect="{true}" data="{parent.students}" height="300px" onselect="{parent.toggleUserSelect}"></grid> </div> </div> </form> </modal>', '.addClassList grid-celltag{ font-size:12px; }', '', function(opts) {
this.years = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(function(y) {
  return {
    value: y,
    label: y
  };
});

this.users = [];

this.tavalue = '';

this.newuser = null;

this.typeahead = null;

this.selecteduser = null;

this.idsinclass = [];

this.labels = require('../../client/labels.js');

this.gridColumns = [
  {
    field: 'first_name',
    label: 'First Name',
    width: '35%'
  }, {
    field: 'surname',
    label: 'Surname',
    width: '35%'
  }, {
    field: 'year',
    label: 'Yr',
    width: '7%'
  }, {
    field: 'classes',
    label: 'Classes',
    width: '23%'
  }
];

this.on('mount', function() {
  this.tags.modal.show();
  this.classroom = {
    users: []
  };
  opts.stores.on('class_changed', this.handleClassStoreChange);
  opts.stores.on('class_saved', this.handleSaved);
  opts.stores.on('users_changed', this.handleUsers);
  opts.stores.on('session_changed', this.sessionUpdated);
  opts.stores.trigger('session_sync');
  opts.stores.trigger('user_sync');
  if (opts.params.class_id !== "new") {
    this.id = opts.params.class_id;
    return opts.stores.trigger('class_get', this.id);
  } else {
    return this.classroom = {
      name: "New Class",
      users: []
    };
  }
});

this.on('unmount', function() {
  opts.stores.off('class_changed', this.handleClassStoreChange);
  opts.stores.off('class_saved', this.handleSaved);
  opts.stores.off('session_changed', this.sessionUpdated);
  opts.stores.off('user_saved', this.userUpdated);
  return opts.stores.off('users_changed', this.handleUsers);
});

this.on('update', function() {
  var ref, ref1, ref2, ref3, ref4;
  if (this.classroom && this.qualifications && !this.classroom.qualification) {
    this.updateDefaultQualification();
  }
  if (((ref = this.classroom) != null ? ref.qualification : void 0) != null) {
    this.updateLevels();
  } else {
    this.levels = [];
  }
  if (!((ref1 = this.classroom) != null ? ref1.level : void 0) && +((ref2 = this.classroom) != null ? ref2.year : void 0) >= 4 && ((ref3 = this.classroom) != null ? (ref4 = ref3.qualification) != null ? ref4.match(/primary/i) : void 0 : void 0)) {
    return this.classroom.level = this.classroom.year;
  }
});

this.updateDefaultQualification = (function(_this) {
  return function() {
    var qual, yr;
    yr = +_this.classroom.year;
    qual = _this.qualifications.filter(function(q) {
      return (yr < 7 && q.value === "Primary") || (yr < 10 && q.value === "Key Stage 3") || ((yr > 6 || yr < 12) && q.value.match(/GCSE/)) || (yr > 11 && q.value.match(/AS Level/));
    });
    if (qual.length) {
      return _this.classroom.qualification = qual[0].value;
    }
  };
})(this);

this.updateLevels = (function(_this) {
  return function() {
    _this.levels = [];
    if (_this.classroom.qualification.match(/GCSE/i)) {
      _this.levels = [
        {
          value: "",
          label: "All"
        }, {
          value: "Foundation",
          label: "Foundation"
        }, {
          value: "Higher",
          label: "Higher"
        }
      ];
    }
    if (_this.classroom.qualification.match(/WJEC/i)) {
      _this.levels = [
        {
          value: "",
          label: "All"
        }, {
          value: "Foundation",
          label: "Foundation"
        }, {
          value: "Intermediate",
          label: "Intermediate"
        }, {
          value: "Higher",
          label: "Higher"
        }
      ];
    }
    if (_this.classroom.qualification.match(/key stage/i)) {
      _this.levels = [
        {
          value: "",
          label: "All"
        }, {
          value: "Emerging",
          label: "Emerging"
        }, {
          value: "Expected",
          label: "Expected"
        }, {
          value: "Exceeding",
          label: "Exceeding"
        }, {
          value: "Excelling",
          label: "Excelling"
        }
      ];
    }
    if (_this.classroom.qualification.match(/primary/i)) {
      return _this.levels = [
        {
          value: "",
          label: "All"
        }, {
          value: "4",
          label: "Year 4"
        }, {
          value: "5",
          label: "Year 5"
        }, {
          value: "6",
          label: "Year 6"
        }
      ];
    }
  };
})(this);

this.toggleUserSelect = (function(_this) {
  return function(user) {
    _this.classroom.users = _this.classroom.users.includes(user) ? _this.classroom.users.filter(function(u) {
      return u !== user;
    }) : _this.classroom.users.concat(user);
    _this.tags.modal.tags.grid.activeRows = _this.classroom.users.slice();
    return _this.update();
  };
})(this);

this.sessionUpdated = (function(_this) {
  return function(session) {
    var groups, ref, ref1;
    _this.session = session;
    groups = ((ref = _this.session.user) != null ? ref.groups : void 0) ? _this.session.user.groups.map(function(group) {
      return group.name;
    }) : [];
    _this.setupQualifications(_this.session.org);
    if ((ref1 = _this.session.user) != null ? ref1.wonde_id : void 0) {
      _this.is_wonde = true;
    }
    if (groups.indexOf('Administrators') > -1) {
      _this.isAdmin = true;
    }
    return _this.update();
  };
})(this);

this.setupQualifications = (function(_this) {
  return function(org) {
    _this.qualifications = [];
    if (org.aslevel) {
      _this.qualifications.push({
        value: "AS Level",
        label: "AS Level"
      });
    }
    if (org.gcse) {
      _this.qualifications.push({
        value: "GCSE",
        label: "GCSE"
      });
    }
    if (org.wjec) {
      _this.qualifications.push({
        value: "GCSE (WJEC)",
        label: "GCSE (WJEC)"
      });
    }
    if (org.legacy) {
      _this.qualifications.push({
        value: "GCSE (Legacy)",
        label: "GCSE (Legacy)"
      });
    }
    if (org.primary) {
      _this.qualifications.push({
        value: "Primary",
        label: "Primary"
      });
    }
    if (org.ks3) {
      _this.qualifications.push({
        value: "Key Stage 3",
        label: "Key Stage 3"
      });
    }
    if (org.igcse_cambridge) {
      _this.qualifications.push({
        value: "IGCSE (Cambridege)",
        label: "GCSE (Cambridege)"
      });
    }
    if (org.igcse_edexcel) {
      return _this.qualifications.push({
        value: "IGCSE (Edexcel)",
        label: "GCSE (Edexcel)"
      });
    }
  };
})(this);

this.handleClassStoreChange = (function(_this) {
  return function(classroom) {
    _this.classroom = classroom;
    return _this.update();
  };
})(this);

this.handleClose = (function(_this) {
  return function() {
    var ref;
    _this.section = window.location.pathname.match('allclasses') ? 'allclasses' : 'classes';
    if ((ref = _this.classroom) != null ? ref.id : void 0) {
      return opts.page('/vle/' + _this.section + '/' + _this.classroom.id + '/' + opts.params.type);
    } else {
      return opts.page('/vle/' + _this.section + '/');
    }
  };
})(this);

this.handleSave = (function(_this) {
  return function() {
    return opts.stores.trigger('class_save', _this.classroom);
  };
})(this);

this.handleSaved = (function(_this) {
  return function() {
    return _this.handleClose();
  };
})(this);

this.handleChange = (function(_this) {
  return function(e) {
    _this.classroom[e.name] = e.value;
    if (e.name === "year") {
      _this.students = !e.value ? _this.users.slice() : _this.users.filter(function(user) {
        return user.year === +e.value;
      });
    }
    return _this.update();
  };
})(this);

this.handleUsers = (function(_this) {
  return function(users) {
    _this.users = users;
    _this.users = _this.users.map(function(u) {
      u.classes = u.classrooms.map(function(c) {
        return c.name;
      });
      return u;
    });
    _this.students = !_this.classroom.year ? _this.users.slice() : _this.users.filter(function(user) {
      return user.year === +_this.classroom.year;
    });
    return _this.update();
  };
})(this);
});
    
  