
    var riot = require('riot')
    
riot.tag2('awardmodal-challenge', '<modal show="{true}" title="{title}" onclose="{handleClose}"> <div class="row"> <div class="col-xs-6"> <div riot-style="{parent.getBadgeStyle()}" class="challenge large"><br>{parent.title}</div> </div> <div class="col-xs-6"> <h4>{parent.data.type}</h4> <p class="pre">{parent.data.description}</p> <p>Marks <strong>{parent.data.usermarks || \'0\'} / {parent.data.marks}</strong></p> <p>Qualification <strong>{parent.data.qualification}</strong></p> <p>Clips <strong if="{parent.data.clips}"><a each="{parent.convertClips(parent.data.clips)}" href="/vle/browse/{id}" style="margin-right:2px;display:inline-block;" class="label label-primary">{clip_id}</a></strong><strong if="{!parent.data.clips}">All</strong></p> <p>Completed - <strong> <dateformat date="{parent.data.when}" format="dd MMM yyyy"> </dateformat></strong></p><br> </div> </div> </modal>', '', '', function(opts) {
this.on('mount', function() {
  opts.stores.on('video_changed', this.handleVideoChanged);
  return opts.stores.trigger('video_sync');
});

this.on('unmount', function() {
  return opts.stores.off('video_changed', this.handleVideoChanged);
});

this.handleVideoChanged = (function(_this) {
  return function(videos) {
    _this.videos = videos;
    return _this.update();
  };
})(this);

this.dateformat = 'dd/MM/yyyy';

this.title = "";

this.handleClose = (function(_this) {
  return function() {
    return _this.tags.modal.hide();
  };
})(this);

this.show = (function(_this) {
  return function(data) {
    _this.data = data;
    _this.title = _this.data.type;
    _this.tags.modal.show();
    return _this.update();
  };
})(this);

this.getBadgeStyle = (function(_this) {
  return function() {
    if (_this.data.badge) {
      return "background:" + _this.data.colour + ";--icon:url('data:image/svg+xml;utf8," + _this.data.badge + "');";
    }
    return "";
  };
})(this);

this.convertClips = (function(_this) {
  return function(video_ids) {
    var ids;
    ids = video_ids.split(",").map(function(n) {
      return +n;
    });
    return _this.videos.filter(function(vid) {
      return ids.includes(+vid.id);
    });
  };
})(this);
});
    
  