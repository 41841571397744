
    var riot = require('riot')
    
riot.tag2('userawards', '<div show="{loading}" class="alert alert-info"> <span class="fa fa-spinner fa-pulse"></span> Loading Awards</div> <h4 show="{data.length}">Awards</h4> <div class="awards small"> <div each="{data}" onclick="{showModal}" riot-style="{parent.getBadgeStyle(badge,colour)}" class="award {type.replace(/-/g,\' \').replace(/year$/,\'\') + (badge &amp;&amp; colour ? \' challengeicon\' : \'\')}"> <div if="{total &gt; 1}" class="count">{total}</div> <div class="awardlabel">{parent.label(when,type)}</div> <div if="{isNew}" class="newBagde"></div> </div> <div class="newbadges"> <div each="{newBadges}" riot-style="{parent.getBadgeStyle(badge,colour)}" class="award large {type.replace(/-/g,\' \').replace(/year$/,\'\') + (badge &amp;&amp; colour ? \' challengeicon\' : \'\') + (newActive.id == id ? \' active\' : \'\')}"> <div class="congrats">Congratulations, You\'ve Earned </div> <div class="awardlabel">{parent.congrats(when,type,badge)}</div> </div> </div> </div> <awardmodal></awardmodal> <awardmodal-challenge stores="{opts.stores}"></awardmodal-challenge>', '.awards.small { font-size: 11px; gap: 0px; } .awards.small .award { margin: 5px; } .awards.small .award:before { font-size: 1em; }', '', function(opts) {
var Resource, RiotControl, request, resourceUtils;

request = require('superagent');

RiotControl = require('riotcontrol');

resourceUtils = require('../libs/resourceUtils.coffee');

Resource = require('../libs/resource.coffee')(request, RiotControl, resourceUtils);

this.on('mount', (function(_this) {
  return function() {
    _this.user_id = opts.userid;
    _this.newBadges = [];
    _this.data = [];
    return _this.load();
  };
})(this));

this.on('update', (function(_this) {
  return function() {
    if (opts.userid !== _this.user_id) {
      _this.user_id = opts.userid;
      return _this.load();
    }
  };
})(this));

this.load = (function(_this) {
  return function() {
    _this.update({
      loading: true
    });
    return new Resource("/duocms/api/leaderboard/userawards/" + _this.user_id).get({
      type: _this.period
    }, function(data1) {
      var activeIdx, colors, end, frame, lastTada, timeperframe;
      _this.data = data1;
      _this.loading = false;
      lastTada = window.localStorage.getItem("lastTada");
      if (!lastTada) {
        _this.newBadges = _this.data.filter(function(b) {
          return b.isNew;
        });
      } else {
        _this.newBadges = _this.data.filter(function(b) {
          return new Date(b.when) > new Date(lastTada);
        });
      }
      window.localStorage.setItem("lastTada", new Date());
      _this.update();
      if (_this.newBadges.length > 0) {
        timeperframe = 4 * 1000;
        end = Date.now() + (timeperframe * _this.newBadges.length);
        colors = ['#bb0000', '#ffffff'];
        activeIdx = -1;
        return (frame = function() {
          var step;
          myConfetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: {
              x: 0
            },
            colors: colors
          });
          myConfetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: {
              x: 1
            },
            colors: colors
          });
          step = (_this.newBadges.length - 1) - Math.floor((end - Date.now()) / timeperframe);
          if (step !== activeIdx) {
            activeIdx = step;
            if (_this.newBadges[activeIdx]) {
              if (_this.audio3.currentTime > 0) {
                _this.audio3.currentTime = 0;
              } else {
                _this.audio3.play();
              }
            }
            if (_this.newBadges[activeIdx]) {
              colors[0] = _this.newBadges[activeIdx].colour || '#bb0000';
            }
            _this.update({
              newActive: _this.newBadges[activeIdx]
            });
          }
          if (Date.now() < end) {
            return requestAnimationFrame(frame);
          }
        })();
      }
    });
  };
})(this);

this.getBadgeStyle = (function(_this) {
  return function(badge, colour) {
    if (badge) {
      return "background:" + colour + ";--icon:url('data:image/svg+xml;utf8," + badge + "');";
    }
    return "";
  };
})(this);

this.label = (function(_this) {
  return function(dt, type) {
    var date, months;
    months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    date = new Date(new Date(dt) - 12 * 60 * 60 * 1000);
    if (type.match(/week$/)) {
      return "w/e " + date.getDate() + " " + months[date.getMonth()];
    }
    if (type.match(/month$/)) {
      return months[date.getMonth()] + " " + date.getFullYear();
    }
    if (type.match(/year$/)) {
      return (date.getFullYear() - 1) + " / " + date.getFullYear();
    }
    return type;
  };
})(this);

this.ucFirst = (function(_this) {
  return function(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
})(this);

this.showModal = (function(_this) {
  return function(e) {
    var type;
    type = e.item.type;
    if (e.item.badge) {
      _this.tags["awardmodal-challenge"].show(e.item);
      return _this.update();
    } else {
      _this.update({
        loading: true
      });
      return new Resource("/duocms/api/leaderboard/userawards/" + _this.user_id).get({
        type: type
      }, function(data) {
        _this.loading = false;
        _this.tags.awardmodal.show(type, data);
        return _this.update();
      });
    }
  };
})(this);
});
    
  