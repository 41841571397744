
    var riot = require('riot')
    
riot.tag2('timer', '<div if="{timer &amp;&amp; displayDate}" class="col-xs-12"> <dateformat format="Time Remaining h:mm:ss" date="{displayDate}"></dateformat> <progressbar value="{percent}" bsstyle="{percent&gt;10 ? \'success\' : \'danger\'}" striped="{false}" active="{true}"> <dateformat format="time h:mm:ss" date="{parent.displayDate}"></dateformat> </progressbar> </div>', '', '', function(opts) {
var interval, timer;

timer = null;

interval = null;

this.displayDate = null;

this.startTimer = function() {
  if (timer || !opts.duration) {
    return;
  }
  this.timer = timer = opts.duration;
  this.step = 100 / this.timer;
  this.percent = 100 - this.timer * this.step;
  return interval = setInterval((function(_this) {
    return function() {
      var hours, minutes, seconds, totalSeconds;
      _this.timer--;
      _this.percent = _this.timer * _this.step;
      if (_this.percent <= 0 && opts.oncomplete) {
        opts.oncomplete();
        clearInterval(interval);
      }
      totalSeconds = _this.timer;
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      minutes = Math.floor(totalSeconds / 60);
      seconds = totalSeconds % 60;
      _this.displayDate = new Date(1970, 1, 1, hours, minutes, seconds);
      return _this.update();
    };
  })(this), 1000);
};

this.on('unmount', function() {
  if (interval) {
    return clearInterval(interval);
  }
});
});
    
  