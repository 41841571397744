
    var riot = require('riot')
    
riot.tag2('crudbuttons', '<div style="padding-bottom:15px;" class="btn-toolbar"> <button if="{opts.onadd != null}" onclick="{handleAdd}" class="btn btn-primary btn-sm">Create {opts.type}</button> <button if="{opts.onedit != null}" onclick="{handleEdit}" class="btn btn-primary btn-sm {disabled:!opts.active}">Edit {opts.type}</button> <confirm if="{opts.ondelete != null}" onconfirm="{handleDelete}" class="btn btn-primary btn-sm {disabled:!opts.active}">Delete {opts.type}</confirm><yield></yield> </div>', '', '', function(opts) {
this.handleAdd = (function(_this) {
  return function() {
    if (opts.onadd && typeof opts.onadd === "function") {
      return opts.onadd();
    }
  };
})(this);

this.handleEdit = (function(_this) {
  return function() {
    if (opts.onedit && opts.active && typeof opts.onedit === "function") {
      return opts.onedit(opts.active);
    }
  };
})(this);

this.handleDelete = (function(_this) {
  return function() {
    if (opts.ondelete && opts.active && typeof opts.ondelete === "function") {
      return opts.ondelete(opts.active);
    }
  };
})(this);
});
    
  