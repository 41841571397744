module.exports = 

  class Move extends require './transform.coffee'
      
    dragMove:(e)=>
      e.preventDefault()
      e.stopPropagation()
      isTouch = false
      if e.touches
        isTouch = true
        touch = e.touches[0]
        mousePoint =  left:touch.pageX,top:touch.pageY
      else
        mousePoint =  left:e.pageX,top:e.pageY
      top = mousePoint.top - @start.top
      left = mousePoint.left - @start.left
      radians = 0 - @getAngle(@elem.parentNode).radians
      relative = @rotateMatix(left,top,@start.offsetLeft,@start.offsetTop,radians)

      if @options.vertical 
        @elem.style.top = relative.top + "px"
      if @options.horizontal
        relative.left = @options.minh  if @options.minh? && relative.left<@options.minh 
        relative.left = @options.maxh  if @options.maxh? && relative.left>@options.maxh
        @elem.style.left = relative.left + "px"

      @pos = @rotateMatix(parseInt(@elem.style.left,10)||0,parseInt(@elem.style.top,10)||0,0,0,0 - radians)
      @options.dragging(@pos,relative,{isTouch})