
    var riot = require('riot')
    
riot.tag2('counter', '<h2>{rounded}{opts.suffix}</h2> <h3>{opts.subtitle}</h3>', 'counter, [riot-tag="counter"], [data-is="counter"] { min-height: calc(7vw + 80px); display: block; -webkit-border-radius: 5px; border-radius: 5px; padding: 5px; text-align: center; } counter h2, [riot-tag="counter"] h2, [data-is="counter"] h2 { font-size: calc(5vw + 20px); color: #004c81; text-align: center; pointer-events: none; } counter h3, [riot-tag="counter"] h3, [data-is="counter"] h3 { pointer-events: none; font-size: calc(1vw + 10px); color: #049adf; }', '', function(opts) {
this.on('mount', function() {
  this.total = 0;
  return this.interval = setInterval((function(_this) {
    return function() {
      if (Math.abs(_this.total - opts.total) > 0.01) {
        return requestAnimationFrame(_this.update);
      }
    };
  })(this), 16);
});

this.on('update', function() {
  var inc;
  inc = Math.abs(opts.total - this.total) * 0.15;
  if (this.total < opts.total + 0.2) {
    this.total += inc;
  } else if (this.total > opts.total - 0.2) {
    this.total -= inc;
  }
  if (opts.total === 0 && this.total > 0 && Math.abs(opts.total - this.total) < 0.2) {
    this.total = 0;
  }
  if (this.total > opts.total && Math.abs(opts.total - this.total) < 0.2) {
    this.total = opts.total;
  }
  if (opts.precision && this.total) {
    return this.rounded = this.total.toFixed(+opts.precision);
  } else {
    return this.rounded = Math.round(this.total);
  }
});
});
    
  