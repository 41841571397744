
    var riot = require('riot')
    
riot.tag2('extravideo', '<modal id="exvidmodal" esckey="{true}" title="{title}" onclose="{handleClose}" bssize="lg" backdrop="{false}"> <div style="margin:-15px" class="embed-responsive embed-responsive-16by9"> <iframe if="{!mp4}" show="{parent.vimeoid}" name="player" riot-src="https://player.vimeo.com/video/{parent.vimeoid}?autoplay=1&amp;autopause=0&amp;badge=0&amp;byline=0&amp;portrait=false&amp;title=false&amp;color=#3FA9F5&amp;api=1" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" class="embed-responsive-item"></iframe> </div> </modal>', '', '', function(opts) {
this.open = (function(_this) {
  return function(vimeoid, title) {
    _this.vimeoid = vimeoid;
    _this.title = title != null ? title : "Video";
    _this.tags.modal.show();
    return _this.update();
  };
})(this);
});
    
  