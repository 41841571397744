
    var riot = require('riot')
    
riot.tag2('piechart', '<canvas id="canvas" height="{opts.height || 400}" width="{opts.width || 400}"></canvas> <div name="legend"></div>', 'piechart{display:block;} piechart ul{list-style:none;margin:0;padding:0;} piechart li span{ display: inline-block; width: 12px; height: 12px; margin-right: 5px; }', '', function(opts) {
var Chart;

Chart = require('chart.js');

this.on('mount', (function(_this) {
  return function() {
    var ctx;
    _this.pieoptions = {
      segmentShowStroke: true,
      segmentStrokeColor: "#fff",
      segmentStrokeWidth: 2,
      percentageInnerCutout: 50,
      animationSteps: 100,
      animationEasing: "easeOutQuart",
      animateRotate: true,
      animateScale: false
    };
    Chart.defaults.global.responsive = true;
    ctx = _this.canvas.getContext("2d");
    _this.chart = new Chart(ctx).Doughnut(opts.data, _this.pieoptions);
    return _this.legend.innerHTML = _this.chart.generateLegend();
  };
})(this));

this.on('update', (function(_this) {
  return function() {
    if (opts.data && _this.chart) {
      return _this.chart.initialize(opts.data, _this.pieoptions);
    }
  };
})(this));
});
    
  