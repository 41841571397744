
    var riot = require('riot')
    
riot.tag2('multianswer', '<div class="multiple form-group"> <label class="control-label {\'col-xs-\'+opts.width1}">{opts.label}</label> <div class="multiple {\'col-xs-\'+opts.width2}"> <div each="{v,i in letters}" class="answer"> <div onclick="{parent.handleClick}" class="btn btn-default {\'btn-primary\':parent.active.indexOf(i)&gt;-1,\'btn-danger\':(parent.opts_answers.indexOf(i) &gt;-1 &amp;&amp; parent.opts.showanswer)}">{v}</div> </div> </div> </div>', '', '', function(opts) {
this.active = [];

this.on('mount', function() {
  var j, ref, results;
  this.letters = (function() {
    results = [];
    for (var j = 0, ref = opts.answercount; 0 <= ref ? j < ref : j > ref; 0 <= ref ? j++ : j--){ results.push(j); }
    return results;
  }).apply(this).map(function(v, i) {
    return String.fromCharCode(65 + i);
  });
  this.active = [];
  if (!this.active.length && opts.value && typeof opts.value === "string") {
    return this.active = opts.value.split(",").map(function(val) {
      return +val;
    });
  }
});

this.on('update', (function(_this) {
  return function() {
    var ref;
    if (!_this.opts_answers && ((typeof opts !== "undefined" && opts !== null ? (ref = opts.answers) != null ? ref.split : void 0 : void 0) != null) && opts.showanswer) {
      return _this.opts_answers = JSON.parse(opts.answers).split(",").map(function(i) {
        return +i;
      });
    }
  };
})(this));

this.handleClick = function(e) {
  var idx;
  idx = e.item.i;
  if (this.active.indexOf(idx) > -1) {
    this.active.splice(this.active.indexOf(idx), 1);
  } else {
    this.active.push(idx);
  }
  opts.onupdate({
    name: opts.id,
    value: this.active.join(",")
  });
  return this.update();
};
});
    
  