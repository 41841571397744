module.exports = {

    groupSort:(collection,field,sort=true)->
      rows = @groupby(collection,field)
      if sort then rows = rows.sort (a,b)->
        return 0 if !a && !b
        if a[field] > b[field] then 1 else if a[field] == b[field] then 0 else -1
      rows.map (video)-> label:video[field],value:video[field]
        
    groupby:(collection,field)->
      unique = []
      rows = []
      collection.forEach (row)->
        if row[field]
          keys = row[field].split(/\//) #splits field on / treating as or
          for key in keys
            if unique?.indexOf(key)== -1
              unique.push(key)
              newRow = Object.assign({},row)
              newRow[field]=key
              rows.push(newRow)
      return rows

}