<script>export let open = false;
let className = "";
export { className as class };
export let placement = "";
const toggle = () => open = !open;</script>


<div class="dropdown {className}">
  <span on:click={toggle}>
    <slot>Menu</slot>
  </span>
  {#if open}
    <div class="pop-backdrop" on:click={(e)=> open=false} ></div>
    <div class="dropdown-menu {placement == "top" ? "top" : ""}" on:click={(e)=> open=false}>
      <slot name="menu"></slot>
    </div>
  {/if}
</div>

<style>
  * {
    white-space: normal;
    -webkit-user-modify:read-only;
  }
  .dropdown{
    display:inline-block;
    position:relative;
  }

  .dropdown-menu{
    display:block;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    background-clip: padding-box;
  }

  .dropdown-menu.top{
    top:auto;
    bottom:100%;
  }

  :global(duo-dropdown [slot="menu"] a),
  :global(.dropdown-menu [slot="menu"] a),
  :global(.dropdown-menu [slot="menu"] button){
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333;
    white-space: nowrap;
    background:white;
    width:100%;
    text-align:left;
    border:0px;
    font-size:14px;
    text-decoration: none;
  }
  :global(duo-dropdown [slot="menu"] a:hover),
  :global(.dropdown-menu [slot="menu"] a:hover),
  :global(.dropdown-menu [slot="menu"] button:hover){
    text-decoration: none;
    color: #303030;
    background-color: #f5f5f5;
  }
  .pop-backdrop{
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    top:0;
    background:rgba(0,0,0,0.01);
    z-index:9;
  }

</style>