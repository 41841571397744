module.exports = 

  class feedbackStore

    constructor:(riot,Resource)->
      riot.observable(@)
      @data = {}
      @saving = false
      @question_id = null
      @assignedwork_id = null
      @resource = new Resource("/duocms/api/feedback")
      @on 'feedback_get',@get
      @on 'feedback_all',@getAll
      @on 'feedback_add',@add
      @on 'feedback_update',@update
      @on 'feedback_get_unread',@unread
      @on 'feedback_delete',@remove

    get:(assignedwork_id,question_id,user_id)->
      return if !assignedwork_id || !question_id
      @question_id = question_id # save here as its not always obvious in view
      @assignedwork_id = assignedwork_id
      @user_id = user_id
      @resource.get {assignedwork_id,question_id,user_id},(@messages)=>
        @resync(@messages,assignedwork_id,question_id,user_id)

    getAll:(archive)->
      @resource.get {id:"all",archive},(@allMessages)=>
        @trigger 'feedback_all_changed',@allMessages

    unread:->
      @resource.get {unread:true},(res)=>
        @trigger 'feedback_unread',res?.total || 0
      
    sync:(assignedwork_id,question_id,user_id)->
      cache_id = assignedwork_id+"_"+question_id+"_"+user_id
      return @get(assignedwork_id,question_id,user_id) if !@data[cache_id]
      @trigger 'feedback_changed',@data[cache_id]

    add:(assignedwork_id,question_id,user_id,message,wholeclass,user_ids)->
      question_id = @question_id if !question_id
      return if @saving == true
      @saving = true
      @savingTimeout = setTimeout => 
        @saving = false
      ,2000
      @resource.save {assignedwork_id,question_id,user_id,message,wholeclass,user_ids},(@messages)=>
        @saving = false
        clearTimeout(@savingTimeout) if @savingTimeout
        @resync(@messages,assignedwork_id,question_id,user_id)

    update:(id,message,assignedwork_id,question_id,user_id)->
      question_id = @question_id if !question_id
      @resource.save {id,message},(@messages)=>
        @resync(@messages,assignedwork_id,question_id,user_id)

    # updates cache and triggers change
    resync:(messages,assignedwork_id,question_id,user_id)=>
      cache_id = assignedwork_id+"_"+question_id+"_"+user_id
      @data[cache_id] = messages
      @trigger 'feedback_changed',@data[cache_id]

    remove:(id,assignedwork_id,question_id,user_id)->
      question_id = @question_id if !question_id
      assignedwork_id = @assignedwork_id if !assignedwork_id
      user_id = @user_id if !user_id
      @resource.remove {id,assignedwork_id,question_id,user_id},(messages)=>
        @resync(messages,assignedwork_id,question_id,user_id)
      
