
    var riot = require('riot')
    
riot.tag2('challengepicker', '<modal title="Add A New Challenge" onclose="parent.closeModal" class="challengemodal"> <alert if="{parent.loading}" class="alert-info">Loading Challenges </alert> <alert bsstyle="info" if="{!parent.loading &amp;&amp; parent.data.length}">Click on the challenges below to add them to your active challenge list</alert> <alert bsstyle="warning" if="{!parent.loading &amp;&amp; !parent.data.length}">Sorry, there are no new challenges available. Please check again later.</alert> <div each="{parent.data}" hide="{disabled:parent.parent.active_ids.includes(id)}" class="challengerow row"> <div class="col-xs-3"> <div onclick="{parent.parent.addChallange}" riot-style="background:{colour};--icon:url(\'data:image/svg+xml;utf8,{badge}\')" class="challenge"></div> </div> <div class="col-xs-9"> <h4>{name}</h4> <p class="pre">{description}</p> <p>Marks Target <strong>{marks}</strong></p> <p>Qualification <strong>{qualification}</strong></p> <p>Clips <strong if="{clips}"><a each="{parent.parent.convertClips(clips)}" href="/vle/browse/{id}" style="margin-right:2px;display:inline-block" class="label label-primary">{clip_id}</a></strong><strong if="{!clips}">All</strong></p> <p>Dates - <strong> <dateformat date="{start}" format="dd MMM yyyy"> </dateformat> - <dateformat date="{end}" format="dd MMM yyyy"> </dateformat></strong></p> </div> </div> </modal>', '.challengerow{ padding:5px; border-bottom:1px solid #eee; } .challengerow .challenge{ margin:12px; } .challengerow .challenge:before{ width:3.5em; height:4em; }', '', function(opts) {
var Resource, RiotControl, request, resourceUtils;

request = require('superagent');

RiotControl = require('riotcontrol');

resourceUtils = require('../libs/resourceUtils.coffee');

Resource = require('../libs/resource.coffee')(request, RiotControl, resourceUtils);

this.on('mount', function() {
  opts.stores.on('video_changed', this.handleVideoChanged);
  return opts.stores.trigger('video_sync');
});

this.load = (function(_this) {
  return function() {
    _this.update({
      loading: true
    });
    return new Resource("/duocms/api/challenges/available").get({}, function(data) {
      _this.data = data;
      _this.loading = false;
      return _this.update();
    });
  };
})(this);

this.show = (function(_this) {
  return function(current) {
    _this.active_ids = current ? current.map(function(c) {
      return c.id;
    }) : [];
    _this.tags.modal.show();
    return _this.load();
  };
})(this);

this.closeModal = (function(_this) {
  return function() {
    return _this.tags.modal.hide();
  };
})(this);

this.addChallange = (function(_this) {
  return function(e) {
    return new Resource("/duocms/api/challenges/join").save({
      challenge_id: e.item.id
    }, function(data) {
      _this.data = data;
      if (opts.updated) {
        opts.updated();
      }
      return _this.closeModal();
    });
  };
})(this);

this.handleVideoChanged = (function(_this) {
  return function(videos) {
    _this.videos = videos;
    return console.log(_this.videos.length, "videos loaded");
  };
})(this);

this.convertClips = (function(_this) {
  return function(video_ids) {
    var ids;
    ids = video_ids.split(",").map(function(n) {
      return +n;
    });
    return _this.videos.filter(function(vid) {
      return ids.includes(+vid.id);
    });
  };
})(this);
});
    
  