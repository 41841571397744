
    var riot = require('riot')
    
riot.tag2('completedassignments', '<br> <div class="col-md-7"> <grid columns="{gridColumns}" data="{results}" height="525px" onselect="{handleSelect}" onedit="{handleEdit}"></grid> </div> <div if="{selected &amp;&amp; answers.length&gt;0}" class="col-md-5"> <panel heading="{selected.first_name} {selected.surname}\'s Answers" class="row-md-4"> <nextprev total="{parent.selected.answers.length}" onchange="{parent.questionIndex}"></nextprev> <div class="canvaswrap"> <canvas name="canvas" width="920" height="520" class="canvas"></canvas> <input each="{parent.dinputs}" value="{text}" riot-style="width:{width}px;height:{height}px;top:{y}px;left:{x}px;position:absolute;border:0px;color:red;background:transparent;font-size:12px;text-align:center;"> </div><img riot-src="/images/questions/question{parent.question_id}.png" class="img-responsive img-question"> <ol type="a"> <li each="{parent.answers}" if="{type!=\'drawing\' &amp;&amp; type!=\'input\'}"> <div> <div class="btn">{prefix}</div> <div class="btn btn-default"><span if="{type==\'numerical\' || !type || type==\'text\'}" class="mathquill-embedded-latex">{text}</span></div> <div class="btn">{suffix}</div> </div> </li> </ol> </panel> </div>', 'completedassignments nextprev{ position:absolute; top:1px; right:5px; color:white; } completedassignments .canvaswrap{ border:1px solid #ccc; position:absolute; }', '', function(opts) {
var shapes;

this.users = [];

this.dinputs = [];

this.gridColumns = [
  {
    field: 'first_name',
    label: 'First Name',
    width: '20%'
  }, {
    field: 'surname',
    label: 'Surname',
    width: '20%'
  }, {
    field: 'classname',
    label: 'Class',
    width: '20%'
  }, {
    field: 'year',
    label: 'Yr',
    width: '10%'
  }, {
    field: 'correct',
    label: 'Correct',
    width: '15%'
  }, {
    field: 'marks',
    label: 'Marks',
    width: '15%'
  }
];

this.on('mount', function() {
  opts.stores.on('assignedwork_changed_one', this.handleAssignedWorkChange);
  opts.stores.on('assignedwork_changed_complete', this.handleAssignedCompleteChange);
  opts.stores.trigger('assignedwork_sync_one', opts.assignedid);
  return window.addEventListener('resize', this.positionAnswer, true);
});

this.on('unmount', function() {
  opts.stores.off('assignedwork_changed_one', this.handleAssignedWorkChange);
  opts.stores.off('assignedwork_changed_complete', this.handleAssignedCompleteChange);
  return window.removeEventListener('resize', this.positionAnswer);
});

this.on('error', function(err) {
  return console.warn(err);
});

this.handleAssignedCompleteChange = (function(_this) {
  return function(results) {
    _this.results = results;
    return _this.update();
  };
})(this);

this.handleSelect = (function(_this) {
  return function(selected) {
    _this.selected = selected;
    _this.questionIndex(0);
    _this.quesImg = document.querySelector('.img-question');
    if (!_this.quesImg) {
      return;
    }
    return _this.quesImg.addEventListener('load', _this.positionAnswer);
  };
})(this);

this.handleAssignedWorkChange = (function(_this) {
  return function(assignment) {
    _this.questions = assignment.questions;
    return opts.stores.trigger('assignedwork_sync_complete', opts.assignedid);
  };
})(this);

this.dragInputs = (function(_this) {
  return function() {
    var quesImg, scale;
    quesImg = document.querySelector('.img-question');
    scale = quesImg.offsetWidth / 570;
    _this.dinputs = [];
    _this.questions.forEach(function(question) {
      if (_this.question_id === question.id) {
        return question.answer.forEach(function(answer, answerIndex) {
          var answers;
          if (answer.type === 'input') {
            _this.dinputs = answer.text;
            answers = _this.answers[answerIndex].text;
            return _this.dinputs = answer.text.map(function(dinput, i) {
              return {
                x: dinput.x * scale,
                y: dinput.y * scale,
                width: dinput.width * scale,
                height: dinput.height * scale,
                text: answers[i]
              };
            });
          }
        });
      }
    });
    return _this.update();
  };
})(this);

this.questionIndex = (function(_this) {
  return function(idx) {
    var ref;
    if (_this.selected.answers.length) {
      _this.question_id = _this.selected.answers[idx].question_id;
      _this.answers = _this.selected.answers[idx].answer;
    } else {
      _this.answers = [];
    }
    if (((ref = _this.answers[0]) != null ? ref.type : void 0) === "drawing") {
      _this.update();
      _this.canvas = _this.root.querySelector('.canvas');
      _this.ctx = _this.canvas.getContext("2d");
      _this.ctx.strokeStyle = "#FF0000";
      return _this.redraw();
    } else {
      _this.update();
      $('.mathquill-embedded-latex').mathquill('revert');
      return $('.mathquill-embedded-latex').mathquill();
    }
  };
})(this);

this.positionAnswer = (function(_this) {
  return function() {
    var pos, quesImg;
    _this.quesImg.removeEventListener('load', _this.positionAnswer);
    quesImg = document.querySelector('.img-question');
    if (!quesImg) {
      return;
    }
    pos = quesImg.getBoundingClientRect();
    _this.canvas = _this.root.querySelector('.canvaswrap');
    _this.canvas.style.top = quesImg.offsetTop + "px";
    _this.canvas.style.left = quesImg.offsetLeft + "px";
    _this.canvas.style.width = pos.width + "px";
    _this.canvas.style.height = pos.height + "px";
    _this.scale = pos.width / 100;
    _this.redraw();
    return _this.update();
  };
})(this);

this.redraw = (function(_this) {
  return function() {
    var canvas, shapedata;
    canvas = _this.root.querySelector('canvas');
    _this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    shapedata = _this.answers[0].text;
    shapedata.forEach(function(shape) {
      return shapes[shape.type](shape);
    });
    _this.dragInputs();
    return _this.update();
  };
})(this);

shapes = {
  rect: (function(_this) {
    return function(options) {
      _this.ctx.beginPath();
      _this.ctx.rect(options.x * _this.scale, options.y * _this.scale, options.w * _this.scale, options.h * _this.scale);
      return _this.ctx.stroke();
    };
  })(this),
  circle: (function(_this) {
    return function(options) {
      _this.ctx.beginPath();
      _this.ctx.arc(options.x * _this.scale, options.y * _this.scale, options.r * _this.scale, 0, Math.PI * 2);
      return _this.ctx.stroke();
    };
  })(this),
  arc: (function(_this) {
    return function(opts) {
      _this.ctx.beginPath();
      _this.ctx.arc(opts.x * _this.scale, opts.y * _this.scale, opts.r * _this.scale, opts.rad1, opts.rad2);
      return _this.ctx.stroke();
    };
  })(this),
  line: (function(_this) {
    return function(options) {
      _this.ctx.beginPath();
      _this.ctx.moveTo(options.x1 * _this.scale, options.y1 * _this.scale);
      _this.ctx.lineTo(options.x2 * _this.scale, options.y2 * _this.scale);
      return _this.ctx.stroke();
    };
  })(this)
};
});
    
  