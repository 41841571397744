
    var riot = require('riot')
    
riot.tag2('draginput', '<div name="answers" class="dianswers {correct:opts.correct}"> <div each="{v,k in opts_answers}" name="dianswer{k}" style="position:absolute;display:none;"> <div show="{v.prefix}" class="inputprefix">{v.prefix}</div> <input type="text" autocomplete="off" onblur="{parent.onupdate}" value="{parent.opts.showanswer ? parent.teacher_answers[k] : parent.opts.value[k]}" style="text-align:center;width:100%;height:100%;display:flex"> <div show="{v.suffix}" class="inputsuffix">{v.suffix}</div> </div> </div>', '', '', function(opts) {
this.on('mount', function() {
  this.answer = opts.value || [];
  this.imgloaded = false;
  return setTimeout((function(_this) {
    return function() {
      window.addEventListener('resize', _this.positionAnswers, true);
      _this.img = document.querySelector('.img-question');
      if (_this.img) {
        _this.img.addEventListener('load', _this.imageLoaded);
      }
      _this.positionAnswers();
      return _this.moveToImgPanel();
    };
  })(this));
});

this.on('unmount', function() {
  var ref;
  window.removeEventListener('resize', this.positionAnswers);
  this.answers.remove();
  if ((ref = document.querySelector('.dianswers')) != null ? ref.remove : void 0) {
    return document.querySelector('.dianswers').remove();
  }
});

this.on('update', function() {
  var opts_answers, ref;
  opts_answers = JSON.parse(opts.answers);
  if (!this.opts_answers && ((typeof opts !== "undefined" && opts !== null ? (ref = opts.answers) != null ? ref.split : void 0 : void 0) != null)) {
    this.opts_answers = opts_answers.map(function(a) {
      a.text = "";
      return a;
    });
  }
  if (opts.showanswer) {
    this.teacher_answers = opts_answers.map(function(a) {
      var ref1, ref2;
      return (ref1 = a.text) != null ? (ref2 = ref1.split(";")) != null ? ref2[0] : void 0 : void 0;
    });
  }
  return this.setAnswerColor();
});

this.setAnswerColor = (function(_this) {
  return function() {
    return _this.opts_answers.forEach(function(answer, k) {
      var ref;
      if (!_this['dianswer' + k]) {
        return;
      }
      if ((ref = opts.subcorrect) != null ? ref[k] : void 0) {
        _this['dianswer' + k].querySelector("input").style.backgroundColor = "rgba(92,184,92,0.2)";
      } else {
        _this['dianswer' + k].querySelector("input").style.removeProperty("background-color");
      }
      return _this['dianswer' + k].style.color = opts.showanswer ? "red" : "black";
    });
  };
})(this);

this.imageLoaded = (function(_this) {
  return function() {
    _this.img.removeEventListener('load', _this.imageLoaded);
    _this.positionAnswers();
    return _this.moveToImgPanel();
  };
})(this);

this.onupdate = (function(_this) {
  return function(e) {
    _this.answer[e.item.k] = e.target.value.replace(/ +/, '');
    return opts.onupdate({
      name: opts.id,
      value: _this.answer
    });
  };
})(this);

this.positionAnswers = (function(_this) {
  return function() {
    var offset, ref, ref1;
    if (!((ref = _this.opts_answers) != null ? ref.forEach : void 0) || !((ref1 = _this.img) != null ? ref1.offsetWidth : void 0)) {
      return;
    }
    _this.scale = _this.img.offsetWidth / 570;
    offset = {
      top: _this.img.offsetTop,
      left: _this.img.offsetLeft
    };
    _this.opts_answers.forEach(function(answer, k) {
      if (_this['dianswer' + k]) {
        _this['dianswer' + k].style.top = (answer.y * _this.scale) + offset.top + "px";
        _this['dianswer' + k].style.left = (answer.x * _this.scale) + offset.left + "px";
        _this['dianswer' + k].style.width = answer.width * _this.scale + "px";
        _this['dianswer' + k].style.height = answer.height * _this.scale + "px";
        return _this['dianswer' + k].style.display = "block";
      }
    });
    return _this.imgloaded = true;
  };
})(this);

this.moveToImgPanel = (function(_this) {
  return function() {
    if (_this.opts_answers.length) {
      if (_this.img != null) {
        return _this.img.parentNode.appendChild(_this.answers);
      }
    }
  };
})(this);
});
    
  