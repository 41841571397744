module.exports = 

  class UserStore

    constructor:(riot,Resource,@utils,@stores,@request)->
      riot.observable(@)
      @users = []
      @savecount = 0
      @classCounts = {}
      @user = null
      @data_state = false
      @resource = new Resource("/duocms/api/users")
      @on 'user_load',@load
      @on 'user_sync',@sync
      @on 'user_get',@get
      @on 'user_sync_one',@sync_one
      @on 'user_save',@save
      @on 'user_remove',@remove
      @on 'users_remove',@removeMulti
      @on 'user_upload',@upload
      @on 'user_import',@import
      @on 'user_addyear',@addYear
      @on 'users_imported',@load
      @on 'users_changed',@countClasses
      @on 'user_classcount_sync',@syncClassCount
      @on 'users_passwords',@updatePasswords

    load:->
      @data_state = 'loading'
      @resource.get org:true,(@users)=>
        @users.forEach (user)-> 
          user.class_names = (user.classrooms.map (classroom)-> classroom.name).join(", ")
        @data_state = 'loaded'
        @sync()

    countClasses:->
      @classCounts = {}
      @users.forEach (user)=>
        return if !user.class_names?
        user.class_names.split(",").forEach (classname)=>
          if classname?
            classname = classname.trim()
            @classCounts[classname] = if !@classCounts[classname]? then 1 else @classCounts[classname] + 1
      @trigger 'user_classcount_changed',@classCounts

    syncClassCount:->
      @trigger 'user_classcount_changed',@classCounts

    sync:->
      @load() if !@data_state
      setTimeout => # force async for ie
        @trigger 'users_changed',@users
      
    sync_one:(id)->
      return if @user == 'loading'
      return @trigger 'user_changed',@user if ""+@user?.id == ""+id
      @user = 'loading'
      @get(id)

    get:(id)->
      return if !id 
      @resource.get {id:id},(@user)=>
        @sync_one(@user.id)

    save:(user)->
      @savecount++
      @one 'toaster_add', (data)=> if data.type == 'error' && @savecount > 0 then @savecount-- # listen for next error to prevent savecount blocking
      @resource.save user,(user)=># this could return multiple users
        @savecount--
        if user?.map #if it's an array
          user.forEach (u)=> @updateUser(u)
        else if user
          @updateUser(user)
      
    updatePasswords:(ids,type,password)->
      data = {
        ids:ids
        passwordtype:type
        password:password
      }
      @resource.save data,(data)=>
        @trigger 'users_passwords_saved' #closes modal
        if data && Array.isArray(data)
          fields = Object.keys(data[0])
          csv = fields.join(",")+"\n" # headers
          csv += (data.map (row)=> (fields.map (field)=> row[field]).join(",") ).join("\n") 
          makeDownload(csv, "new-passwords.csv", "text/csv")
          
    updateUser:(user)->
      if user.classrooms
        user.class_names = (user.classrooms.map (classroom)-> classroom.name).join(", ")
      @utils.updateFromId(@users,user)
      @trigger 'user_saved' #closes modal
      @sync() if @users.length > 1 #if they already have a user list

    remove:(user)->
      classesChanged = []
      @resource.remove user, =>
        if user.classrooms
          user.classrooms.forEach (cr)-> 
            classesChanged.push(cr.id) if classesChanged.indexOf(cr.id)==-1
        @users = @utils.removeFromId(@users,user.id)
        @stores.trigger('class_remove_empty',classesChanged)
        @sync()
 
    removeMulti:(users)->
      classesChanged = []
      # break users into batches as delete won't work with long urls
      overflow = if users.length > 200 then users.splice(200) else null
      users = users.filter (user)-> !user?.account_type?.match(/a/i) # don't delete admins with multi-select
      @resource.removeMulti users, =>
        users.forEach (user)=>
          user.classrooms.forEach (cr)-> 
            classesChanged.push(cr.id) if classesChanged.indexOf(cr.id)==-1
          @utils.removeFromId(@users,user.id)
        @stores.trigger('class_remove_empty',classesChanged)
        @sync()    
        @removeMulti(overflow) if overflow #call again with left over
    
    upload:(file)->
      formData = new FormData();
      formData.append("name", 'users.xslx');
      formData.append("file", file);
      xhr = new XMLHttpRequest();
      xhr.open('POST', '/duocms/api/users/uploadschool?_csrf='+window.csrf, true)
      self = this   
      xhr.onload = ->
        if (this.status == 200)
          resp = JSON.parse(this.response)
          self.trigger 'user_uploaded',resp.data
        else
          resp = JSON.parse(this.response)
          self.trigger 'toaster_add',title:'Error',message:resp.message,type:'error'
      xhr.send(formData)
      
    addYear:->
      xhr = new XMLHttpRequest();
      xhr.open('GET', '/duocms/api/users/addyear?_csrf='+window.csrf, true)
      self = this  
      xhr.onload = ->
        if (this.status == 200)
          self.load()
        else
          resp = JSON.parse(this.response)
          self.trigger 'toaster_add',title:'Error',message:resp.message,type:'error'
      xhr.send() 
      
    import:(addUsers,updateUsers,deleteUsers,addClasses)->
      if @savecount < 7 #prevent sending more than 6 requests at once
        if addClasses.length > 0
          @stores.trigger('class_save',addClasses.shift())
        else if addUsers.length > 0
          #@save(addUsers.shift())
          @request.post('/duocms/api/users/batchadd?_csrf='+window.csrf)
          .send(addUsers).end (err,res)=> 
            addUsers.length = 0
            @import(addUsers,updateUsers,deleteUsers,addClasses)
          # faux update countdown
          inter = setInterval =>
            if addUsers.length > 5
              addUsers.length -= 5
              @trigger 'user_imported'
            else 
              addUsers.length = 0
              @trigger 'user_imported'
              clearInterval(inter)
          ,100
          return
        else if updateUsers.length > 0
          @save(updateUsers.shift())
        else if deleteUsers.length > 0
          @remove(deleteUsers.shift())
        
      if addUsers.length+updateUsers.length+deleteUsers.length > 0
        setTimeout =>
          @import(addUsers,updateUsers,deleteUsers,addClasses)
          @trigger 'user_imported' #refresh after each send
        ,100 #limits to 100 req per second
      else
        @savecount = 0 #reset to zero to prevent lock
        setTimeout => 
          @trigger 'users_imported'
          @trigger 'user_load' #reload after import complete
        ,2000

makeDownload = (content, fileName, mimeType)->
  a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';
  a.href = URL.createObjectURL(new Blob([content], {type: mimeType}))
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
