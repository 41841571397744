
    var riot = require('riot')
    
riot.tag2('accountpanel', '<div if="{teacher==true || admin==true}" class="accountpanel"> <panel heading="My Account Details"> <accountdetails stores="{parent.opts.stores}"></accountdetails> </panel> <panel heading="Educational Institution"> <schooldetails stores="{parent.opts.stores}" mode="{parent.opts.mode}"></schooldetails> </panel> <panel heading="Subscription"> <subscription stores="{parent.opts.stores}" mode="{parent.opts.mode}"></subscription> </panel> </div> <div if="{student==true}" class="accountpanel"> <panel heading="My Account Details" class="row-sm-3"> <accountdetails stores="{parent.opts.stores}"></accountdetails> </panel> <panel heading="Educational Institution" class="row-sm-3"> <schooldetails stores="{parent.opts.stores}" mode="{parent.opts.mode}"></schooldetails> </panel> </div>', '.accountpanel { display: -webkit-box; display: -moz-box; display: -webkit-flex; display: -ms-flexbox; display: box; display: flex; -webkit-box-lines: multiple; -moz-box-lines: multiple; -o-box-lines: multiple; -webkit-flex-wrap: wrap; -ms-flex-wrap: wrap; flex-wrap: wrap; gap: 10px; } .accountpanel > * { -webkit-box-flex: 1; -moz-box-flex: 1; -o-box-flex: 1; box-flex: 1; -webkit-flex: 1 1 400px; -ms-flex: 1 1 400px; flex: 1 1 400px; }', '', function(opts) {
this.on('mount', function() {
  this.teacher = false;
  this.student = false;
  opts.stores.on('session_changed', this.sessionUpdated);
  return opts.stores.trigger('session_sync');
});

this.on('error', function(err) {
  return console.error(err);
});

this.on('unmount', function() {
  return opts.stores.off('session_changed', this.sessionUpdated);
});

this.sessionUpdated = (function(_this) {
  return function(data) {
    var ref;
    _this.groups = ((ref = data.user) != null ? ref.groups : void 0) ? data.user.groups.map(function(group) {
      return group.name;
    }) : [];
    _this.user = data.user;
    if (_this.groups.indexOf("Teachers") > -1) {
      _this.teacher = true;
    }
    if (_this.groups.indexOf("Students") > -1) {
      _this.student = true;
    }
    if (_this.groups.indexOf("School Admins") > -1) {
      _this.admin = true;
    }
    return _this.update();
  };
})(this);
});
    
  