
    var riot = require('riot')
    
riot.tag2('subscription', '<alert bsstyle="danger" if="{daysLeft&lt;20}"> <h4>Alert</h4> <p>Your subscription expires in <strong>{parent.daysLeft} {parent.daysLeft>1?⁗days⁗:⁗day⁗}</strong>, <a href="/vle/renew" class="alert-link">please click here to renew and avoid loss of service.</a></p> </alert> <alert bsstyle="warning" if="{daysLeft&lt;=30 &amp;&amp; daysLeft&gt;=20}"> <h4>Notice</h4> <p>Your subscription expires in {parent.daysLeft} days,<a href="/vle/renew"> please click here to renew.</a></p> </alert> <alert bsstyle="success" if="{daysLeft&gt;30}"> <p>You have {parent.daysLeft} days left of your subscription.</p> </alert> <alert bsstyle="info" class="clearfix"> <p><strong>Subscription Types </strong></p> <ul> <li if="{parent.org.aslevel}">AS Level </li> <li if="{parent.org.gcse}">GCSE</li> <li if="{parent.org.wjec}">GCSE (WJEC)</li> <li if="{parent.org.legacy}">GCSE (Legacy)</li> <li if="{parent.org.primary}">Primary Maths</li> <li if="{parent.org.ks3}">Key Stage 3</li> <li if="{parent.org.igcse_cambridge}">IGCSE Cambridge</li> <li if="{parent.org.igcse_edexcel}">IGCSE Edexcel</li> </ul> </alert>', '', '', function(opts) {
this.status = '';

this.on('mount', function() {
  opts.stores.on('session_changed', this.sessionUpdated);
  return opts.stores.trigger('session_sync');
});

this.on('unmount', function() {
  opts.stores.off('session_changed', this.sessionUpdated);
  return opts.stores.off('user_saved', this.userUpdated);
});

this.sessionUpdated = (function(_this) {
  return function(data) {
    var groups, ref;
    groups = ((ref = data.user) != null ? ref.groups : void 0) ? data.user.groups.map(function(group) {
      return group.name;
    }) : [];
    _this.daysLeft = Math.round((data.org.subscription_end - new Date()) / 1000 / 60 / 60 / 24);
    _this.org = data.org;
    return _this.update({
      user: data.user,
      groups: groups
    });
  };
})(this);
});
    
  