
    var riot = require('riot')
    
riot.tag2('extras', '<iframe riot-src="{src}" onload="{frameloaded}" name="contentframe" riot-style="width:100%;height:{height}px;border:0px;display:{showing}" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>', '', '', function(opts) {
var frameready;

console.log("extras");

frameready = require("./frameready.js");

this.height = 500;

this.quals = {
  aslevel: "as_level",
  gcse: "gcse",
  ks3: "ks_3",
  primary: "primary",
  wjec: "wjec",
  legacy: "legacy"
};

this.on('updated', function() {
  var src;
  this.framedoc = this.contentframe.contentDocument || this.contentframe.contentWindow.document;
  src = this.framedoc.location.pathname;
  if (!src.match(/blank/) && "/vle" + src.replace(/\/$/, "") !== document.location.pathname.replace(/\/$/, "")) {
    this.src = document.location.pathname.replace(/^\/vle\//, "/");
    return this.framedoc.location = this.src;
  }
});

this.on('mount', function() {
  this.showing = "none";
  opts.stores.on('session_changed', this.sessionUpdated);
  opts.stores.trigger('session_sync');
  return frameready(document.querySelector("iframe"), (function(_this) {
    return function() {
      return _this.frameloaded();
    };
  })(this));
});

this.on('unmount', function() {
  opts.stores.off('session_changed', this.sessionUpdated);
  return this.root.parentNode.parentNode.className = "container";
});

this.frameloaded = (function(_this) {
  return function() {
    var innerurl, outerurl, src;
    _this.framedoc = _this.contentframe.contentDocument || _this.contentframe.contentWindow.document;
    src = _this.framedoc.location.pathname;
    if (!src || src.match(/blank/) || !src.match(/^\/extras\//)) {
      innerurl = document.location.pathname.replace(/^\/vle\//, "/");
      _this.src = innerurl;
      _this.update();
    } else {
      outerurl = src.replace(/^\/extras\//, "/vle/extras/").replace(/\/blank\//, "/");
      window.history.replaceState({
        page: true
      }, null, outerurl);
    }
    [].slice.call(_this.framedoc.querySelectorAll('a')).forEach(function(link) {
      var ref;
      if ((ref = link.getAttribute("href")) != null ? ref.match(/\.pdf$/) : void 0) {
        return link.setAttribute("target", "_blank");
      }
    });
    _this.hideButtons();
    _this.showing = "block";
    return setTimeout(function() {
      _this.height = Math.max(500, _this.docHeight(_this.framedoc), _this.docHeight(document) - 140);
      return _this.update();
    });
  };
})(this);

this.sessionUpdated = (function(_this) {
  return function(session) {
    _this.session = session;
    _this.hideButtons();
    return _this.update();
  };
})(this);

this.docHeight = function(doc) {
  return Math.max(doc.body.scrollHeight, doc.body.offsetHeight, doc.documentElement.clientHeight, doc.documentElement.scrollHeight, doc.documentElement.offsetHeight);
};

this.hideButtons = (function(_this) {
  return function() {
    var key, menu, ref, ref1, ref2, val;
    if (!_this.framedoc || !_this.session) {
      return;
    }
    menu = [].slice.call(_this.framedoc.querySelectorAll('.nav-stacked a'));
    ref = _this.quals;
    for (key in ref) {
      val = ref[key];
      if (!((ref1 = _this.session) != null ? ref1.org[key] : void 0)) {
        menu.forEach(function(a) {
          if (a.href.match(val)) {
            return a.style.display = "none";
          }
        });
      }
    }
    return (ref2 = _this.framedoc.querySelector('.menuwrap')) != null ? ref2.style.display = "block" : void 0;
  };
})(this);
});
    
  