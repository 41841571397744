<script>export let value = "";
export let items = [];
export let labelField;
export let valueField;
export let maxItems = 5;
export let required = null;
let activeIndex = 0;
let hidden = true;
let found = false;
export let inputvalue;
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
const search = (query = "", data = []) => {
  if (query) {
    let esc_query = escapeRegExp(query);
    let matchany = new RegExp("^(?=.*" + esc_query.replace(/\s/, ")(?=.*") + ").*$", "i");
    let matchstart = new RegExp("^" + esc_query + ".*$", "i");
    return data.filter(item => JSON.stringify(item).match(matchany)) // match all containing
    .sort((a, b) => {
      // sort by those which match start
      let m1 = a[labelField] ? a[labelField].match(matchstart) : "";
      let m2 = b[labelField] ? b[labelField].match(matchstart) : "";
      return (m1 ? 1 : 0) > (m2 ? 1 : 0) ? -1 : 1;
    });
  } else if (data) {
    return data.slice();
  }
};
let options = search(inputvalue, items).slice(0, maxItems);
function handleKey(e) {
  if (e.key == "ArrowDown" && activeIndex < options.length - 1) {
    activeIndex += 1;
  }
  if (e.key == "ArrowUp" && activeIndex > 0) {
    activeIndex -= 1;
  }
  if (e.key == "Enter") {
    if (activeIndex > -1 && !hidden && inputvalue && inputvalue.length) {
      inputvalue = options[activeIndex][labelField];
      value = options[activeIndex][valueField];
      found = true;
    } else {
      inputvalue = e.target.value;
      value = null;
      found = false;
    }
    e.target.blur();
  }
  if (activeIndex > options.length - 1) {
    activeIndex = options.length - 1;
  }
}
function getFromItems(items) {
  if (!hidden) {
    return inputvalue;
  }
  if (value != null && items.filter(item => item[valueField] == value).length) {
    found = true;
    return items.filter(item => item[valueField] == value)[0][labelField];
  }
  found = false;
  return inputvalue;
}
function handleBlur(e) {
  hidden = true;
  let tmp_inputvalue = items.filter(item => item[valueField] == value).length ? items.filter(item => item[valueField] == value)[0][labelField] : "";
  if (inputvalue == "") value = null;
  if (tmp_inputvalue != inputvalue) {
    value = null;
  }
}
$: inputvalue = getFromItems(items, inputvalue);</script>

<div class="autocomplete">
  <input type="text" class="form-input" autocomplete="typeahead" required={required} bind:value={inputvalue} on:focus={(e)=> hidden=false} on:blur={handleBlur} on:keydown={handleKey} on:input={(e)=> options = search(e.target.value,items).slice(0,maxItems)}/>
  {#if inputvalue && inputvalue.length > 0 && !hidden}
    <div class="optionlist">
      {#each options as option,idx}
        <div 
          on:mouseover={(e)=> activeIndex=idx}
          on:mousedown={(e)=>{ 
            inputvalue = options[idx][labelField]; 
            value = options[activeIndex][valueField]
          }}
          class={activeIndex == idx ? "active" : ""}>
            {option[labelField]}
        </div>
      {/each}
    </div>
  {/if}
</div>

<style>
  .autocomplete{
    width:100%;
    position:relative
  }
  .optionlist{
    position:absolute;
    left:0;
    right:0;
    top:35px;
    background:white;
    box-shadow:0 1px 5px rgba(0,0,0,0.3);
    z-index:1001;
  }
  .optionlist > div{
    padding:7px 10px;
    cursor:pointer;
  }
  .optionlist > div.active{
    background:#eee;
  }
</style>