<div class="wrap">
  <canvas bind:this={canvas}></canvas>
  <div class="icon" class:show={showNumber}></div>
    <slot />
  <div class="count" class:show={showNumber}>{count}</div> 
</div> 
<h3>{subtitle}</h3> 


<script>import Chart from 'chart.js';
import { onMount, onDestroy } from "svelte";
export let delay = 0,
  showNumber = false,
  subtitle = "",
  count = 0,
  total = 0;
let canvas, chart, width, shouldupdate;
let pieoptions = {
  segmentShowStroke: true,
  segmentStrokeWidth: 2,
  cutoutPercentage: 80,
  animationSteps: 100,
  animationEasing: "easeOutQuart",
  tooltips: {
    enabled: false
  }
};
onMount(() => {
  shouldupdate = false;
  Chart.defaults.global.responsive = true;
  let ctx = canvas.getContext("2d");
  chart = new Chart(ctx, {
    type: 'doughnut',
    data: {
      datasets: [{
        data: [0, 100],
        backgroundColor: ["#FFFFFF", "#FFFFFF"]
      }]
    },
    options: {
      ...pieoptions
    }
  });
  if (delay) {
    setTimeout(setup, delay * 1000);
  } else {
    setTimeout(setup);
  }
});
function setup() {
  showNumber = false;
  let options = {
    ...pieoptions,
    animation: {
      onProgress(anim) {
        if (anim.animationObject.currentStep < 10) showNumber = false;
      },
      onComplete() {
        showNumber = true;
      }
    }
  };
  Chart.defaults.global.responsive = true;
  let ctx = canvas.getContext("2d");
  chart = new Chart(ctx, {
    type: 'doughnut',
    data: {
      datasets: [{
        data: [0, 100],
        backgroundColor: ["#3AA6E3", "#e5f2fa"]
      }]
    },
    options: options
  });
  let done = count || 0;
  let notdone = total ? total - count : 100;
  chart.data.datasets[0].data[0] = done;
  chart.data.datasets[0].data[1] = notdone;
  chart.update();
  shouldupdate = true;
}
$: shouldupdate && setup(count, total);</script>


<style>

  .wrap {
    display: grid;
    grid-template-areas: "full";
    pointer-events: none;
  }

  .wrap :global(> *){
    min-width: 0;
    grid-area: full;
    align-self:center;
    justify-self:center;
  }

  .wrap :global(.circle) {
    background: #fec600;
    border-radius: 50%;
    margin-top:5px;
    text-align: center;
    width: calc(8.2vw + 2px);
  }
  .wrap :global(.circle .fa) {
    color: #fff;
    font-size: calc(2.5vw + 4px);
    line-height: calc(8.2vw + 2px);
  }

  h3 {
    color: #59a5de;
    text-align: center;
    font-size: calc(2vw + 10px);
  }

  .count {
    opacity: 0;
    background: #59a5de;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    transition: 0.5s opacity;
    border: 2px solid #fff;
    width: calc(8.2vw + 2px);
    line-height: calc(8vw + 2px);
    font-size: calc(2vw + 4px);
    align-self:center;
    justify-self:center;
    margin-top:5px;
    margin-left:calc(10vw + 2px) ;
    grid-area: full;
    z-index:10;
  }

  .count.show {
    opacity: 1;
  }

  @media (min-width: 768px) {
    .wrap :global(.circle) {
      width: calc(4.1vw + 2px);
    }

    .wrap :global(.circle .fa) {
      font-size: calc(1.5vw + 4px);
      line-height: calc(4.1vw + 2px);
    }
    .count {
      width: calc(4.1vw + 2px);
      line-height: calc(4vw + 2px);
      font-size: calc(1vw + 4px);
    }
    h3 {
      font-size: calc(1vw + 10px);
    }
  }

  @media (min-width: 1200px) {
    .wrap :global(.circle) {
      width: 55px;
    }
    .wrap :global(.circle .fa) {
      font-size: 20px;
      line-height: 55px;
    }
    .count {
      font-size: 20px;
      line-height: 55px;
      width: 55px;
    }
    h3 {
      font-size: 20px;
    }
  }


  </style>