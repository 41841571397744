<script>import Formdate from "duo-kit/src/shared/formdate.svelte";
import Formrow from "duo-kit/src/shared/formrow.svelte";
import { createEventDispatcher } from "svelte";
export let value;
let open = false;
let dispatch = createEventDispatcher();
let hours = Array(24).fill("").map((v, idx) => idx.toString().padStart(2, '0'));
let minutes = Array(60).fill("").map((v, idx) => idx.toString().padStart(2, '0'));
function setDate(e) {
  let newdate = e.detail;
  if (newdate == date) return; // prevent update loop
  value = newdate ? new Date(new Date(new Date(newdate).setHours(hour)).setMinutes(minute)) : null;
  open = false;
  dispatch("change", value);
}
function setHour(e) {
  let hr = +e.target.value;
  value = new Date(new Date(value).setHours(hr));
  dispatch("change", value);
}
function setMinutes(e) {
  let min = +e.target.value;
  value = new Date(new Date(value).setMinutes(min));
  dispatch("change", value);
}
$: hour = value && value.getHours ? value.getHours() : 0;
$: minute = value && value.getMinutes ? value.getMinutes() : 0;
$: date = value ? new Date(value) : null;</script>

<div class="datetime">
  <Formdate {...$$restProps} value={date} locale="en-GB" bind:open on:change={setDate}/>
  <Formrow type="select" disabled={$$restProps.disabled} value={hour} on:change={setHour}>{#each hours as hr}<option value={+hr}>{hr}</option>{/each}</Formrow>
  <Formrow type="select" disabled={$$restProps.disabled} value={minute} on:change={setMinutes}>{#each minutes as min}<option value={+min}>{min}</option>{/each}</Formrow>
</div>

<style>
  .datetime{
    display:grid;
    grid-template-columns: 1fr 60px 60px;
    margin:4px 4px;
    gap:1px;
  }
  .datetime :global(.form-row){
    margin:0 1px;
  }

</style>