// legacy code
const {RiotControl} = require("./site.coffee")
import api from "duo-kit/src/shared/api.js"
import component from "duo-kit/src/shared/component.js"
import AssignworkMulti from "./assignment/assignwork-multi.svelte"
import StudentStats from "./student_stats/student-stats.svelte"
component({component:AssignworkMulti,tagname:"assignwork-multi",href:"/resources/css/svelte.css",shadow:false,attributes:["isexam","assignedids"]}) 
component({component:StudentStats,tagname:"student-stats2",href:"/resources/css/svelte.css",shadow:false,attributes:["id"]}) 


// link riot login to svelte
api.registerLogin(()=> {
  return new Promise((resolve,reject)=>{
    RiotControl.one("login_success",()=>{
      resolve()
    })
    RiotControl.trigger("login_changed",{show:true}) // show login
  })
});