
    var riot = require('riot')
    
riot.tag2('formdate', '<forminput label="{opts.label}" value="{val}" name="{opts.name}" placeholder="{opts.placeholder}" buttonafter="&lt;i class=\'fa fa-calendar\'&gt;&lt;/i&gt;" width1="{opts.width1}" width2="{opts.width2}" buttonafterclick="{showcal}" onupdate="{handleTyping}"></forminput> <popover title="{opts.title}"> <datepicker onupdate="{parent.handleUpdate}" date="{parent.date}"></datepicker> </popover>', 'formdate .fa.fa-calendar{ -webkit-pointer-events:none; -moz-pointer-events:none; pointer-events:none; }', '', function(opts) {
var Formatter;

Formatter = require('riot-kit/utils/dateformat.coffee');

this.val = "";

this.prevValue = "";

this.on('update', function() {
  if (opts.value && this.prevValue !== opts.value.getTime()) {
    this.setDate(opts.value);
    return this.date = opts.value;
  }
});

this.showcal = (function(_this) {
  return function(e) {
    return _this.tags.popover.clicked(e);
  };
})(this);

this.handleUpdate = (function(_this) {
  return function(newdate, changetypes) {
    if (changetypes.indexOf('month') !== -1 || changetypes.indexOf('year') !== -1) {
      return;
    }
    _this.setDate(newdate);
    _this.updateParent(newdate, 1);
    _this.tags.popover.hide();
    return _this.update();
  };
})(this);

this.handleTyping = (function(_this) {
  return function(data) {
    var dt, newdate;
    dt = new Formatter();
    newdate = dt.parseString(opts.dateformat, data.value);
    if (newdate) {
      _this.date = newdate;
      _this.updateParent(newdate, 2);
      return _this.update();
    }
  };
})(this);

this.updateParent = (function(_this) {
  return function(newdate, type) {
    var name;
    if (opts.onupdate) {
      name = (opts.id != null) && opts.id.length ? opts.id : opts.name;
      return opts.onupdate.call(null, {
        name: name,
        value: newdate
      });
    }
  };
})(this);

this.setDate = (function(_this) {
  return function(date) {
    var dt;
    _this.prevValue = date.getTime();
    if (!opts.dateformat || !date) {
      return _this.val = date;
    } else {
      dt = new Formatter(date);
      return _this.val = dt.format(opts.dateformat);
    }
  };
})(this);
});
    
  