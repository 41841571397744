module.exports = {
  level:{
    'GCSE':'Tier',
    'GCSE (WJEC)':'Tier',
    'GCSE (Legacy)':'Tier',
    'IGCSE (Cambridge)':'Tier',
    'IGCSE (Edexcel)':'Tier',
    'AS Level Old':'Exam Board',
    'KS3':'Standard',
    'Primary':'Year'},
  grade:{
    'GCSE':'Grade',
    'GCSE (WJEC)':'Grade',
    'GCSE (Legacy)':'Grade',
    'IGCSE (Cambridge)':'Grade',
    'IGCSE (Edexcel)':'Grade',
    'AS Level Old':"Exam",
    'AS Level':"Module",
    'KS3':null,
    'Primary':null},
  topic:{
    'GCSE':'Topic',
    'GCSE (WJEC)':'Topic',
    'GCSE (Legacy)':'Topic',
    'IGCSE (Cambridge)':'Topic',
    'IGCSE (Edexcel)':'Topic',
    'AS Level Old':'Module',
    'AS Level':"Topic",
    'KS3':'Topic',
    'Primary':'Topic'}
}