
    var riot = require('riot')
    
riot.tag2('textinput', '<forminput label="{opts.label}" width1="{opts.width1}" width2="{opts.width2}" id="{opts.id}" value="{opts.showanswer ? opts_answer : opts.value}" addonbefore="{opts.addonbefore}" addonafter="{opts.addonafter}" onupdate="{onupdate}" autocomplete="off"></forminput>', '', '', function(opts) {
this.elem = null;

this.value = null;

this.onupdate = (function(_this) {
  return function(e) {
    return opts.onupdate({
      name: opts.id,
      value: e.value
    });
  };
})(this);

this.on('update', function() {
  var opts_answer, ref;
  opts_answer = JSON.parse(opts.answers);
  if (opts.showanswer) {
    return this.opts_answer = ((ref = opts_answer.split(";")) != null ? ref[0] : void 0) || opts_answer;
  }
});

this.on('updated', function() {
  return this.root.querySelector("input").style.color = opts.showanswer ? "red" : "black";
});
});
    
  