
    var riot = require('riot')
    
riot.tag2('usage-clipick', '<div class="col-xs-4"> <formselect id="qualification" width1="5" width2="7" onupdate="{handleChange}" default="Select" value="{chosen.qualification}" label="Qualification" options="{filters.qualifications}"></formselect> </div> <div if="{labels.level}" class="col-xs-4"> <formselect id="level" width1="5" width2="7" onupdate="{handleChange}" default="All" value="{chosen.level}" label="{labels.level}" options="{filters.levels}"></formselect> </div> <div if="{labels.grade}" class="col-xs-4"> <formselect id="grade" width1="5" width2="7" onupdate="{handleChange}" default="Summary" value="{chosen.grade}" label="{labels.grade}" options="{filters.grades}"></formselect> </div> <div if="{labels.topic &amp;&amp; (chosen.qualification==\'KS3\' || chosen.qualification==\'Primary\') &amp;&amp; chosen.level}" class="col-xs-4"> <formselect id="topic" width1="5" width2="7" onupdate="{handleChange}" default="All" value="{chosen.topic}" label="{labels.topic}" options="{filters.topics}"> </formselect> </div>', '', '', function(opts) {
var filters, labels;

filters = {
  qualification: '',
  level: '',
  clip_id: '',
  grade: '',
  topic: '',
  search: '',
  when: ''
};

this.labels = {};

this.search = '';

labels = require('../../client/labels.js');

this.on('mount', function() {
  return this.restored = false;
});

this.on('update', function() {
  if (opts.all && !this.restored) {
    this.restored = true;
    return this.removeTimeout = setTimeout((function(_this) {
      return function() {
        var ref;
        if ((ref = window.localStorage) != null ? ref.usageChosen : void 0) {
          _this.chosen = JSON.parse(window.localStorage.usageChosen);
          _this.qualificationChange(_this.chosen.qualification);
          _this.filterOptions(_this.chosen.qualification);
          if (opts.onfilter) {
            opts.onfilter(_this.filter(_this.chosen), _this.chosen);
          }
        } else {
          _this.chosen = {};
          _this.filterOptions();
          _this.qualificationChange(_this.chosen.qualification);
          if (opts.onfilter) {
            opts.onfilter(_this.filter(_this.chosen), _this.chosen);
          }
        }
        return _this.update();
      };
    })(this));
  }
});

this.on('unmount', function() {
  return clearTimeout(this.removeTimeout);
});

this.filterOptions = (function(_this) {
  return function(qualification) {
    var gradefilt, levelfilt, qualfilt, ref, ref1, ref2, ref3, ref4, ref5, ref6;
    if (!opts.all || !opts.all.length) {
      return;
    }
    if (!qualification) {
      qualification = (ref = _this.groupSort(opts.all, 'qualification')) != null ? (ref1 = ref.sort()) != null ? (ref2 = ref1[0]) != null ? ref2.value : void 0 : void 0 : void 0;
    }
    qualfilt = opts.all.filter(function(row) {
      return row.qualification === qualification;
    });
    levelfilt = ((ref3 = _this.chosen) != null ? ref3.level : void 0) ? qualfilt.filter(function(row) {
      return row.level.match(_this.chosen.level);
    }) : qualfilt;
    gradefilt = ((ref4 = _this.chosen) != null ? ref4.grade : void 0) ? levelfilt.filter(function(row) {
      return row.grade === _this.chosen.grade;
    }) : levelfilt;
    if (((ref5 = _this.chosen) != null ? ref5.level : void 0) === "") {
      _this.chosen.topic = "";
    }
    _this.filters = {};
    _this.filters.qualifications = _this.groupSort(opts.all, 'qualification').sort();
    _this.filters.levels = _this.groupSort(qualfilt, 'level', qualification === 'KS3' || (qualification && qualification.match(/gcse/i)) ? false : true);
    _this.filters.grades = _this.groupSort(levelfilt, 'grade');
    _this.filters.topics = _this.groupSort(gradefilt, 'topic').sort();
    if (!((ref6 = _this.chosen) != null ? ref6.qualification : void 0)) {
      return _this.chosen.qualification = _this.filters.qualifications[0].value;
    }
  };
})(this);

this.qualificationChange = (function(_this) {
  return function(qualification) {
    _this.labels = {
      level: labels.level[qualification],
      grade: labels.grade[qualification],
      topic: labels.topic[qualification]
    };
    return _this.update();
  };
})(this);

this.handleChange = (function(_this) {
  return function(row) {
    if (row.name === 'qualification') {
      _this.qualificationChange(row.value);
      _this.chosen = {
        qualification: '',
        level: '',
        clip_id: '',
        grade: '',
        topic: '',
        search: '',
        when: ''
      };
    }
    if (row.name == null) {
      return;
    }
    _this.chosen[row.name] = row.value;
    if (window.localStorage) {
      window.localStorage.usageChosen = JSON.stringify(_this.chosen);
    }
    _this.filterOptions(_this.chosen.qualification);
    if (opts.onfilter) {
      opts.onfilter(_this.filter(_this.chosen), _this.chosen);
    }
    return _this.update();
  };
})(this);

this.filter = (function(_this) {
  return function(chosen) {
    var add, data, filt1, filtered, i, key, len, ref, ref1, reg, row, val;
    data = opts.all.slice();
    if (!chosen || !chosen.qualification) {
      return _this.filtered = [];
    }
    filtered = [];
    if (chosen.search) {
      reg = new RegExp(chosen.search.split(' ').join(".+"), "ig");
    }
    filt1 = data.filter(function(row) {
      var ref, ref1, ref2;
      return ((ref = row.clip_id) != null ? ref.match(reg) : void 0) || ((ref1 = row.name) != null ? ref1.match(reg) : void 0) || ((ref2 = row.description) != null ? ref2.match(reg) : void 0);
    });
    for (i = 0, len = filt1.length; i < len; i++) {
      row = filt1[i];
      add = true;
      if (row.qualification !== _this.chosen.qualification) {
        add = false;
      }
      ref = _this.chosen;
      for (key in ref) {
        val = ref[key];
        if (val === "Higher" && _this.chosen.qualification === "GCSE") {
          val = "";
        }
        val = val.replace(/[*]+/, '\\\*');
        if (key !== "search" && "" + row[key] !== "" + val && val !== "" && !((ref1 = row[key]) != null ? ref1.match(val) : void 0)) {
          add = false;
        }
      }
      if (add) {
        filtered.push(row);
      }
    }
    return filtered;
  };
})(this);

this.groupSort = (function(_this) {
  return function(collection, field, sort) {
    var rows;
    if (sort == null) {
      sort = true;
    }
    rows = _this.groupby(collection, field);
    if (sort) {
      rows = rows.sort(function(a, b) {
        if (!a && !b) {
          return 0;
        }
        if (a[field] > b[field]) {
          return 1;
        } else if (a[field] === b[field]) {
          return 0;
        } else {
          return -1;
        }
      });
    }
    return rows.map(function(video) {
      return {
        label: video[field],
        value: video[field]
      };
    });
  };
})(this);

this.groupby = function(collection, field) {
  var rows, unique;
  unique = [];
  rows = [];
  collection.forEach(function(row) {
    var i, key, keys, len, newRow, results;
    if (row[field]) {
      keys = row[field].split(/\//);
      results = [];
      for (i = 0, len = keys.length; i < len; i++) {
        key = keys[i];
        if (unique.indexOf(key) === -1) {
          unique.push(key);
          newRow = Object.assign({}, row);
          newRow[field] = key;
          results.push(rows.push(newRow));
        } else {
          results.push(void 0);
        }
      }
      return results;
    }
  });
  return rows;
};
});
    
  