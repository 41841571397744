
    var riot = require('riot')
    
riot.tag2('timer-mini', '<dateformat if="{timer &amp;&amp; displayDate}" format="Time Remaining h:mm:ss" date="{displayDate}"></dateformat>', '', '', function(opts) {
var interval, timer;

timer = null;

interval = null;

this.on('mount', function() {
  return this.paused = false;
});

this.pause = function() {
  return this.update({
    paused: true
  });
};

this.unpause = function() {
  return this.update({
    paused: false
  });
};

this.startTimer = function(duration) {
  if (timer || (!opts.duration && !duration)) {
    return;
  }
  this.timer = timer = duration || opts.duration;
  this.step = 100 / this.timer;
  this.percent = 100 - this.timer * this.step;
  interval = setInterval((function(_this) {
    return function() {
      if (_this.paused) {
        return;
      }
      _this.timer--;
      _this.setTime();
      _this.percent = _this.timer * _this.step;
      if (_this.percent <= 0 && opts.oncomplete) {
        opts.oncomplete();
        return clearInterval(interval);
      }
    };
  })(this), 1000);
  return this.setTime();
};

this.setTime = (function(_this) {
  return function() {
    var hours, minutes, seconds, totalSeconds;
    totalSeconds = _this.timer;
    hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    minutes = Math.floor(totalSeconds / 60);
    seconds = totalSeconds % 60;
    _this.displayDate = new Date(1970, 1, 1, hours, minutes, seconds);
    return _this.update();
  };
})(this);

this.on('unmount', function() {
  if (interval) {
    return clearInterval(interval);
  }
});
});
    
  