#Save using bs+jq for nav.. perhaps covert to riot or something later??

#Navbar and dropdowns
qsall = (elem,selector)-> [].slice.call(elem.querySelectorAll(selector))

toggle = document.querySelectorAll('.navbar-toggle')[0]
collapse = document.querySelectorAll('.navbar-collapse')[0]
dropdowns = qsall(document,'.dropdown')

#Toggle if navbar menu is open or closed
toggleMenu = ->
    collapse.classList.toggle('collapse');
    collapse.classList.toggle('in');

#Close all dropdown menus
 closeMenus = ->
  [0..dropdowns.length].forEach (dropdown)->
    dropdown.getElementsByClassName('dropdown-toggle')[0].classList.remove('dropdown-open')
    dropdown.classList.remove('open');


#Add click handling to dropdowns
dropdowns.forEach (dropdown)->
  
  dropdown.addEventListener 'click', ->
    if (document.body.clientWidth < 768) 
      open = this.classList.contains('open')
      closeMenus()
      if (!open) 
        this.getElementsByClassName('dropdown-toggle')[0].classList.toggle('dropdown-open')
        this.classList.toggle('open');


toggle.addEventListener('click', toggleMenu, false) if toggle