
    var riot = require('riot')
    
riot.tag2('questionstats', '<div class="download"> <form method="post" action="/duocms/api/logs?filter=csv" name="downloadform"> <input type="hidden" name="csv" value="{csvContent}"> <input type="hidden" name="_csrf" value="{window.csrf}"> <input type="hidden" name="filename" value="practice_questions"> <button onclick="{downloadStats}" class="btn btn-default"><span class="fa fa-download"></span><span class="hidden-xs"> Download Usage</span></button> </form> </div> <div class="row videostatsearch"> <div class="col-xs-2"> <formselect label="Class" options="{classes}" value="{chosenclass}" default="All" onupdate="{classSelected}" width1="4" width2="8"></formselect> </div> <div class="col-xs-2"> <formselect id="grade" width1="3" width2="9" onupdate="{handleWhenChage}" default="All" value="{when}" label="When" options="{whenopts}"></formselect> </div> <div class="col-xs-8"> <usage-clipick all="{videos}" onfilter="{videosFiltered}"></usage-clipick> </div> </div> <div class="row"> <div class="col-xs-12"> <grid2 columns="{gridColumns}" data="{stats}" height="{490}" onselect="{handleSelect}" rowheight="{60}" class="clipstats"></grid2> </div> </div>', 'grid.clipstats .gridcell { height: 30px; } .download { position: absolute; top: 60px; right: 15px; }', '', function(opts) {
this.on('mount', function() {
  var schoolyr;
  this.summary = false;
  this.loginsPerUser = {};
  this.when = "";
  schoolyr = new Date().getMonth() < 8 ? new Date(new Date().getFullYear() - 1, 8, 1) : new Date(new Date().getFullYear(), 8, 1);
  this.whenopts = [
    {
      label: 'last week',
      value: this.daysToSecAgo(7)
    }, {
      label: 'last 4 weeks',
      value: this.daysToSecAgo(28)
    }, {
      label: 'from ' + this.dateToStringShort(schoolyr),
      value: schoolyr.getTime()
    }
  ];
  this.stats = [];
  opts.stores.on('logs_changed_practiceQuestionsPerUser', this.practiceStatsChanged);
  opts.stores.on('users_changed', this.usersChanged);
  opts.stores.on('video_changed', this.videosChanged);
  opts.stores.on('classes_changed', this.handleClassChange);
  opts.stores.trigger('video_sync');
  return opts.stores.trigger('class_sync');
});

this.on('unmount', function() {
  opts.stores.off('logs_changed_practiceQuestionsPerUser', this.practiceStatsChanged);
  opts.stores.off('users_changed', this.usersChanged);
  opts.stores.off('video_changed', this.videosChanged);
  return opts.stores.off('classes_changed', this.handleClassChange);
});

this.videosChanged = (function(_this) {
  return function(videos) {
    _this.videos = videos;
    _this.video_from_clipid = {};
    _this.video_from_id = {};
    _this.videos.forEach(function(vid) {
      return _this.video_from_clipid[vid.id + "-" + vid.clip_id] = vid;
    });
    _this.videos.forEach(function(vid) {
      return _this.video_from_id[vid.id] = vid;
    });
    return _this.update();
  };
})(this);

this.usersChanged = (function(_this) {
  return function(users) {
    var i, len, val;
    _this.users = [];
    for (i = 0, len = users.length; i < len; i++) {
      val = users[i];
      if (val.account_type === "P") {
        _this.users.push(val);
      }
    }
    if (_this.users.length < 1) {
      return;
    }
    opts.stores.trigger('log_sync', 'practiceQuestionsPerUser');
    return _this.filterUsers();
  };
})(this);

this.videosFiltered = (function(_this) {
  return function(filtered, chosen) {
    var colwidth, groupby, lastgroup, order;
    if (!filtered) {
      return;
    }
    _this.gridColumns = [
      {
        field: 'name',
        label: 'Name',
        width: 170,
        fixed: true
      }, {
        field: 'classname',
        label: 'Class',
        width: 70,
        fixed: true
      }, {
        field: 'year',
        label: 'Yr',
        width: 40,
        fixed: true
      }, {
        field: 'last',
        label: 'Last Attempted',
        width: 105,
        fixed: true
      }
    ];
    _this.clip_ids = [];
    if (chosen.qualification === "GCSE" || chosen.qualification === "AS Level" || chosen.qualification === "GCSE (WJEC)" || chosen.qualification === "GCSE (Legacy)") {
      groupby = "grade";
    } else {
      groupby = "level";
    }
    if (!chosen[groupby]) {
      colwidth = 110;
      _this.summary = true;
      _this.valPerGroup = {};
      lastgroup = '';
      if (chosen.qualification === "AS Level" || chosen.qualification === "Primary") {
        filtered = filtered.sort(function(a, b) {
          if (a[groupby] > b[groupby]) {
            return 1;
          } else {
            return -1;
          }
        });
      }
      if (chosen.qualification === "KS3") {
        order = ["Emerging", "Expected", "Exceeding", "Excelling"];
        filtered = filtered.sort(function(a, b) {
          if (order.indexOf(a[groupby]) > order.indexOf(b[groupby])) {
            return 1;
          } else {
            return -1;
          }
        });
      }
      filtered.forEach(function(video) {
        if (video[groupby] !== lastgroup) {
          lastgroup = video[groupby];
          _this.gridColumns.push({
            field: video[groupby],
            label: video[groupby],
            width: colwidth,
            tag: "markscell"
          });
        }
        _this.clip_ids.push(video.id + "-" + video.clip_id);
        return _this.valPerGroup[video.id + "-" + video.clip_id] = lastgroup;
      });
    } else {
      colwidth = 85;
      _this.summary = false;
      filtered.forEach(function(video) {
        _this.clip_ids.push(video.id + "-" + video.clip_id);
        return _this.gridColumns.push({
          field: video.id + "-" + video.clip_id,
          label: video.clip_id,
          width: colwidth,
          tag: "markscell"
        });
      });
    }
    return opts.stores.trigger('user_sync');
  };
})(this);

this.practiceStatsChanged = (function(_this) {
  return function(logs1) {
    _this.logs = logs1;
    return _this.practiceStatsPerUser();
  };
})(this);

this.loginsChanged = (function(_this) {
  return function(logs) {
    _this.loginsPerUser = {};
    logs.forEach(function(log) {
      return _this.loginsPerUser[log.user_id] = {
        total: log.total,
        last: log.last
      };
    });
    _this.stats = _this.stats.map(function(row) {
      var lastlogin, ref, ref1;
      row.logins = ((ref = _this.loginsPerUser[row.id]) != null ? ref.total : void 0) || 0;
      lastlogin = (ref1 = _this.loginsPerUser[row.id]) != null ? ref1.last : void 0;
      row.last_login = lastlogin ? _this.dateToString(lastlogin) : "";
      return row;
    });
    return _this.update();
  };
})(this);

this.practiceStatsPerUser = (function(_this) {
  return function() {
    var last, vidstats;
    if (!_this.logs || !_this.filteredUsers) {
      return;
    }
    vidstats = {};
    last = {};
    _this.logs.forEach(function(log) {
      var ref;
      if (_this.when && _this.when > ((ref = log.updated_at) != null ? ref.getTime() : void 0)) {
        return;
      }
      if (vidstats[log.user_id] == null) {
        vidstats[log.user_id] = {};
      }
      vidstats[log.user_id][log.video_id + "-" + log.clip_id] = log;
      if (!last[log.user_id] || log.updated_at > last[log.user_id]) {
        return last[log.user_id] = log.updated_at;
      }
    });
    _this.stats = [];
    _this.stats = _this.filteredUsers.map(function(user) {
      var avail_marks, classes, last_attempt, lastgroup, lastlogin, ref, ref1, ref2, ref3, ref4, stat;
      classes = (ref = user.classrooms[0]) != null ? ref.name : void 0;
      lastgroup = null;
      avail_marks = 0;
      last_attempt = null;
      stat = {
        id: user.id,
        name: " " + user.surname + ", " + user.first_name,
        classname: classes,
        year: user.year,
        last: _this.dateToString(last[user.id])
      };
      _this.clip_ids.forEach(function(clip_id, index, self) {
        var marks, perc, ref1, ref2, ref3, ref4, ref5, ref6, ref7, total, updated;
        if (_this.summary) {
          if (lastgroup !== _this.valPerGroup[clip_id]) {
            if (lastgroup) {
              total = stat[lastgroup];
              perc = Math.round((1000 / avail_marks) * total) / 10;
              perc = isNaN(perc) ? 0 : perc;
              stat[lastgroup] = perc + "%" + "," + total + "/" + avail_marks + "," + _this.dateToString(last_attempt);
            }
            lastgroup = _this.valPerGroup[clip_id];
            stat[lastgroup] = 0;
            avail_marks = 0;
            last_attempt = null;
          }
          stat[lastgroup] += ((ref1 = vidstats[user.id]) != null ? ref1[clip_id] : void 0) != null ? +((ref2 = vidstats[user.id]) != null ? ref2[clip_id].marks : void 0) : +0;
          avail_marks += _this.video_from_clipid[clip_id].ques_marks;
          if (((ref3 = vidstats[user.id]) != null ? (ref4 = ref3[clip_id]) != null ? ref4.updated_at : void 0 : void 0) > last_attempt) {
            last_attempt = vidstats[user.id][clip_id].updated_at;
          }
          if (index === self.length - 1) {
            total = stat[lastgroup];
            perc = Math.round((1000 / avail_marks) * total) / 10;
            perc = isNaN(perc) ? 0 : perc;
            return stat[lastgroup] = perc + "%" + "," + total + "/" + avail_marks + ",";
          }
        } else {
          if (((ref5 = vidstats[user.id]) != null ? ref5[clip_id] : void 0) != null) {
            marks = (ref6 = vidstats[user.id]) != null ? ref6[clip_id].marks : void 0;
            updated = (ref7 = vidstats[user.id]) != null ? ref7[clip_id].updated_at : void 0;
            perc = Math.round(marks / _this.video_from_clipid[clip_id].ques_marks * 100);
            perc = isNaN(perc) ? 0 : perc;
            return stat[clip_id] = perc + "%," + marks + "/" + _this.video_from_clipid[clip_id].ques_marks + "," + _this.dateToString(updated);
          } else if (_this.video_from_clipid[clip_id].ques_marks != null) {
            return stat[clip_id] = "";
          } else {
            return stat[clip_id] = "";
          }
        }
      });
      stat.logins = ((ref1 = _this.loginsPerUser) != null ? (ref2 = ref1[user.id]) != null ? ref2.total : void 0 : void 0) || 0;
      lastlogin = (ref3 = _this.loginsPerUser) != null ? (ref4 = ref3[user.id]) != null ? ref4.last : void 0 : void 0;
      stat.last_login = lastlogin ? _this.dateToString(lastlogin) : "";
      return stat;
    });
    return _this.update();
  };
})(this);

this.averageArray = function(arr, suffix) {
  return Math.round((arr.reduce((function(a, b) {
    return a + b;
  }), 0)) / arr.length) + suffix;
};

this.dateToString = function(dt) {
  if (!dt) {
    return "";
  }
  return dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
};

this.dateToStringShort = function(dt) {
  if (!dt) {
    return "";
  }
  return dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + (dt.getFullYear() - 2000);
};

this.handleClassChange = (function(_this) {
  return function(classes) {
    _this.classes = classes.map(function(c) {
      return {
        label: c.name,
        value: c.id
      };
    });
    return _this.filterUsers();
  };
})(this);

this.handleWhenChage = (function(_this) {
  return function(options) {
    _this.when = options.value;
    opts.stores.trigger('log_sync', 'loginsPerUser', {
      since: options.value
    });
    return _this.practiceStatsPerUser();
  };
})(this);

this.classSelected = (function(_this) {
  return function(opt) {
    _this.chosenclass = +opt.value;
    return _this.filterUsers();
  };
})(this);

this.filterUsers = (function(_this) {
  return function() {
    if (!_this.users) {
      return;
    }
    if (!_this.chosenclass) {
      _this.filteredUsers = _this.users.slice();
    } else {
      _this.filteredUsers = _this.users.filter(function(user) {
        return user.classrooms.filter(function(cr) {
          return cr.id === _this.chosenclass;
        }).length > 0;
      });
    }
    _this.update();
    return _this.practiceStatsPerUser();
  };
})(this);

this.daysToSecAgo = function(days) {
  var seconds;
  seconds = days * 60 * 60 * 24 * 1000;
  return new Date().getTime() - seconds;
};

this.downloadStats = (function(_this) {
  return function() {
    var cols, csv, csvContent;
    csv = [];
    cols = _this.gridColumns.map(function(col) {
      return col.field;
    });
    csv[0] = _this.gridColumns.map(function(col) {
      return col.label.replace('8 - 9', '\t8 - 9\t');
    });
    _this.stats.forEach(function(row, idx) {
      return csv[idx + 1] = cols.map(function(field) {
        return _this.stats[idx][field];
      });
    });
    csvContent = "";
    csv.forEach(function(infoArray, index) {
      var dataString;
      dataString = '"' + infoArray.join('","') + '"';
      return csvContent += index < csv.length ? dataString + "\n" : dataString;
    });
    _this.csvContent = csvContent;
    _this.update();
    return _this.downloadform.submit();
  };
})(this);
});
riot.tag2('markscell', '<div><small class="pull-left">{perc}</small><small class="pull-right">{marks}</small></div><br> <div class="text-center"> <small>{date}</small></div>', '', '', function(opts) {
this.on('update', (function(_this) {
  return function() {
    var parts;
    parts = opts.value.split(",");
    _this.perc = parts[0];
    _this.marks = parts[1];
    return _this.date = parts[2];
  };
})(this));
});
    
  