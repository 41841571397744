
    var riot = require('riot')
    
riot.tag2('contactpanel', '<panel heading="Contact" class="row2"> <p if="{opts.mode==\'student\'}">The below form will be sent to your teacher</p> <formhorizontal width1="2" width2="10" submit="{parent.send}"> <forminput name="subject" onupdate="{parent.parent.handleChange}" value="{parent.parent.data.subject}" label="Subject"></forminput> <forminput name="email" onupdate="{parent.parent.handleChange}" value="{parent.parent.data.email}" label="Your Email"></forminput> <formtextarea name="message" rows="8" onupdate="{parent.parent.handleChange}" value="{parent.parent.data.message}" label="Message"></formtextarea> <div class="btn-toolbar"> <button type="submit" class="btn btn-primary pull-right">Send</button> </div> </formhorizontal> </panel>', '', '', function(opts) {
var request;

request = require('superagent');

this.counter = 0;

this.data = {
  subject: '',
  message: '',
  email: ''
};

this.on('mount', function() {
  request.Request.prototype.csrf = function(token) {
    if (!token) {
      token = window.csrf;
    }
    this.set('X-CSRF-Token', token);
    return this;
  };
  opts.stores.on('session_changed', this.sessionUpdated);
  return opts.stores.trigger('session_sync');
});

this.on('unmount', function() {
  return opts.stores.off('session_changed', this.sessionUpdated);
});

this.handleChange = (function(_this) {
  return function(input) {
    return _this.data[input.name] = input.value;
  };
})(this);

this.sessionUpdated = (function(_this) {
  return function(session) {
    _this.session = session;
    _this.data.email = _this.session.user.email;
    _this.data.orgname = _this.session.org.name;
    return _this.update();
  };
})(this);

this.send = (function(_this) {
  return function(e) {
    var errors;
    errors = [];
    if (!_this.data.subject) {
      errors.push("Please add a subject");
    }
    if (!_this.data.message) {
      errors.push("Please add a message");
    }
    if (errors.length > 0) {
      opts.stores.trigger('toaster_add', {
        title: 'Error',
        type: 'warning',
        message: errors.join("\n")
      });
      return;
    }
    return request.post("/duocms/api/contact").send(_this.data).csrf().end(function(err, res) {
      if (+res.status === 200) {
        opts.stores.trigger('toaster_add', {
          title: 'Success',
          type: 'success',
          message: res.body.data.message
        });
        _this.data.subject = '';
        _this.data.message = '';
      } else {
        opts.stores.trigger('toaster_add', {
          title: 'Error',
          type: 'error',
          message: res.body.message
        });
        if (+res.status === 401) {
          document.querySelector('login')._tag.show();
        }
      }
      return _this.update();
    });
  };
})(this);
});
    
  