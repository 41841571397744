
    var riot = require('riot')
    
riot.tag2('drawing', '<canvas name="canvas" width="920" height="520"> </canvas> <protractor if="{protractorShow}" snaps="{snaps}"></protractor> <compass name="compass" if="{compassShow}" ondraw="{compassDraw}" ondrawend="{compassEnd}" scale="{scale}" snaps="{snaps}"></compass> <ruler if="{rulerShow}" cm="88" pencilpos="{pencilPos}" pencilstop="{pencilStop}" scale="{scale}" style=""></ruler> <paper name="paper" if="{paperShow}" papershow="{paperShow}" onmove="{tracingMove}" onrotate="{tracingRotate}" onfliph="{tracingFlipH}" onflipv="{tracingFlipV}"> </paper> <handle each="{point,i in points}" riot-style="left:{point.x1*scale}px;top:{point.y1*scale}px" point="{point}" update="{parent.pointUpdate}" snaps="{snaps}"></handle> <handle each="{point,i in lines}" riot-style="left:{point.x1*scale}px;top:{point.y1*scale}px;z-index:{i}" point="{point}" scale="{scale}" snaps="{snaps}" update="{parent.lineUpdate1}"></handle> <handle each="{point,i in lines}" riot-style="left:{point.x2*scale}px;top:{point.y2*scale}px;z-index:{i}" point="{point}" scale="{scale}" snaps="{snaps}" update="{parent.lineUpdate2}"></handle>', 'drawing{ display:block; position:absolute; }', '', function(opts) {
var ctx, mirrorPoint, mirrorPointV, offset, rotateMatix, shapedata, shapes, tracingpos;

shapedata = [];

this.points = [];

this.handles = [];

this.lines = [];

this.img_snaps = [];

this.pencilStart = false;

ctx = this.canvas.getContext("2d");

offset = this.canvas.getBoundingClientRect();

tracingpos = this.paper.getBoundingClientRect();

ctx.strokeStyle = "#FF0000";

this.on('mount', function() {
  opts.stores.on('answer_tools_updated', this.handleToolsUpdated);
  opts.stores.on('answer_drawing_updated', this.handleDrawingUpdated);
  opts.stores.on('answer_drawing_clear', this.clear);
  opts.stores.on('answer_drawing_undo', this.undoDrawingStep);
  opts.stores.trigger('answer_toolsync');
  this.quesImg = document.querySelector('.img-question');
  if (!this.quesImg) {
    return;
  }
  this.quesImg.addEventListener('load', this.positionAnswer);
  return window.addEventListener('resize', this.rescale, true);
});

this.on('unmount', function() {
  if (this.quesImg) {
    this.quesImg.removeEventListener('load', this.positionAnswer);
  }
  opts.stores.off('answer_tools_updated', this.handleToolsUpdated);
  opts.stores.off('answer_drawing_clear', this.clear);
  opts.stores.off('answer_drawing_undo', this.undoDrawingStep);
  opts.stores.off('answer_drawing_updated', this.handleDrawingUpdated);
  this.canvas.removeEventListener('touchmove', this.stopMove);
  return window.removeEventListener('resize', this.rescale);
});

this.on('update', function() {
  this.points = shapedata.filter((function(_this) {
    return function(s) {
      return s.type === "point";
    };
  })(this));
  this.lines = shapedata.filter((function(_this) {
    return function(s) {
      return s.type === "line";
    };
  })(this));
  return this.redraw();
});

this.handleDrawingUpdated = (function(_this) {
  return function(sdata) {
    shapedata = sdata;
    _this.updateSnaps();
    return _this.update();
  };
})(this);

this.positionAnswer = (function(_this) {
  return function() {
    var pos, quesImg;
    quesImg = document.querySelector('.img-question');
    if (quesImg) {
      pos = quesImg.getBoundingClientRect();
      _this.canvas.width = pos.width;
      _this.canvas.height = pos.height;
      _this.root.style.top = quesImg.offsetTop + "px";
      _this.root.style.left = quesImg.offsetLeft + "px";
      _this.root.style.width = pos.width + "px";
      _this.root.style.height = pos.height + "px";
      _this.canvas.setAttribute('height', pos.height);
      _this.scale = pos.width / 100;
      _this.updateSnaps();
      opts.stores.trigger("answer_setscale", _this.scale);
      _this.snapsFromImage(quesImg.getAttribute("src"));
      return _this.update();
    }
  };
})(this);

this.updateSnaps = (function(_this) {
  return function() {
    var answerscale, imgscale, ref;
    _this.snaps = [];
    answerscale = (570 / 100) / _this.scale;
    imgscale = (920 / 100) / _this.scale;
    if ((ref = opts.answer) != null) {
      ref.forEach(function(answer) {
        var text;
        text = typeof answer.text === "string" ? JSON.parse(answer.text) : answer.text;
        if (Array.isArray(text)) {
          return text.forEach(function(txt) {
            if (txt.type === "snap") {
              return _this.snaps.push({
                top: txt.y1 / answerscale,
                left: txt.x1 / answerscale
              });
            }
          });
        }
      });
    }
    return _this.snaps = _this.snaps.concat(_this.lines.map(function(l) {
      return {
        top: +l.y1 * _this.scale,
        left: +l.x1 * _this.scale,
        point: l
      };
    }), _this.lines.map(function(l) {
      return {
        top: +l.y2 * _this.scale,
        left: +l.x2 * _this.scale,
        point: l
      };
    }), _this.points.map(function(l) {
      return {
        top: +l.y1 * _this.scale,
        left: +l.x1 * _this.scale,
        point: l
      };
    }), _this.img_snaps.map(function(p) {
      return {
        top: p.y / imgscale,
        left: p.x / imgscale
      };
    }));
  };
})(this);

this.snapsFromImage = (function(_this) {
  return function(src) {
    var color, image, img_canvas, img_ctx;
    image = new Image();
    image.src = src;
    img_canvas = document.createElement('canvas');
    img_canvas.width = 920;
    img_canvas.height = 520;
    color = {
      r: 0,
      g: 249,
      b: 0
    };
    img_ctx = img_canvas.getContext('2d');
    _this.img_snaps = [];
    return image.onload = function() {
      var b, buffer, data, g, h, len, p, px, r, ref, snaps, w, x, y;
      img_ctx.drawImage(image, 0, 0);
      w = img_ctx.canvas.width;
      h = img_ctx.canvas.height;
      data = img_ctx.getImageData(0, 0, w, h);
      buffer = data.data;
      len = buffer.length;
      y = 0;
      snaps = [];
      while (y < h) {
        p = y * 4 * w;
        x = 0;
        while (x < w) {
          px = p + x * 4;
          ref = [buffer[px], buffer[px + 1], buffer[px + 2]], r = ref[0], g = ref[1], b = ref[2];
          if (r < 10 && g > 30 && b > 30 && g < 70 && b < 70) {
            _this.img_snaps.push({
              y: y,
              x: x
            });
          }
          x += 1;
        }
        y += 1;
      }
      return _this.updateSnaps();
    };
  };
})(this);

this.handleToolsUpdated = (function(_this) {
  return function(tools) {
    _this.tools = tools;
    if (_this.rulerShow && !_this.tools.rulerShow) {
      document.querySelector('ruler').setAttribute('style', '');
      document.querySelector('ruler')._tag.reset();
    }
    if (_this.protractorShow && !_this.tools.protractorShow) {
      document.querySelector('protractor').setAttribute('style', '');
      document.querySelector('protractor')._tag.reset();
    }
    if (_this.compassShow && !_this.tools.compassShow) {
      _this.compass.setAttribute('style', '');
      _this.compass._tag.reset();
    }
    if (_this.paperShow && !_this.tools.paperShow) {
      _this.paper.setAttribute('style', '');
      _this.paper._tag.reset();
    }
    _this.rulerShow = _this.tools.rulerShow;
    _this.compassShow = _this.tools.compassShow;
    _this.protractorShow = _this.tools.protractorShow;
    _this.paperShow = _this.tools.paperShow;
    return _this.update();
  };
})(this);

this.pencilPos = (function(_this) {
  return function(pos) {
    if (!_this.pencilStart) {
      _this.pencilStart = pos;
      ctx.beginPath();
      ctx.strokeStyle = "#FF0000";
      ctx.lineWidth = 1;
      ctx.moveTo(pos.left, pos.top);
    }
    ctx.lineTo(pos.left, pos.top);
    return ctx.stroke();
  };
})(this);

this.pencilStop = (function(_this) {
  return function(pos) {
    shapedata.push({
      type: 'line',
      x1: _this.pencilStart.left / _this.scale,
      y1: _this.pencilStart.top / _this.scale,
      x2: pos.left / _this.scale,
      y2: pos.top / _this.scale,
      tracing: _this.paperShow
    });
    _this.pencilStart = false;
    return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
  };
})(this);

this.compassDraw = (function(_this) {
  return function(pos) {
    ctx = _this.canvas.getContext("2d");
    offset = _this.canvas.getBoundingClientRect();
    ctx.strokeStyle = "#FF0000";
    ctx.beginPath();
    ctx.arc(pos.x, pos.y, pos.r, pos.rad1, pos.rad2, pos.anticlockwise);
    return ctx.stroke();
  };
})(this);

this.compassEnd = (function(_this) {
  return function(arc) {
    arc.x = arc.x / _this.scale;
    arc.r = arc.r / _this.scale;
    arc.y = arc.y / _this.scale;
    arc.tracing = _this.paperShow;
    shapedata.push(arc);
    return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
  };
})(this);

this.crossStop = (function(_this) {
  return function(pos) {
    shapedata.push({
      type: 'point',
      x1: pos.left / _this.scale,
      y1: pos.top / _this.scale,
      tracing: _this.paperShow
    });
    return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
  };
})(this);

this.clear = (function(_this) {
  return function() {
    shapedata = [];
    return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
  };
})(this);

this.undoDrawingStep = (function(_this) {
  return function(a) {
    shapedata.pop();
    return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
  };
})(this);

this.redraw = (function(_this) {
  return function() {
    if (!(shapedata != null ? shapedata.forEach : void 0)) {
      return;
    }
    ctx.clearRect(0, 0, _this.canvas.width, _this.canvas.height);
    return shapedata.forEach(function(shape) {
      if (shape.active) {
        ctx.strokeStyle = "#3fa9f5";
        ctx.lineWidth = 5;
      } else {
        ctx.strokeStyle = "#FF0000";
        ctx.lineWidth = 3;
      }
      ctx.setLineDash(shape.tracing ? [3, 2] : []);
      if (shapes[shape.type] != null) {
        return shapes[shape.type](shape);
      }
    });
  };
})(this);

shapes = {
  rect: (function(_this) {
    return function(options) {
      ctx.beginPath();
      ctx.rect(options.x * _this.scale, options.y * _this.scale, options.w * _this.scale, options.h * _this.scale);
      return ctx.stroke();
    };
  })(this),
  circle: (function(_this) {
    return function(options) {
      ctx.beginPath();
      ctx.arc(options.x, options.y, options.r, 0, Math.PI * 2);
      return ctx.stroke();
    };
  })(this),
  arc: (function(_this) {
    return function(opts) {
      ctx.beginPath();
      ctx.arc(opts.x * _this.scale, opts.y * _this.scale, opts.r * _this.scale, opts.rad1, opts.rad2);
      return ctx.stroke();
    };
  })(this),
  line: (function(_this) {
    return function(options) {
      ctx.beginPath();
      ctx.moveTo(options.x1 * _this.scale, options.y1 * _this.scale);
      ctx.lineTo(options.x2 * _this.scale, options.y2 * _this.scale);
      return ctx.stroke();
    };
  })(this),
  point: (function(_this) {
    return function(options) {
      ctx.beginPath();
      ctx.moveTo((options.x1 - 1) * _this.scale, (options.y1 - 1) * _this.scale);
      ctx.lineTo((options.x1 + 1) * _this.scale, (options.y1 + 1) * _this.scale);
      ctx.moveTo((options.x1 + 1) * _this.scale, (options.y1 - 1) * _this.scale);
      ctx.lineTo((options.x1 - 1) * _this.scale, (options.y1 + 1) * _this.scale);
      return ctx.stroke();
    };
  })(this)
};

this.rescale = (function(_this) {
  return function() {
    return _this.positionAnswer();
  };
})(this);

this.pointUpdate = (function(_this) {
  return function(arg) {
    var idx, point, x, y;
    point = arg.point, x = arg.x, y = arg.y;
    idx = shapedata.indexOf(point);
    if (idx > -1) {
      shapedata[idx].x1 = x / _this.scale;
      shapedata[idx].y1 = y / _this.scale;
      return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
    }
  };
})(this);

this.lineUpdate1 = (function(_this) {
  return function(arg) {
    var idx, point, x, y;
    point = arg.point, x = arg.x, y = arg.y;
    idx = shapedata.indexOf(point);
    if (idx > -1) {
      shapedata[idx].x1 = x / _this.scale;
      shapedata[idx].y1 = y / _this.scale;
      return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
    }
  };
})(this);

this.lineUpdate2 = (function(_this) {
  return function(arg) {
    var idx, point, x, y;
    point = arg.point, x = arg.x, y = arg.y;
    idx = shapedata.indexOf(point);
    if (idx > -1) {
      shapedata[idx].x2 = x / _this.scale;
      shapedata[idx].y2 = y / _this.scale;
      return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
    }
  };
})(this);

this.tracingRotate = (function(_this) {
  return function(radians, center) {
    if (!(shapedata != null ? shapedata.forEach : void 0)) {
      return;
    }
    shapedata = shapedata.map(function(shape) {
      var left, ref, ref1, ref2, top;
      if (!shape.tracing) {
        return shape;
      }
      if (shape.x != null) {
        ref = rotateMatix(shape.x, shape.y, (center.left + _this.paper.offsetLeft) / _this.scale, (center.top + _this.paper.offsetTop) / _this.scale, radians), left = ref.left, top = ref.top;
        shape.x = left;
        shape.y = top;
        shape.rad1 -= radians;
        shape.rad2 -= radians;
      }
      if (shape.x1 != null) {
        ref1 = rotateMatix(shape.x1, shape.y1, (center.left + _this.paper.offsetLeft) / _this.scale, (center.top + _this.paper.offsetTop) / _this.scale, radians), left = ref1.left, top = ref1.top;
        shape.x1 = left;
        shape.y1 = top;
      }
      if (shape.x2 != null) {
        ref2 = rotateMatix(shape.x2, shape.y2, (center.left + _this.paper.offsetLeft) / _this.scale, (center.top + _this.paper.offsetTop) / _this.scale, radians), left = ref2.left, top = ref2.top;
        shape.x2 = left;
        shape.y2 = top;
      }
      return shape;
    });
    return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
  };
})(this);

this.tracingMove = (function(_this) {
  return function(delta) {
    if (!(shapedata != null ? shapedata.forEach : void 0)) {
      return;
    }
    shapedata = shapedata.map(function(shape) {
      if (!shape.tracing) {
        return shape;
      }
      if (shape.x != null) {
        shape.x += delta.left / _this.scale;
        shape.y += delta.top / _this.scale;
      }
      if (shape.x1 != null) {
        shape.x1 += delta.left / _this.scale;
        shape.y1 += delta.top / _this.scale;
      }
      if (shape.x2 != null) {
        shape.x2 += delta.left / _this.scale;
        shape.y2 += delta.top / _this.scale;
      }
      return shape;
    });
    return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
  };
})(this);

this.tracingFlipH = (function(_this) {
  return function(radians, center) {
    if (!(shapedata != null ? shapedata.forEach : void 0)) {
      return;
    }
    shapedata = shapedata.map(function(shape) {
      var left, rad1, rad2, ref, ref1, ref2, top;
      if (!shape.tracing) {
        return shape;
      }
      if (shape.x != null) {
        ref = mirrorPoint(shape.x, shape.y, (center.left + _this.paper.offsetLeft) / _this.scale, (center.top + _this.paper.offsetTop) / _this.scale, radians), left = ref.left, top = ref.top;
        shape.x = left;
        shape.y = top;
        rad1 = shape.rad1, rad2 = shape.rad2;
        shape.rad1 = Math.PI - rad2 + (radians * 2);
        shape.rad2 = Math.PI - rad1 + (radians * 2);
      }
      if (shape.x1 != null) {
        ref1 = mirrorPoint(shape.x1, shape.y1, (center.left + _this.paper.offsetLeft) / _this.scale, (center.top + _this.paper.offsetTop) / _this.scale, radians), left = ref1.left, top = ref1.top;
        shape.x1 = left;
        shape.y1 = top;
      }
      if (shape.x2 != null) {
        ref2 = mirrorPoint(shape.x2, shape.y2, (center.left + _this.paper.offsetLeft) / _this.scale, (center.top + _this.paper.offsetTop) / _this.scale, radians), left = ref2.left, top = ref2.top;
        shape.x2 = left;
        shape.y2 = top;
      }
      return shape;
    });
    return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
  };
})(this);

this.tracingFlipV = (function(_this) {
  return function(radians, center) {
    if (!(shapedata != null ? shapedata.forEach : void 0)) {
      return;
    }
    shapedata = shapedata.map(function(shape) {
      var left, rad1, rad2, ref, ref1, ref2, top;
      if (!shape.tracing) {
        return shape;
      }
      if (shape.x != null) {
        ref = mirrorPointV(shape.x, shape.y, (center.left + _this.paper.offsetLeft) / _this.scale, (center.top + _this.paper.offsetTop) / _this.scale, radians), left = ref.left, top = ref.top;
        shape.x = left;
        shape.y = top;
        rad1 = shape.rad1, rad2 = shape.rad2;
        shape.rad1 = 0 - rad2 + (radians * 2);
        shape.rad2 = 0 - rad1 + (radians * 2);
      }
      if (shape.x1 != null) {
        ref1 = mirrorPointV(shape.x1, shape.y1, (center.left + _this.paper.offsetLeft) / _this.scale, (center.top + _this.paper.offsetTop) / _this.scale, radians), left = ref1.left, top = ref1.top;
        shape.x1 = left;
        shape.y1 = top;
      }
      if (shape.x2 != null) {
        ref2 = mirrorPointV(shape.x2, shape.y2, (center.left + _this.paper.offsetLeft) / _this.scale, (center.top + _this.paper.offsetTop) / _this.scale, radians), left = ref2.left, top = ref2.top;
        shape.x2 = left;
        shape.y2 = top;
      }
      return shape;
    });
    return opts.stores.trigger('answer_trigger_drawing_updated', shapedata);
  };
})(this);

mirrorPoint = function(left, top, centerX, centerY, radians) {
  var ref, ref1;
  ref = rotateMatix(left, top, centerX, centerY, radians), left = ref.left, top = ref.top;
  left = centerX - left + centerX;
  ref1 = rotateMatix(left, top, centerX, centerY, 0 - radians), left = ref1.left, top = ref1.top;
  return {
    left: left,
    top: top
  };
};

mirrorPointV = function(left, top, centerX, centerY, radians) {
  var ref, ref1;
  ref = rotateMatix(left, top, centerX, centerY, radians), left = ref.left, top = ref.top;
  top = centerY - top + centerY;
  ref1 = rotateMatix(left, top, centerX, centerY, 0 - radians), left = ref1.left, top = ref1.top;
  return {
    left: left,
    top: top
  };
};

rotateMatix = function(x, y, centerX, centerY, radians) {
  var cos, sin, xr, yr;
  cos = Math.cos(radians);
  sin = Math.sin(radians);
  xr = (cos * (x - centerX)) + (sin * (y - centerY)) + centerX;
  yr = (cos * (y - centerY)) - (sin * (x - centerX)) + centerY;
  return {
    left: xr,
    top: yr
  };
};
});
    
  