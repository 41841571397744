
    var riot = require('riot')
    
riot.tag2('tip', '<yield></yield> <div id="tooltip" if="{show}" name="pop" riot-style="display:block;opacity:{opacity};top:{top};left:{left}" class="tooltip fade {placement} {in:show} {tipclass}"> <div class="tooltip-arrow"></div> <div class="tooltip-inner"> <raw content="{opts.tip}"></raw> </div> </div>', 'tip { display: inline-block; } .tooltip .tooltip-inner { -webkit-border-radius: 5px; border-radius: 5px; -webkit-box-shadow: 0 2px 10px rgba(0,0,0,0.5); box-shadow: 0 2px 10px rgba(0,0,0,0.5); display: block; }', '', function(opts) {
var placementlib;

placementlib = require('riot-kit/utils/placement.coffee');

this.show = false;

this.isActive = false;

this.timeOut = null;

this.opacity = 0;

this.placement = this.opts.placement || "top";

this.tipclass = this.opts.tipclass || "";

this.parentNode = this.root.parentNode;

this.hideTip = (function(_this) {
  return function() {
    document.removeEventListener('click', _this.hide, false);
    _this.parentNode.appendChild(_this.root);
    _this.show = false;
    if (opts.hiding) {
      opts.hiding();
    }
    return _this.update();
  };
})(this);

this.popclicked = function(e) {
  e.preventDefault();
  return e.stopPropagation();
};

this.activate = (function(_this) {
  return function() {
    _this.isActive = true;
    return _this.root.addEventListener('mouseover', _this.showTip);
  };
})(this);

this.deactiveate = (function(_this) {
  return function() {
    _this.isActive = false;
    _this.root.removeEventListener('mouseover', _this.showTip);
    if (_this.show) {
      _this.hideTip();
    }
    if (_this.timeOut) {
      return clearTimeout(_this.timeOut);
    }
  };
})(this);

this.on('mount', (function(_this) {
  return function() {
    if (opts.tip && !opts.disabled && !_this.isActive) {
      return _this.activate();
    }
  };
})(this));

this.on('update', (function(_this) {
  return function() {
    if (opts.tip && !opts.disabled && !_this.isActive) {
      _this.activate();
    }
    if (opts.tip && opts.disabled && _this.isActive) {
      return _this.deactiveate();
    }
  };
})(this));

this.on('unmount', (function(_this) {
  return function() {
    return _this.deactiveate();
  };
})(this));

this.hide = (function(_this) {
  return function() {
    _this.root.removeEventListener('mouseleave', _this.hideTip);
    if (_this.timeOut) {
      clearTimeout(_this.timeOut);
    }
    _this.timeOut = setTimeout(function() {
      return _this.root.appendChild(_this.tooltip);
    });
    _this.show = false;
    if (opts.hiding) {
      opts.hiding();
    }
    _this.opacity = 0;
    return _this.update();
  };
})(this);

this.showTip = (function(_this) {
  return function(e) {
    _this.root.addEventListener('mouseleave', _this.hideTip);
    _this.show = true;
    _this.update();
    _this.parentNode = _this.root.parentNode;
    document.body.appendChild(_this.tooltip);
    return _this.timeOut = setTimeout(function() {
      var pos;
      pos = placementlib({
        placement: _this.placement,
        target: e.target,
        element: _this.tooltip
      });
      _this.placement = pos.placement;
      _this.top = Math.round(pos.top) + "px";
      _this.left = Math.round(pos.left) + "px";
      _this.opacity = 0.9;
      return _this.update();
    });
  };
})(this);
});
    
  