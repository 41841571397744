
    var riot = require('riot')
    
riot.tag2('challengeinfo', '<modal title="{challenge.name}" onclose="parent.closeModal" class="challengemodal"> <div class="row"> <div class="col-xs-6"> <div riot-style="background:{parent.challenge.colour};--icon:url(\'data:image/svg+xml;utf8,{parent.challenge.badge}\')" class="large challenge"> {parent.challenge.name} </div> </div> <div class="col-xs-6"> <h4>{parent.challenge.name}</h4> <p class="pre">{parent.challenge.description}</p> <p>Marks <strong>{parent.challenge.usermarks || \'0\'} / {parent.challenge.marks}</strong></p> <p>Qualification <strong>{parent.challenge.qualification}</strong></p> <p>Clips <strong if="{parent.challenge.clips}"><a each="{parent.convertClips(parent.challenge.clips)}" href="/vle/browse/{id}" style="margin-right:2px;display:inline-block;" class="label {parent.parent.clipStyle(id)}">{clip_id}</a></strong><strong if="{!parent.challenge.clips}">All</strong></p> <p>Dates - <strong> <dateformat date="{parent.challenge.start}" format="dd MMM yyyy"> </dateformat> - <dateformat date="{parent.challenge.end}" format="dd MMM yyyy"></dateformat></strong></p><br> <confirm onconfirm="{parent.quitChallenge}" confirmtext=" Quit " class="btn btn-danger">Quit This Challenge</confirm> </div> </div> </modal>', '.large.challenge:before{ font-size:4em; }', '', function(opts) {
var Resource, RiotControl, request, resourceUtils;

request = require('superagent');

RiotControl = require('riotcontrol');

resourceUtils = require('../libs/resourceUtils.coffee');

Resource = require('../libs/resource.coffee')(request, RiotControl, resourceUtils);

this.on('mount', function() {
  opts.stores.on('video_changed', this.handleVideoChanged);
  return opts.stores.trigger('video_sync');
});

this.on('unmount', function() {
  return opts.stores.off('video_changed', this.handleVideoChanged);
});

this.handleVideoChanged = (function(_this) {
  return function(videos) {
    _this.videos = videos;
    return _this.update();
  };
})(this);

this.load = (function(_this) {
  return function() {};
})(this);

this.show = (function(_this) {
  return function(challenge) {
    _this.challenge = challenge;
    _this.tags.modal.show();
    _this.update();
    return _this.load();
  };
})(this);

this.closeModal = (function(_this) {
  return function() {
    return _this.tags.modal.hide();
  };
})(this);

this.quitChallenge = (function(_this) {
  return function() {
    return new Resource("/duocms/api/challenges/quit").save({
      challenge_id: _this.challenge.id
    }, function(data) {
      _this.data = data;
      if (opts.updated) {
        opts.updated();
      }
      return _this.closeModal();
    });
  };
})(this);

this.convertClips = (function(_this) {
  return function(video_ids) {
    var ids;
    ids = video_ids.split(",").map(function(n) {
      return +n;
    });
    return _this.videos.filter(function(vid) {
      return ids.includes(+vid.id);
    });
  };
})(this);

this.clipStyle = (function(_this) {
  return function(id) {
    var got, needed, ref, style;
    needed = _this.challenge.clipmarks;
    got = (ref = _this.challenge.marksperclip) != null ? ref.find(function(c) {
      return c.clip_id === id;
    }) : void 0;
    style = (got != null ? got.marks : void 0) >= needed ? 'label-success' : 'label-primary';
    return style;
  };
})(this);
});
    
  