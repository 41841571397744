module.exports = 

  class AssignedWorkStore

    constructor:(riot,@Resource,@utils,@stores,@request,@resourceUtils)->
      riot.observable(@)
      @resource = new @Resource("/duocms/api/assignedwork")
      @rows = []
      @getting = {}
      @data_complete = {}
      @rows = null
      @exams = []
      @exams_by_id = {}
      @on 'assignedwork_load',@load
      @on 'assignedwork_load_student',@loadstudent
      @on 'assignedwork_exams',@loadexams
      @on 'assignedwork_get_exam',@loadexam
      @on 'assignedwork_get_exam_questions',@loadexamquestions
      @on 'assignedwork_sync',@sync
      @on 'assignedwork_save',@save
      @on 'assignedwork_get',@get
      @on 'assignedwork_get_user',@get_user
      @on 'assignedwork_set',@set
      @on 'assignedwork_unlock',@unlock
      @on 'assignedwork_remove',@remove
      @on 'assignedwork_sync_one',@sync_one
      @on 'assignedwork_sync_complete',@sync_complete
      @on 'assignedwork_toggle_question',@toggleQuestion
      @on 'assignedwork_add_questions',@addQuestions
      @on 'assignedwork_remove_clip',@removeClip
      @on 'assignedwork_duplicate',@duplicate
      @on 'answer_count_changed',@updateAnswerCounts
      @on 'hide_exam_question',@hideExamQuestion
      @on 'unhide_exam_question',@unHideExamQuestion
      @on 'error',(e)-> console.error e

    load:(recent,school_id)->
      @data_state = 'loading'
      @resource.get {recent:recent,school_id},(@rows)=>
        #normalize data a little to help with presentation
        @rows.forEach (assignment,idx)=>
          @rows[idx].classname = assignment.classroom?.name
          @rows[idx].year = assignment.classroom?.year
          @rows[idx].creator = assignment.owner?.first_name+" "+assignment.owner?.surname
          @rows[idx].questionlist = @_getQuestionIds(assignment.playlist)
        #@stores.trigger 'answer_count_load' # cauese error for teachers and admin ? #332
        @data_state = 'loaded'
        @sync()

    loadstudent:(recent)->
      @data_state = 'loading'
      resource = new @Resource("/duocms/api/assignedwork/student")
      resource.get {recent:recent},(@rows)=>        
        @data_state = 'loaded'
        @sync()

    loadexams:->
      return @trigger('assignedwork_exams_changed',@exams) if @exams.length
      return if @exam_loading
      @exam_loading = true # prevent double load
      @request.get "/duocms/api/exams",(err,res)=>
        @exam_loading = false
        if res.body.data
          @exams = @resourceUtils.responseConvert(res.body.data)
          @exams.map (exam)->
            exam.type = "exam"
            exam
          @trigger('assignedwork_exams_changed',@exams)

    loadexam:(examid)->
      return if @exams_by_id[examid] == "loading"
      return @trigger('assignedwork_exam_changed',@exams_by_id[examid]) if @exams_by_id[examid] 
      @exams_by_id[examid] = "loading"
      @request.get "/duocms/api/exams/#{examid}",(err,res)=>
        if res.body.data
          @exams_by_id[examid] = @resourceUtils.responseConvert(res.body.data)
          @exams_by_id[examid].type = "exam"
          @trigger('assignedwork_exam_changed',@exams_by_id[examid])

    loadexamquestions:(examid)->
      return if @exams_by_id[examid] == "loading"
      return @trigger('assignedwork_exam_changed',@exams_by_id[examid]) if @exams_by_id[examid] 
      @exams_by_id[examid] = "loading"
      @request.get "/duocms/api/exams/#{examid}/questions",(err,res)=>
        if res.body.data
          @exams_by_id[examid] = @resourceUtils.responseConvert(res.body.data)
          @exams_by_id[examid].type = "exam"
          @trigger('assignedwork_exam_changed',@exams_by_id[examid])

    getCompleted:(id)->
      @resource.get {id:id,users:true},(data)=>
        @data_complete[id] = data.classroom.users.map (user)->
          userMarks = 0
          user.answers.forEach (answer)-> answer.answer.forEach (subanswer)-> userMarks+= +subanswer.marks

          id:user.id
          classname:data.classroom.name
          year:data.classroom.year
          first_name:user.first_name
          surname:user.surname
          answers:user.answers
          marks: userMarks
          correct: (user.answers.reduce ((a,b)->a + if b.correct then 1 else 0),0)+"/"+user.answers.length
        @trigger 'assignedwork_changed_complete',@data_complete[id]


    sync_complete:(id)->
      return @getCompleted(id) if !@data_complete[id]
      setTimeout => @trigger 'assignedwork_changed_complete',@data_complete[id]

    sync:->
      return @load() if !@data_state
      @trigger 'assignedwork_changed',@rows

    sync_one:(row)->
      @row = row if row
      @trigger 'assignedwork_changed_one',@row
   
    get:(id,user_id)->
      # user_id is used for feedback bubbles, so isn't a required param
      return if !id || @getting[id]==true || id=='new'
      @getting[id]=true
      @resource.get {id:id,user_id:user_id},(row)=> 
        @row = row
        @row.questions.forEach (q)=> q.answer.forEach (a)=> 
          a.prefix = @escapeBraces(a.prefix) if a?.prefix
          a.text = @escapeBraces(a.text) if a?.text
          a.suffix = @escapeBraces(a.suffix) if a?.suffix

        @row.playlist = @makePlaylist()
        @row.questionlist = @_getQuestionIds(row.playlist)
        if @row.questions && @row.questions.length 
          @row.totalMarks = @row.questions.map((ques)-> ques.marks).reduce ((a,b)-> +a + +b),0
        else
          @row.totalMarks = 0
        setTimeout => #throttle reload for 1 second
          delete @getting[id]
        ,1000
        @sync_one()
    
    # gets students work, not related to this class
    get_user:(user_id)->
      @resource.get {user_id:user_id},(rows)=>
        @trigger 'assignedwork_user_changed',rows

    duplicate:(selected)->
      @resource.save {id:selected.id,duplicate:true},(@row)=>
        @trigger 'assignedwork_saved',@row
        @load()

    set:(row)->
      @row = row
      @sync_one()

    save:->
      school_id = @row.org_id
      @resource.save @row, (@row)=>
        @trigger 'assignedwork_saved',@row #closes modal
        @load(null,school_id)

    remove:(row)->
      @resource.remove row, =>
        @trigger 'assignedwork_removed',@row #closes modal
        @utils.removeFromId(@rows,row.id)
        @sync()

    toggleQuestion:(ques) =>
      @row.questions = [] if !@row.questions?.length
      total = @row.questions.length
      questions = @row.questions.filter (q)-> q.id!=ques.id
      if questions.length < total # remove
        @row.questions = questions
        vids = @row.playlist[ques.video_id]
        vids.splice(vids.indexOf(ques.id),1) if vids && ques.id
        @row.playlist[ques.video_id]=vids
      else # add
        @row.questions.push(ques)
        @row.playlist = {} if !@row.playlist
        @row.playlist[ques.video_id] = [] if !@row.playlist[ques.video_id]
        @row.playlist[ques.video_id].push(ques.id)
      @sync_one()

    addQuestions:(questions,video_id)=>
      count = 0
      @row.questions = [] if !@row.questions?.length
      @row.playlist = {} if !@row.playlist # add empty playlist if one doesn't exist
      if !@row.playlist[video_id]? # if no clip, add them all
        @row.playlist[video_id] = questions.map (q)-> q.id
        @row.questions = @row.questions.concat(questions)
      else # only add those not already added
        questions.forEach (question)=>
          if @row.playlist[video_id].indexOf(question.id) == -1
            @row.playlist[video_id].push(question.id)
            @row.questions.push(question)
      @sync_one()
      
    makePlaylist:=>
      playlist = {}
      @row.questions.forEach (q)->
        playlist[q.video_id] = [] if !playlist[q.video_id]
        playlist[q.video_id].push(q.id)
      return playlist

    removeClip:(cliplist,selected)=>
      cliplist.splice(cliplist.indexOf(selected),1)
      @row.questions = @row.questions.filter (q)=> @row.playlist[selected.id].indexOf(+q.id) == -1
      delete @row.playlist[selected.id]
      @sync_one()

    _getQuestionIds:(playlist)=>
      qlist = []
      qlist = qlist.concat(val) for key,val of playlist || {}
      return qlist

    updateAnswerCounts:(answerCounts)=>
      answerCounts.forEach (row)=>
        idx = @utils.indexFromId(@rows,row.assignedwork_id)
        if idx > -1
          @rows[idx].answercount = row.answered 
          @rows[idx].allanswered = if row.answered >= @rows[idx].questionlist.length then true else false
      #@stores.trigger 'assignedwork_changed',@rows
    
    escapeBraces:(str)->
      return str.replace(/(^|[^\\])((\\\\)*\{)/g,'$1\\$2') if str && typeof str == "string"
      return str

    unlock:(id)->
      @request.post("/duocms/api/assignedwork/#{id}/unlock").send("").csrf().end (err,res)=>
        if err
          @stores.trigger 'toaster_add',{type:'error',title:'Sorry',message:res.body.message}
        else
          window.location.reload()

    hideExamQuestion:(assignment_id,question_id)->
      @request.post("/duocms/api/assignedwork/#{assignment_id}/question/?question_ids=#{question_id}").send("").csrf().end (err,res)=>
        if err
          @stores.trigger 'toaster_add',{type:'error',title:'Sorry',message:res.body.message}
        else
          @trigger 'assignedwork_get',assignment_id # force reload

    unHideExamQuestion:(assignment_id,question_id)->
      @request.del("/duocms/api/assignedwork/#{assignment_id}/question/?question_ids=#{question_id}").csrf().end (err,res)=>
        if err
          @stores.trigger 'toaster_add',{type:'error',title:'Sorry',message:res.body.message}
        else
          @trigger 'assignedwork_get',assignment_id

  