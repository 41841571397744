module.exports = 
  
  class Transform

    constructor:(@handle,@elem,@options={})->
      @handle.addEventListener 'mousedown',@dragStart
      @handle.addEventListener 'touchstart',@dragStart
      @options.vertical ?= true
      @options.horizontal ?= true
      @options.dragging ?= (pos)->
      @options.dragStart ?= ->
      @options.dragEnd ?= ->

    dragStart:(e)=>
      e.preventDefault()
      e.stopPropagation()
      document.addEventListener 'mouseup',@dragEnd
      document.addEventListener 'touchend',@dragEnd 
      document.addEventListener 'mousemove',@dragMove,false
      document.addEventListener 'touchmove',@dragMove,false
      isTouch = false
      if e.touches
        isTouch = true
        touch = e.touches[0]
        ev =  left:touch.pageX,top:touch.pageY
      else
        ev =  left:e.pageX,top:e.pageY
      top = @elem.offsetTop
      left = @elem.offsetLeft
      @start = {left:(ev.left - left),top:(ev.top - top),offsetLeft:left,offsetTop:top}
      origin = null
      style = window.getComputedStyle(@elem, null);
      ['transform-origin','-moz-transform-origin','-webkit-transform-origin','-ms-transform-origin'].forEach (version)=>
        origin = style.getPropertyValue(version) || @origin
      if origin
        @tsOrigin = {left:+origin.match(/^([0-9]+)/)[1],top:+origin.match(/([0-9])[^0-9]+$/)[1]}
      @options.dragStart(@start,{isTouch})
        


    dragEnd:(e)=>
      document.removeEventListener 'mouseup',@dragEnd
      document.removeEventListener 'mousemove',@dragMove
      document.removeEventListener 'touchend',@dragEnd
      document.removeEventListener 'touchmove',@dragMove
      @options.dragEnd()
      
    dragMove:(e)->
      #should be implements by subclass

    getAngle:(elem)=>
      st = window.getComputedStyle(elem, null);
      tr = st.getPropertyValue("-webkit-transform") ||
           st.getPropertyValue("-moz-transform") ||
           st.getPropertyValue("-ms-transform") ||
           st.getPropertyValue("-o-transform") ||
           st.getPropertyValue("transform")
      try
        values = tr.split('(')[1].split(')')[0].split(',');
      catch e
        return {radians:0,degrees:0}
      [a,b,c,d] = values
      scale = Math.sqrt(a*a + b*b)
      sin = b/scale
      radians = Math.atan2(b, a)
      degrees = radians * (180/Math.PI)
      {radians,degrees}

    rotateMatix:(x, y, centerX, centerY, radians)->
      cos = Math.cos
      sin = Math.sin

      xr = (x - centerX) * cos(radians) - (y - centerY) * sin(radians) + centerX
      yr = (x - centerX) * sin(radians) + (y - centerY) * cos(radians) + centerY

      return {left:xr,top:yr}

