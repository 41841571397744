
    var riot = require('riot')
    
riot.tag2('accountdetails', '<div class="form-horizontal"> <formstatic id="username" onupdate="{handleChange}" value="{user.username}" label="Username"></formstatic> <div show="{groups.includes(\'Teachers\') || groups.includes(\'School Admins\')}"> <forminput id="first_name" onupdate="{handleChange}" value="{user.first_name}" label="First Name"></forminput> <forminput id="surname" onupdate="{handleChange}" value="{user.surname}" label="Surname"></forminput> </div> <div show="{!groups.includes(\'Teachers\') &amp;&amp; !groups.includes(\'School Admins\')}"> <formstatic id="first_name" onupdate="{handleChange}" value="{user.first_name}" label="First Name"></formstatic> <formstatic id="surname" onupdate="{handleChange}" value="{user.surname}" label="Surname"></formstatic> <formstatic id="email" value="{classrooms.join(&quot;, &quot;)}" label="{classrooms.length&gt;1?&quot;Classes&quot;:&quot;Class&quot;}"> </formstatic> </div> <forminput id="email" if="{!user.org.lock_emails || groups.includes(\'Teachers\') || groups.includes(\'School Admins\')}" onupdate="{handleChange}" value="{user.email}" label="Email"></forminput> <formstatic id="email" if="{user.org.lock_emails &amp;&amp; !groups.includes(\'Teachers\') &amp;&amp; !groups.includes(\'School Admins\')}" onupdate="{handleChange}" value="{user.email}" label="Email"></formstatic> <forminput id="password" onupdate="{handleChange}" type="password" label="Password" placeholder="Leave blank if you don\'t wish to update this"></forminput> <forminput id="password2" onupdate="{handleChange}" type="password" label="Password (again)" placeholder="Leave blank if you don\'t wish to update this"></forminput> <formcheckbox id="anonymous" onupdate="{handleChange}" label="Hide my name on leaderboards" value="{user.anonymous}"></formcheckbox> <button onclick="{save}" class="btn pull-right {hasChanged ? \'btn-success\':\'btn-default\'}">Save Changes</button> </div>', '', '', function(opts) {
this.loadedUser = {};

this.user = {};

this.on('mount', function() {
  this.user = {};
  this.hasChanged = false;
  this.groups = [];
  opts.stores.on('session_changed', this.sessionUpdated);
  opts.stores.on('user_saved', this.userUpdated);
  return opts.stores.trigger('session_sync');
});

this.on('unmount', function() {
  opts.stores.off('session_changed', this.sessionUpdated);
  return opts.stores.off('user_saved', this.userUpdated);
});

this.on('error', function(err) {
  return console.error(err);
});

this.on('update', function() {
  return this.hasChanged = this.user.email !== this.loadedUser.email || (this.user.password && this.user.password === this.user.password2 && this.user.password.length > 6) || this.user.anonymous !== this.loadedUser.anonymous;
});

this.userUpdated = (function(_this) {
  return function(data) {
    _this.root.querySelector("input#password").value = "";
    _this.root.querySelector("input#password2").value = "";
    delete _this.user.password;
    delete _this.user.password2;
    opts.stores.trigger('toaster_add', {
      type: 'success',
      title: 'Success',
      message: "Your details have been updated successfully."
    });
    return opts.stores.trigger('session_sync');
  };
})(this);

this.sessionUpdated = (function(_this) {
  return function(data) {
    var ref, ref1;
    _this.groups = ((ref = data.user) != null ? ref.groups : void 0) ? data.user.groups.map(function(group) {
      return group.name;
    }) : null;
    _this.classrooms = ((ref1 = data.user) != null ? ref1.classrooms : void 0) ? data.user.classrooms.map(function(classroom) {
      return classroom.name;
    }) : [];
    _this.loadedUser = Object.assign({}, data.user);
    _this.user = data.user;
    return _this.update();
  };
})(this);

this.handleChange = (function(_this) {
  return function(e) {
    _this.user[e.name] = e.value;
    return _this.update();
  };
})(this);

this.save = (function(_this) {
  return function() {
    if (_this.user.password !== _this.user.password2) {
      return opts.stores.trigger('toaster_add', {
        type: 'warning',
        title: 'Error',
        message: "You need to enter the same new password in both boxes."
      });
    } else {
      delete _this.user.password2;
      return opts.stores.trigger('user_save', {
        id: "me",
        email: _this.user.email,
        password: _this.user.password,
        anonymous: _this.user.anonymous
      });
    }
  };
})(this);
});
    
  