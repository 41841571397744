natsort = require "../libs/natsort"
module.exports = 

  class ClassStore

    constructor:(riot,@Resource,@utils)->
      riot.observable(@)
      @section = null
      @resource = new @Resource("/duocms/api/classrooms")
      @resource_all = new @Resource("/duocms/api/classrooms?all=true")
      @class_stats = new @Resource("/duocms/api/logs?filter=classMonth")
      @classrooms = []
      @classrooms_all = []
      @classusers = {}
      @classassignments = {}
      @classmarks = {}
      @classLoaded = {}
      @on 'class_load',@load
      @on 'class_sync',@sync
      @on 'class_sync_all',@sync_all
      @on 'class_sync_stats',@sync_stats
      @on 'class_save',@save
      @on 'class_get',@get
      @on 'class_remove',@remove
      @on 'class_remove_empty',@remove_empty
      @on 'class_load_users',@load_users
      @on 'class_load_assignments',@load_assignments
      @on 'class_load_marks',@load_marks
      @on 'class_get_withstudents',@getWithStudents

    load:(cback,params)->
      @data_state = 'loading'
      @resource.get params,(classrooms)=>
        @classrooms = classrooms.sort (a,b)-> natsort(a.name,b.name)
        @data_state = 'loaded'
        @sync()
        cback(@classrooms) if cback

    load_all:(cback)->
      @data_state_all = 'loading'
      @resource_all.get (classrooms)=>
        @classrooms_all = classrooms.sort (a,b)-> natsort(a.name,b.name)
        @data_state_all = 'loaded'
        @sync_all()
        cback(@classrooms_all) if cback

    load_stats:(cback)->
      @data_state_stats = 'loading'
      @class_stats.get (stats)=>
        @classrooms_stats = {}
        stats.forEach (s)=>
          @classrooms_stats[s.classroom_id] = s
        @data_state_stats = 'loaded'
        @sync_stats()
        cback(@classrooms_stats ) if cback

    sync:({section}={})->
      console.log(section,@section)
      if !@data_state || (section && @section != section && section != "classes")
        @section = section if section
        return @load(null,{section})        
      setTimeout => # force async for ie
        @trigger 'classes_changed',@classrooms

    sync_all:->
      return @load_all() if !@data_state_all
      setTimeout => # force async for ie
        @trigger 'classes_changed_all',@classrooms_all

    sync_stats:->
      return @load_stats() if !@data_state_stats
      setTimeout => # force async for ie
        @trigger 'classes_changed_stats',@classrooms_stats

    load_users:(class_id)->
      if @classusers[class_id] && @classusers[class_id]!="loading"
        @trigger 'class_users_changed'+class_id,@classusers[class_id]
        return 
      else 
        @classusers[class_id]="loading"
        usersapi = new @Resource("/duocms/api/classrooms/#{class_id}/users?stats=true")
        usersapi.get (users)=>
          @classusers[class_id] = users
          @trigger 'class_users_changed'+class_id,@classusers[class_id]

    load_assignments:(class_id)->
      if @classassignments[class_id] && @classassignments[class_id]!="loading"
        @trigger 'class_assignments_changed'+class_id,@classassignments[class_id]
        return 
      else if !@classassignments[class_id]
        @classassignments[class_id]="loading"
        usersapi = new @Resource("/duocms/api/classrooms/#{class_id}/assignments")
        usersapi.get (users)=>
          @classassignments[class_id] = users
          @trigger 'class_assignments_changed'+class_id,@classassignments[class_id]
    
    load_marks:(class_id,user_ids,assignment_ids)->
      notLoaded = assignment_ids.filter (id)=> !(@classmarks[class_id+"_"+id] || @classmarks[class_id+"_"+id]=="loading")
      if notLoaded.length == 0
        @trigger 'class_marks_changed'+class_id,@classmarks
        return 
      else 
        notLoaded.forEach (id)=> @classmarks[class_id+"_"+id]="loading"
        usersapi = new @Resource("/duocms/api/classrooms/#{class_id}/marks")
        usersapi.get {user_ids:user_ids.join(","),assignment_ids:assignment_ids.join(",")},(marks)=>
          marks.forEach (mark)=>
            @classmarks[class_id+"_"+mark.assignedwork_id] = {} if !@classmarks[class_id+"_"+mark.assignedwork_id] || @classmarks[class_id+"_"+mark.assignedwork_id]=="loading"
            @classmarks[class_id+"_"+mark.assignedwork_id][mark.user_id] = {mark:mark.mark,percent:mark.percent,feedback_added:mark.feedback_added,feedback_unread:mark.feedback_unread}
          @trigger 'class_marks_changed'+class_id,@classmarks

    sync_one:(id)->
      @trigger 'class_changed'+id,@classLoaded[id]
      @trigger 'class_changed',@classLoaded[id]
      
    get:(id)->
      return if !id || @classLoaded[id]=="loading"
      return @sync_one(id) if @classLoaded[id]
      @classLoaded[id] = "loading"
      # @resource.get {id:id},(classroom)=>
      new @Resource("/duocms/api/classrooms/"+id+"/details").get (classroom)=> # #407 speed up?
        @classLoaded[id] = classroom
        @sync_one(id)

    getWithStudents:(id)->
      return if !id || @classLoaded[id]=="loading"
      return @sync_one(id) if @classLoaded[id]
      @classLoaded[id] = "loading"
      # @resource.get {id:id},(classroom)=>
      new @Resource("/duocms/api/classrooms/0,"+id).get (classroom)=> 
        @classLoaded[id] = classroom
        @sync_one(id)

    save:(classroom,cback)->
      @resource.save classroom, (@classroom)=>
        @trigger 'class_saved',@classroom # closes modal
        @classLoaded[@classroom.id] = @classroom
        @data_state = null # force relaod on sync
        @utils.updateFromId(@classrooms,@classroom)
        @sync_one(@classroom.id)
        @sync() # reload list after save, help recalc class size and teachers
        cback(@classroom) if cback

    remove:(classroom)->
      @resource.remove classroom, =>
        @trigger 'class_removed',@classroom #closes modal
        @utils.removeFromId(@classrooms,classroom.id)
        @sync()

    remove_empty:(ids)->
      # get all classes again, to get their new user count, check through classes with matching ids and remove if empty
      @load (classrooms)=>
        classrooms.forEach (classroom)=>
          if ids.indexOf(classroom.id) > -1 && classroom.student_count == 0
            @remove(classroom)