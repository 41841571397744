<script>export let isexam, assignedids;
import { onMount } from "svelte";
import api from "duo-kit/src/shared/api.js";
import Formrow from "duo-kit/src/shared/formrow.svelte";
import Formdatetime from "../shared/formdatetime.svelte";
import FormMulti from "../shared/form-multi.svelte";
import ToggleLink from "../shared/toggle-link.svelte";
import IconSpinner from "duo-kit/src/icons/spinner.svelte";
import { tick } from "svelte";
let assignments = {};
let submitcount = 0;
let studentsPerAssignment = {};
let classrooms_height = [];
let students_height = [];
let classes = [];
let studentsPerClass = {};
let forms;
let saving = false;
let links = {
  title: false,
  notes: false,
  type: false,
  intervention: false,
  students: false,
  classrooms: false,
  assigned_at: false,
  due_at: false,
  lock_at: false,
  timelimit: false,
  multi_attempts: false,
  hidemarks: false,
  studentfeedback: false,
  studentunlock: false,
  is_random: false,
  random_tabs: false
};
async function waitForSession() {
  var _window$session;
  if (!((_window$session = window.session) !== null && _window$session !== void 0 && _window$session.user)) {
    await new Promise(r => setTimeout(r, 100));
    return waitForSession();
  }
}
async function load(assignedids) {
  var _window, _window$session2, _window$session2$user;
  if (!assignedids) return;
  let classrooms = (await api.load({
    url: `/duocms/api/classrooms`
  })).data;
  classes = classrooms.filter(c => c.student_count > 0).map(c => ({
    label: c.name,
    value: c.id
  }));
  let assigned_ids = assignedids === null || assignedids === void 0 ? void 0 : assignedids.split(",");

  // wait for window.session to be set?
  await waitForSession();
  let meta = ((_window = window) === null || _window === void 0 ? void 0 : (_window$session2 = _window.session) === null || _window$session2 === void 0 ? void 0 : (_window$session2$user = _window$session2.user) === null || _window$session2$user === void 0 ? void 0 : _window$session2$user.meta) || {};
  for (let id of assigned_ids) {
    studentsPerAssignment[id] = [];
    if (isExam) {
      let exam = (await api.load({
        url: `/duocms/api/exams/${id}`
      })).data;
      assignments[id] = {
        title: exam.session + " " + exam.year + " " + exam.name,
        type: "exam",
        description: exam.description,
        exam_id: exam.id,
        exam: exam,
        classrooms: [],
        students: [],
        timelimit: null
      };
    } else {
      assignments[id] = (await api.load({
        url: `/duocms/api/assignedwork/${id}`
      })).data;
      assignments[id].type = "homework";
    }
    // set defaults
    assignments[id].assigned_at = new Date(new Date().setHours(8, 0, 0, 0));
    assignments[id].multi_attempts = meta.multi_attempts ? "" + meta.multi_attempts : "1";
    assignments[id].hidemarks = meta.hidemarks;
    assignments[id].studentfeedback = meta.studentfeedback;
    assignments[id].studentunlock = meta.studentunlock;
    assignments[id].is_random = meta.is_random;
    assignments[id].random_tabs = meta.random_tabs;
  }
}
async function loadStudentForClasses() {
  let loaded = Object.keys(studentsPerClass).map(a => +a);
  let needed = [...new Set(Object.values(assignments).map(row => row.classrooms).flat())]; // all selected classes de-deped
  let willLoad = needed.filter(id => !loaded.includes(+id));
  getStudentPerAssignment();
  if (!willLoad.length) return;
  willLoad.forEach(id => studentsPerClass[id] = []); // prevent dupe loads
  let res = await api.load({
    url: `/duocms/api/classrooms/${willLoad.join(",")}/details`
  });
  if (Array.isArray(res.data)) {
    res.data.forEach(classroom => studentsPerClass[classroom.id] = classroom.users.map(u => ({
      value: u.id,
      label: `${u.first_name.slice(0, 1)} ${u.surname}`
    })));
  } else if (res.data && res.data.id) {
    studentsPerClass[res.data.id] = res.data.users.map(u => ({
      value: u.id,
      label: `${u.first_name.slice(0, 1)} ${u.surname}`
    }));
  }
  getStudentPerAssignment();
}
function copyData() {
  Object.keys(links).forEach(field => {
    if (links[field]) {
      let src = "";
      Object.keys(assignments).forEach((id, idx) => {
        if (idx === 0) {
          src = assignments[id][field];
        } else {
          assignments[id][field] = src;
        }
      });
    }
  });
}
function getStudentPerAssignment() {
  for (let row of Object.values(assignments)) {
    studentsPerAssignment[row.id] = row.classrooms ? row.classrooms.map(class_id => studentsPerClass[class_id]).flat() : [];
  }
}
function getMonthsBetween(date1, date2) {
  let monthsbetween = (date2.getFullYear() - date1.getFullYear()) * 12 - +date1.getMonth() + +date2.getMonth();
  if (date2.getDate() < date1.getDate()) monthsbetween--;
  return monthsbetween;
}
function fixDueDate() {
  for (let row of Object.values(assignments)) {
    if (row.due_at && !row.lock_at || row.due_at > row.lock_at) row.lock_at = row.due_at;
    if (row.due_at && row.assigned_at && row.due_at < row.assigned_at) row.assigned_at = row.due_at;
    if (row.due_at && row.lock_at && getMonthsBetween(row.due_at, row.lock_at) > 2) row.lock_at = new Date(new Date(row.due_at).setMonth(row.due_at.getMonth() + 3));
  }
}
function formChanged() {
  copyData();
  loadStudentForClasses();
  fixDueDate();
}
function hasSubmitted(assignment) {
  submitcount++;
}
export async function save() {
  // try submitting all forms
  submitcount = 0;
  forms.querySelectorAll("form").forEach(form => form.requestSubmit());
  await tick();
  let failcount = Object.keys(assignments).length - submitcount;
  if (failcount > 0) {
    saving = false;
    throw new Error(`Errors found. Please check and re-submit`);
  }
  saving = true;
  for (let assignment of Object.values(assignments)) {
    delete assignment.id;
    delete assignment.assigned_by;
    await api.save({
      url: `/duocms/api/assignedwork`,
      data: assignment
    });
  }
  saving = false;
}
function resetPrefs(id) {
  assignments[id].multi_attempts = "1";
  assignments[id].hidemarks = false;
  assignments[id].studentfeedback = false;
  assignments[id].studentunlock = false;
  assignments[id].is_random = false;
  assignments[id].random_tabs = false;
  copyData();
}
function usePrefs(id) {
  var _window2, _window2$session, _window2$session$user;
  let meta = ((_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$session = _window2.session) === null || _window2$session === void 0 ? void 0 : (_window2$session$user = _window2$session.user) === null || _window2$session$user === void 0 ? void 0 : _window2$session$user.meta) || {};
  assignments[id].multi_attempts = "" + meta.multi_attempts || "1";
  assignments[id].hidemarks = meta.hidemarks;
  assignments[id].studentfeedback = meta.studentfeedback;
  assignments[id].studentunlock = meta.studentunlock;
  assignments[id].is_random = meta.is_random;
  assignments[id].random_tabs = meta.random_tabs;
  copyData();
}
$: isExam = isexam == "true";
$: load(assignedids);
$: hasInterventions = Object.values(assignments).some(a => a.intervention);</script>
<div class="assignementGrid">
  <div class="labels">
    <div>Title</div>
    <div class="textarea">Notes</div>
    {#if !isExam}<div>Assignment Type</div>{/if}
    <div>&nbsp;</div>
    <div style="min-height:{Math.max(...classrooms_height)}px">Class</div>
    {#if hasInterventions}
      <div style="min-height:{Math.max(...students_height)}px">Students</div>
    {/if}
    <div>Assigned Date</div>
    <div>Due Date</div>
    <div>Lock Date</div>
    <div>Time Limit</div>
    <div>Multiple Choice Attempts</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
  </div>
  <div class="links">
    <div><ToggleLink title="Make all titles the same" on:change={formChanged} bind:checked={links.title} /></div>
    <div class="textarea"><ToggleLink title="Make all notes the same" on:change={formChanged} bind:checked={links.notes} /></div>
    {#if !isExam}<div><ToggleLink title="Make all types the same" on:change={formChanged} bind:checked={links.type} /></div>{/if}
    <div><ToggleLink title="Make interventions fields the same" on:change={formChanged} bind:checked={links.intervention} /></div>
    <div style="min-height:{Math.max(...classrooms_height)}px"><ToggleLink title="Make all classrooms the same" on:change={formChanged} bind:checked={links.classrooms} /></div>
    {#if hasInterventions}
      <div style="min-height:{Math.max(...students_height)}px"><ToggleLink title="Make all students the same" on:change={formChanged} bind:checked={links.students} /></div>
    {/if}
    <div><ToggleLink title="Make all assigned dates the same" on:change={formChanged} bind:checked={links.assigned_at} /></div>
    <div><ToggleLink title="Make all duo dates the same" on:change={formChanged} bind:checked={links.due_at} /></div>
    <div><ToggleLink title="Make all lock dates the same" on:change={formChanged} bind:checked={links.lock_at} /></div>
    <div><ToggleLink title="Make all time limits the same" on:change={formChanged} bind:checked={links.timelimit} /></div>
    <div><ToggleLink title="Make all attempts options the same" on:change={formChanged} bind:checked={links.multi_attempts} /></div>
    <div><ToggleLink title="Make all hidemarks the same" on:change={formChanged} bind:checked={links.hidemarks} /></div>
    <div><ToggleLink title="Make all student feedback options the same" on:change={formChanged} bind:checked={links.studentfeedback} /></div>
    <div><ToggleLink title="Make all student unlock options the same" on:change={formChanged} bind:checked={links.studentunlock} /></div>
    <div><ToggleLink title="Make all random question options the same" on:change={formChanged} bind:checked={links.is_random} /></div>
    <div><ToggleLink title="Make all random order options the same" on:change={formChanged} bind:checked={links.random_tabs} /></div>
  </div>
  <div class="fields" bind:this={forms}>
    {#each Object.keys(assignments) as id,idx}
      <form class="form" on:submit|preventDefault={()=> hasSubmitted(assignments[idx])}>
        <Formrow disabled={links.title && idx} on:change={formChanged} type="text" bind:value={assignments[id].title} />
        <Formrow disabled={links.notes && idx} on:change={formChanged} type="textarea" rows="3" bind:value={assignments[id].notes} />
        {#if !isExam}
          <Formrow disabled={links.type && idx} on:change={formChanged} type="select" rows="3" bind:value={assignments[id].type}>
            <option value="homework">Homework</option>
            <option value="test">Test</option>
            <option value="classwork">Classwork</option>
          </Formrow>
        {/if}
        <Formrow disabled={links.intervention && idx} on:change={formChanged} id={"intervention"+idx} label="Intervention" type="checkbox" bind:checked={assignments[id].intervention} />
        <div style="min-height:{Math.max(...classrooms_height)}px">
          <div bind:offsetHeight={classrooms_height[idx]}>
            <FormMulti disabled={links.classrooms && idx} required on:change={formChanged} bind:value={assignments[id].classrooms} options={classes} />
          </div>
        </div>
        
        {#if hasInterventions}
          <div style="min-height:{Math.max(...students_height)}px">
            <div bind:offsetHeight={students_height[idx]}>
              {#if assignments[id].intervention}
                <FormMulti disabled={links.students && idx} on:change={formChanged} placeholder="All" bind:value={assignments[id].students} options={studentsPerAssignment[id]} />
              {/if}
            </div>
          </div>
        {/if}
        <Formdatetime disabled={links.assigned_at && idx} on:change={formChanged} placeholder="dd/mm/yyyy" bind:value={assignments[id].assigned_at} />
        <Formdatetime disabled={links.due_at && idx} required on:change={formChanged} placeholder="dd/mm/yyyy" bind:value={assignments[id].due_at} />
        <Formdatetime disabled={links.lock_at && idx} required on:change={formChanged} placeholder="dd/mm/yyyy" bind:value={assignments[id].lock_at} />
        <Formrow disabled={links.timelimit && idx} on:change={formChanged} type="number" min="0" placeholder="Leave blank to have no time limit" bind:value={assignments[id].timelimit}>
          <div slot="suffix"><span>minutes</span></div>
        </Formrow>

        <Formrow disabled={links.multi_attempts && idx} on:change={formChanged} type="select" bind:value={assignments[id].multi_attempts}>
          <option value="1">1</option>
          <option value="0">Unlimited</option>
        </Formrow>
        
        <Formrow style="margin-bottom:8px;" on:change={formChanged} id={"hidemarks"+idx} disabled={links.hidemarks && idx} label="Hide marks until due date" type="checkbox" bind:checked={assignments[id].hidemarks} />
        <Formrow style="margin-bottom:8px;" on:change={formChanged} id={"studentfeedback"+idx} disabled={links.studentfeedback && idx} label="Allow Students to Initiate Feedback" type="checkbox" bind:checked={assignments[id].studentfeedback} />
        <Formrow style="margin-bottom:8px;" on:change={formChanged} id={"studentunlock"+idx} disabled={links.studentunlock && idx} label="Allow Students to Unlock Assignment" type="checkbox" bind:checked={assignments[id].studentunlock} />
        <Formrow style="margin-bottom:8px;" on:change={formChanged} id={"is_random"+idx} disabled={links.is_random && idx} label="Assign Random Question Variations" type="checkbox" bind:checked={assignments[id].is_random} />
        <Formrow style="margin-bottom:8px;" on:change={formChanged} id={"random_tabs"+idx} disabled={links.random_tabs && idx} label="Randomise Question Order" type="checkbox" bind:checked={assignments[id].random_tabs} />
        <div class="btn-toolbar">
          <button class="btn btn-xs btn-link" on:click={()=> usePrefs(id)} type="button">Use Preferences</button>
          <button class="btn btn-xs btn-link" on:click={()=> resetPrefs(id)} type="button">Reset</button>
        </div>
      </form>
    {/each}
   </div>
</div>


{#if saving}
  <div class="saving">
    <div><IconSpinner /> Saving</div>
  </div>
{/if}


<style>
  .assignementGrid{
    display:grid;
    grid-template-columns: 180px 40px 1fr;
    --s-labelwidth:0px;
    font-weight:bold;
  }
  .labels div{
    text-align:right; 
    line-height:3.3rem;
    margin:5px;
  }
  .labels .textarea{
    height:7.5rem;
  }
  .links > div{
    --s-button-margins:5px 0 0 0px;
    height:37.9px;
  }

  .links .textarea{
    height:80px;
  }
  
  .fields{
    display:flex;
    overflow:auto;
  }
  .fields > form{
    min-width:300px;
    flex:1 0 0px;
  }
  .assignementGrid .fields :global(.form-input){
    --s-border-radius:5px;
    border: 1px solid rgba(9,148,255,0.2);
    padding: 0.5em 1em;
    box-shadow: inset 1px 1px 5px rgb(9 148 255 / 50%);
    color: #0a76c4;
  }
  .saving{
    position:absolute;
    background:rgba(255,255,255,0.8);
    top:0;
    left:10px;
    right:10px;
    bottom:-55px;
    display:grid;
    place-content: center;
    font-size:2em;
    color:#0a76c4;
  }


</style>


