
    var riot = require('riot')
    
riot.tag2('message-modal', '<modal id="messagemodal" esckey="{true}" title="Messages" onclose="{handleClose}" onopen="{handleOpen}"> <div if="{parent.messsagesHaveUpdated &amp;&amp; parent.messages.length == 0}"> <p>You have no messages.</p> </div> <grid2 if="{parent.messsagesHaveUpdated &amp;&amp; parent.messages.length &gt; 0}" columns="{parent.gridColumns}" data="{parent.messages}" height="{200}" click="{parent.handleSelect}"></grid2> <div if="{parent.message}" class="message-panel"> <h4>{parent.message.subject}</h4> <div class="markdownArea"></div> </div> </modal>', '.message-panel { height: 300px; overflow: auto; -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.3); box-shadow: 0 0 5px rgba(0,0,0,0.3); padding: 10px; margin-top: 15px; }', '', function(opts) {
var converter, showdown;

showdown = require("showdown");

converter = new showdown.Converter({
  underline: true,
  strikethrough: true,
  simpleLineBreaks: true,
  openLinksInNewWindow: true,
  emoji: true,
  simplifiedAutoLink: true,
  tables: true,
  tasklists: true,
  parseImgDimensions: true
});

this.closed = true;

this.messsagesHaveUpdated = false;

this.gridColumns = [
  {
    field: 'type',
    label: 'Type',
    width: 50,
    tag: "messagetype-cell",
    blah: "hello"
  }, {
    field: 'subject',
    label: 'Subject',
    width: 350,
    tag: "subject-cell"
  }, {
    field: 'updated_at',
    label: 'Updated',
    width: 145,
    tag: "datetime-cell"
  }
];

this.on('mount', function() {
  opts.stores.on('messages_changed', this.messsagesUpdated);
  opts.stores.on('message_changed', this.messsageUpdated);
  return opts.stores.on('session_changed', this.sessionUpdated);
});

this.on('unmount', function() {
  return opts.stores.off('messages_changed', this.messsagesUpdated);
});

this.on('update', function() {
  if (this.tags.modal.root._tag.visible === true && this.closed) {
    return this.handleOpen();
  }
});

this.on('updated', function() {
  var ref;
  if ((((ref = this.message) != null ? ref.message : void 0) != null) && document.querySelector(".markdownArea")) {
    return document.querySelector(".markdownArea").innerHTML = converter.makeHtml(this.message.message);
  }
});

this.messsagesUpdated = (function(_this) {
  return function(messages) {
    _this.messages = messages;
    _this.messsagesHaveUpdated = true;
    return _this.update();
  };
})(this);

this.messsageUpdated = (function(_this) {
  return function(message) {
    _this.message = message;
    return _this.update();
  };
})(this);

this.sessionUpdated = (function(_this) {
  return function(session) {
    _this.session = session;
    _this.gridColumns[2].message_check = _this.gridColumns[1].message_check = _this.session.user.message_check;
    return _this.update();
  };
})(this);

this.handleSelect = (function(_this) {
  return function(selected) {
    _this.selected = selected;
    return opts.stores.trigger('message_sync_one', _this.selected[0].id);
  };
})(this);

this.handleOpen = (function(_this) {
  return function() {
    opts.stores.trigger('session_sync');
    opts.stores.trigger('message_load');
    return _this.closed = false;
  };
})(this);

this.handleClose = (function(_this) {
  return function() {
    opts.stores.trigger('message_count_changed', 0);
    _this.session.user.message_check = new Date();
    return _this.closed = true;
  };
})(this);
});
riot.tag2('datetime-cell', '<div if="{opts.data[opts.cell.ridx].updated_at &gt; opts.cell.col.message_check}"><strong> <dateformat format="dd/MM/yyyy hh:mm" date="{opts.value}"> </dateformat></strong></div> <div if="{opts.data[opts.cell.ridx].updated_at &lt;= opts.cell.col.message_check}"> <dateformat format="dd/MM/yyyy hh:mm" date="{opts.value}"> </dateformat> </div>', '', '', function(opts) {
});
riot.tag2('subject-cell', '<div if="{opts.data[opts.cell.ridx].updated_at &gt; opts.cell.col.message_check}"><strong>{opts.value}</strong></div> <div if="{opts.data[opts.cell.ridx].updated_at &lt;= opts.cell.col.message_check}">{opts.value}</div>', '', '', function(opts) {
});
riot.tag2('messagetype-cell', '<div class="text-center"><span class="fa {opts.value==\'alert\'?\'fa-warning danger\':\'fa-info\'}"> </span></div>', '', '', function(opts) {
});
    
  