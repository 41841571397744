module.exports = (request,stores,resourceUtils)->

  class Resource 

    constructor:(url)->
      @resourceUrl = url
      request.Request.prototype.csrf = (token)->
        token = window.csrf if (!token)
        @set('X-CSRF-Token', token);
        return this

    get:(params...,cback)->
      if typeof cback != 'function' then throw new Error('callback not set') 
      url = @resourceUrl + if(params[0]?.id) then "/"+params[0].id else ""
      request.get url,params[0],(err,res)=> 
        @createResponse(err,res,params,cback,'get')
    
    save:(params...,cback)->
      if typeof cback != 'function' then throw new Error('callback not set')
      url = @resourceUrl + if(params[0]?.id) then "/"+params[0].id else ""
      data = params[0] #utils.requestConvert(params[0])
      if(params[0]?.id)
        request.put(url).send(data).csrf().end (err,res)=> 
          @createResponse(err,res,params,cback,'save')
      else
        request.post(url).send(data).csrf().end (err,res)=> 
          @createResponse(err,res,params,cback,'save')

    remove:(params...,cback)->
      if typeof cback != 'function' then throw new Error('callback not set')
      url = @resourceUrl + if(params[0]?.id) then "/"+params[0].id else ""
      request.del(url).query(params[0]).csrf().end (err,res)=> 
        @createResponse(err,res,params,cback,'remove')
        
    removeMulti:(params...,cback)->
      if typeof cback != 'function' then throw new Error('callback not set')
      url = @resourceUrl
      query = ids:(params[0].map (row)-> row.id).join(",")
      request.del(url).query(query).csrf().end (err,res)=>
        @createResponse(err,res,params,cback,'remove')

    createResponse:(err,res,params,cback,method)->
      if res?.status==403
        if !res.message && res.text?.includes("you have been blocked")
          stores.trigger('toaster_add',title:'Sorry',message:"Our security filter has detected unusual content, please amend and try again.",type:'warning')
        else
          stores.trigger('toaster_add',title:'Error',message:res.message,type:'error')
        return
      if(res?.unauthorized)
        callback = => @[method](params[0],cback)
        stores.trigger 'login_autherror',callback
      else if(res?.clientError)
        stores.trigger('toaster_add',title:'Error',message:res.body.message,type:'warning')
      else if(err)
        stores.trigger('toaster_add',title:'Error',message:"Sorry, unable to connect, please try again later",type:'error')
      else
        data = resourceUtils.responseConvert(res?.body?.data)
        if cback?
          cback(data)