
    var riot = require('riot')
    
riot.tag2('myworkmodal', '<modal id="assignedmodal" title="{\'Work Assigned \'+(assigned_ids ? \' x \'+assigned_ids.length : \'\')}" bssize="xl" onclose="{handleClose}" onsave="{handleSave}"> <row class="clearfix"> <assignedmodalform if="{parent.assigned_ids.length == 1}" stores="{parent.opts.stores}" examid="{parent.isExam ? parent.assigned_ids[0] : null}" assignedid="{!parent.isExam ? parent.assigned_ids[0] : null}" assigning="{parent.assigning}" removeclip="{false}"></assignedmodalform> <assignwork-multi if="{parent.assigned_ids.length &gt; 1}" isexam="{parent.isExam.toString()}" assignedids="{parent.assigned_ids.toString()}"></assignwork-multi> </row> </modal>', 'grid{ margin:-15px 0 5px 0; outline:1px solid #ddd; } .gridheader{ outline:1px solid #ddd; }', '', function(opts) {
this.on('mount', function() {
  opts.stores.on('assignedwork_saved', this.handleClose);
  opts.stores.on('assignedwork_changed_one', this.handleChange);
  this.cliplist = [];
  this.tabindex = 0;
  this.isExam = false;
  this.assigned_ids = [];
  this.assignedwork = null;
  if (!opts.params.assigned_id) {
    opts.params.assigned_id = "new";
  }
  if (opts.params.assigned_id === "new") {
    opts.stores.trigger('assignedwork_set', {});
  } else if (opts.params.assigned_id.match(/^new/)) {
    this.assigning = true;
    this.assigned_ids = opts.params.assigned_id.replace(/new/g, '').split(",");
  } else if (opts.params.assigned_id.match(/^exam/)) {
    this.assigning = true;
    this.isExam = true;
    this.assigned_ids = opts.params.assigned_id.replace(/exam/g, '').split(",");
  } else {
    this.assigned_ids = [opts.params.assigned_id];
  }
  return this.tags.modal.show();
});

this.on('unmount', function() {
  opts.stores.off('assignedwork_saved', this.handleClose);
  return opts.stores.off('assignedwork_changed_one', this.handleChange);
});

this.handleChange = (function(_this) {
  return function(assignment) {
    _this.assignment = assignment;
  };
})(this);

this.handleClose = (function(_this) {
  return function() {
    return opts.page('/assignedwork');
  };
})(this);

this.handleSave = (function(_this) {
  return function() {
    var ref, ref1;
    if (_this.assigned_ids.length > 1) {
      return document.querySelector("assignwork-multi").elem.save().then(function() {
        opts.stores.trigger('assignedwork_load');
        return opts.page('/assignedwork');
      })["catch"](function(err) {
        return _this.error(err.message);
      });
    } else {
      if (((ref = _this.assignment) != null ? (ref1 = ref.questionlist) != null ? ref1.length : void 0 : void 0) > 100) {
        return _this.error('The number of questions per assignment is limited to 100. Please remove some questions.');
      }
      if (!_this.assignment.type) {
        return _this.error('No assignment type has been selected.');
      }
      if (_this.assignment.classrooms.length < 1) {
        return _this.error('You haven\'t selected a classroom.');
      }
      if (!_this.assignment.due_at) {
        return _this.error('Please add a due date before saving.');
      }
      if (_this.assignment.assigned_at > _this.assignment.due_at) {
        return _this.error('Please make sure your due date is after your assigned date.');
      }
      if (_this.getMonthsBetween(_this.assignment.assigned_at, _this.assignment.due_at) >= 3) {
        return _this.error('An assignment has to be Due within 3 months of its Assigned Date. Please amend the Due Date.');
      }
      if (_this.getMonthsBetween(_this.assignment.due_at, _this.assignment.lock_at) >= 3) {
        return _this.error('An assignment has to be locked within 3 months of its Due Date. Please amend the Lock Date.');
      }
      return opts.stores.trigger('assignedwork_save');
    }
  };
})(this);

this.setTab = (function(_this) {
  return function(tabindex) {
    _this.tabindex = tabindex;
    return _this.update();
  };
})(this);

this.error = function(message) {
  return opts.stores.trigger('toaster_add', {
    title: "Error",
    message: message,
    type: 'warning'
  });
};

this.getMonthsBetween = function(date1, date2) {
  var monthsbetween;
  monthsbetween = ((date2.getFullYear() - date1.getFullYear()) * 12) - +date1.getMonth() + +date2.getMonth();
  if (date2.getDate() < date1.getDate()) {
    monthsbetween--;
  }
  return monthsbetween;
};
});
    
  