
    var riot = require('riot')
    
riot.tag2('labeloverlay', '<div riot-style="transform:scale({scale})" class="scaleblock"> <div each="{opts.labels}" riot-style="top:{y-1}px;left:{x-6}px;width:{width};height:{height}px;" text="{text}" class="labelblock"> <div class="markdown"></div> </div> </div>', 'labeloverlay { display: block; position: absolute; overflow: hidden; } .scaleblock { -webkit-transform-origin: top left; -moz-transform-origin: top left; -o-transform-origin: top left; -ms-transform-origin: top left; transform-origin: top left; } .labelblock { position: absolute; overflow: hidden; font-size: 16px; font-family: arial; } .labelblock :is(h1, .labelblock h2, .labelblock h3, .labelblock h4, .labelblock h5, .labelblock h6, .labelblock p) { margin: 0; color: #000; font-family: arial; letter-spacing: -0.015em; } .labelblock h1 { font-size: 2em; } .labelblock h2 { font-size: 1.8em; } .labelblock h3 { font-size: 1.5em; } .labelblock p { font-size: 1em; } .labelblock .markdown { height: 100%; }', '', function(opts) {
var converter, showdown;

showdown = require("showdown");

converter = new showdown.Converter({
  underline: true,
  strikethrough: true,
  simpleLineBreaks: true,
  openLinksInNewWindow: true,
  emoji: true,
  simplifiedAutoLink: true,
  tables: true,
  tasklists: true,
  parseImgDimensions: true
});

this.on('mount', function() {
  this.quesImg = document.querySelector('.img-question');
  if (!this.quesImg) {
    return;
  }
  this.positionAnswer();
  this.quesImg.addEventListener('load', this.positionAnswer);
  return window.addEventListener('resize', this.positionAnswer, true);
});

this.on('unmount', function() {
  if (this.quesImg) {
    this.quesImg.removeEventListener('load', this.positionAnswer);
  }
  return window.removeEventListener('resize', this.positionAnswer);
});

this.positionAnswer = (function(_this) {
  return function() {
    var pos, quesImg;
    quesImg = document.querySelector('.img-question');
    if (quesImg) {
      pos = quesImg.getBoundingClientRect();
      _this.root.style.top = quesImg.offsetTop + "px";
      _this.root.style.width = pos.width + "px";
      _this.root.style.height = pos.height + "px";
      _this.scale = pos.width / 570;
      _this.root.style.left = 0;
      return _this.update();
    }
  };
})(this);

this.on('updated', function() {
  return this.root.querySelectorAll(".labelblock").forEach((function(_this) {
    return function(elem) {
      return elem.querySelector(".markdown").innerHTML = converter.makeHtml(elem.getAttribute("text"));
    };
  })(this));
});
});
    
  