
    var riot = require('riot')
    
riot.tag2('recentlywatched', '<grid if="{clips &amp;&amp; clips.length}" columns="{gridColumns}" data="{clips}" height="355px" onselect="{handleSelect}"> <p if="{!clips}"><i class="fa fa-spinner fa-pulse">Loading Data</i></p> <p if="{!clips.length}">You have no recently watched clips</p> </grid>', '', '', function(opts) {
this.gridColumns = [
  {
    field: 'id',
    label: '#',
    width: '10%'
  }, {
    field: 'name',
    label: 'Title',
    width: '60%'
  }, {
    field: 'created_at',
    label: 'When',
    width: '30%'
  }
];

this.on('mount', function() {
  opts.stores.on('clip_recent_changed', (function(_this) {
    return function(clips) {
      _this.clips = clips;
      return _this.update();
    };
  })(this));
  return opts.stores.trigger('clip_recent_get');
});

this.on('unmount', function() {
  return opts.stores.off('clip_recent_changed');
});

this.on('handleSelect', function(row) {});
});
    
  