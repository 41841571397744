
    var riot = require('riot')
    
riot.tag2('search', '<div class="input-group"> <input name="keyword" oninput="{update}" type="text" placeholder="{opts.placeholder || \'Search\'}" class="form-control"> <div class="input-group-btn"> <div if="{!keyword.value}" class="btn btn-primary"> <div class="fa fa-search"></div> </div> <div if="{keyword.value}" onclick="{cancelSearch}" class="btn btn-warning"> <div class="fa fa-times"></div> </div> </div> </div>', '', '', function(opts) {
var debounce;

this.prevUpdateValue = null;

debounce = null;

this.on('update', (function(_this) {
  return function() {
    if (debounce) {
      clearTimeout(debounce);
    }
    return debounce = setTimeout(function() {
      if (opts.data && typeof opts.onsearch === 'function') {
        if (_this.keyword.value === "") {
          return opts.onsearch(opts.data);
        }
        return opts.onsearch(_this.handleFilter(_this.keyword.value));
      }
    }, 300);
  };
})(this));

this.handleFilter = (function(_this) {
  return function(keyword) {
    keyword = _this.escapeRegExp(keyword);
    keyword = new RegExp(keyword.toLowerCase().trim(), "g");
    return opts.data.filter(function(row) {
      var str;
      str = JSON.stringify(row).toLowerCase();
      return str.match(keyword);
    });
  };
})(this);

this.escapeRegExp = (function(_this) {
  return function(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  };
})(this);

this.cancelSearch = (function(_this) {
  return function() {
    return _this.keyword.value = "";
  };
})(this);
});
    
  