module.exports = 

  class MessageStore

    constructor:(riot,Resource,@utils)->
      riot.observable(@)
      @resource = new Resource("/duocms/api/messages")
      @messages = []
      @count = null
      @messageLoaded = {}
      @on 'message_load',@load
      @on 'message_sync',@sync
      @on 'message_sync_one',@sync_one
      @on 'message_count',@message_count
      @on 'message_count_sync',@message_count_sync

    load:(cback)->
      @data_state = 'loading'
      @resource.get {resetcount:true},(@messages)=>
        @data_state = 'loaded'
        @sync()
        cback(@messages) if cback

    sync:->
      return @load() if !@data_state
      setTimeout => # force async for ie
        @trigger 'messages_changed',@messages

    load_one:(id)->
      @messageLoaded[id] = "loading"
      @resource.get {id:id},(message)=>
        @messageLoaded[id] = message
        @sync_one(id)
        @trigger 'message_changed'+id,@messageLoaded[id]
      
    sync_one:(id)->
      return @load_one(id) if !@messageLoaded[id]
      setTimeout => # force async for ie
        @trigger 'message_changed'+id,@messageLoaded[id]
        @trigger 'message_changed',@messageLoaded[id]

    message_count:->
      @count_state = "loading"
      @resource.get {count:true},(@count)=>
        @trigger 'message_count_changed',@count

    message_count_sync:->
      return @message_count() if !@count_state
      setTimeout => # force async for ie
        @trigger 'message_count_changed',@count



  

  