require './libs/polyfills'
ro = require './libs/resize-observer-poly.js' # needed for ios 12 and below
window.ResizeObserver = ro if !window.ResizeObserver
riot        = require('riot')
window.$ = window.jQuery = require 'jquery'
RiotControl = require('riotcontrol')
groupby     = require './libs/groupby.coffee'
request     = require 'superagent'
PathParser  = require('pathparser')
try 
  Raven.config('https://60ce4fe01f3e495b9e235602423d42c1@sentry.duodesign.co.uk/3',{
    shouldSendCallback:(data)->!data.message.match(/'jQ'/) && Math.random() * 100 <= 10 # send 10% of errors, try to hide mathquill error
  }).install() 
catch e


# Librarys
require './libs/bsmenus.coffee'
require './libs/mathquill.10.1.js'
utils = require './libs/utils.coffee'
natsort = require './libs/natsort.js'
resourceUtils = require './libs/resourceUtils.coffee'

resource = require('./libs/resource.coffee')(request,RiotControl,resourceUtils)

# ie polyfill for element.remove
if !('remove' in Element.prototype)
  Element.prototype.remove = -> @parentNode.removeChild(this) if this.parentNode

#riot.util.tmpl.errorHandler = (err)-> console.error(err)

require './app.tag'
#users
require './user/userlist.tag'
require './user/usermodal.tag'
require './user/userpanel.tag'
require './user/upload-modal.tag'
require './user/password-modal.tag'
require './user/restore-modal.tag'
#account
require './account/accountpanel.tag'
require './account/accountdetails.tag'
require './account/schooldetails.tag'
require './account/recentlywatched.tag'
require './account/subscription.tag'
#assignment
require './assignment/assignments.tag'
require './assignment/assignedwork.tag'
require './assignment/assignmentmanagerpanel.tag'
require './assignment/assignedmodal.tag'
require './assignment/assignedmodal.tag'
require './assignment/student-workpanel.tag'
#require './assignment/student-modal.tag'
require './assignment/student-panel.tag'
# require './assignment/student-panel2.tag'
require './assignment/student-alert.tag'
require './assignment/assignedmodalform.tag'
require './assignment/timer.tag'
require './assignment/timer-mini.tag'
require './assignment/completedassignments.tag'
require './assignment/questionpicker.tag'
require './assignment/myworkmodal.tag'
require './assignment/congrats-popup.tag'
require './assignment/labeloverlay.tag'
require './assignment/didyouknow.tag'
#browse
require './browse/browsepanel.tag'
require './browse/clippicker2.tag'
#require './browse/questionpanel.tag'
require './browse/questionpanel.tag'
# require './browse/questionpanel2.tag'
#answer stuff
require './answer/multianswer.tag'
require './answer/answer.tag'
require './answer/mathsinput.tag'
require './answer/drawinginput.tag'
require './answer/drawing.tag'
require './answer/ruler.tag'
require './answer/protractor.tag'
require './answer/compass.tag'
require './answer/draginput.tag'
require './answer/freeforminput.tag'
require './answer/paper.tag'
require './answer/handle.tag'
require './answer/textinput.tag'
require './answer/keyboard.tag'
#class
require './class/classpanel.tag'
require './class/classlist.tag'
require './class/classmodal.tag'
require './class/importmodal.tag'
require './class/classdashboard.tag'
require './class/staffleadermodal.tag'
#require './class/classmemberlist.tag'
require './class/classmarks.tag'
require './class/classmarks-tabbed.tag'
require './class/redirect.tag'
require './class/usersmarks.tag'
require './class/userassignment.tag'
require './class/userawards.tag'
require './class/classrestoremodal.tag'
#extras
require './extras/extras.tag'
require './extras/extravideo.tag'
#contact
require './contact/contactpanel.tag'
#dashboard
require './dashboard/dashboardpanel.tag'
#stats
# require './stats/progresspanel.tag'
require './stats/teacherstatspanel.tag'
#require './stats/usage-filters.tag'
# require './stats/loginsperday.tag'
require './stats/loginsperday2.tag'
require './stats/questionstats.tag'
require './stats/clipstats.tag'
require './stats/usage-clippick.tag'
#renew
require './renew/renewform.tag'
require './renew/suspended.tag'
require './renew/sub-reminder.tag'
# messages
require './messages/message-icon.tag'
require './messages/message-modal.tag'
# student stats
require './student_stats/student-stats-panel.tag'
require './student_stats/student-stats.tag'
# feedback
require './feedback/feedback.tag'
require './feedback/teachersfeedback.tag'
require './feedback/studentfeedback.tag'
#shared
require './shared/barchart.tag'
require './shared/linechart.tag'
require './shared/menu.tag'
require './shared/panel.tag'
require './shared/piechart.tag'
require './shared/vimeoplayer.tag'
require './shared/crudbuttons.tag'
require './shared/toaster.tag'
require './shared/login.tag'
require './shared/nextprev.tag'
require './shared/uploadbutton.tag'
require './shared/multiselect.tag'
require './shared/nexttabs.tag'
require './shared/grid2.tag'
require './shared/grid.tag'
require './shared/search.tag'
require './shared/404.tag'
require './shared/modal.tag'
require './shared/counter.tag'
require './shared/charpicker.tag'
require './shared/charpicker-inline.tag'
require './shared/feedback-icon.tag'
require './shared/donut-count.tag'
require './shared/tip.tag'
require './shared/dateformat.tag'
require './shared/change-password'

#Leaderboard
require "./leaderboard/leadermodal.tag"
require "./leaderboard/awards.tag"
require "./leaderboard/challenges.tag"
require "./leaderboard/awardmodal.tag"
require "./leaderboard/awardmodal-challenge.tag"
require "./leaderboard/challengepicker.tag"
require "./leaderboard/challengeinfo.tag"

require 'riot-kit/src/popover'
require 'riot-kit/src/datepicker'
require 'riot-kit/src/confirm'
require 'riot-routehandler/src/routehandler'
require 'riot-subtag/src/subtag'
require 'riot-kit/src/tabs'
require 'riot-kit/src/dropdown'
require 'riot-kit/src/alert'
require 'riot-kit/src/progressbar'
require 'riot-kit/src/navigation'
require 'riot-kit/src/raw'
require 'riot-kit/src/pagination'

# need to be at the bottom to override any use of riot-kit form
require './shared/form.tag' 
require './shared/formdate.tag'
require './shared/formdatetime.tag'

page = require 'page'

# Stores
params = {}
VideoStore        = require './stores/video.coffee'
SessionStore      = require './stores/session.coffee'
UserStore         = require './stores/user.coffee'
ClassStore        = require './stores/class.coffee'
ToastStore        = require './stores/toaster.coffee'
LoginStore        = require './stores/login.coffee'
QuestionStore     = require './stores/question.coffee'
AssignedworkStore = require './stores/assignedwork.coffee'
AnswerStore       = require './stores/answer.coffee'
LogStore          = require './stores/log.coffee'
MessageStore      = require './stores/message.coffee'
FeedbackStore     = require './stores/feedback.coffee'
PrefsStore        = require './stores/prefs.coffee'

RiotControl.addStore(new VideoStore(riot,resource,groupby,utils,natsort))
RiotControl.addStore(new SessionStore(riot,resource))
RiotControl.addStore(new ClassStore(riot,resource,utils))
RiotControl.addStore(new UserStore(riot,resource,utils,RiotControl,request))
RiotControl.addStore(new QuestionStore(riot,resource))
RiotControl.addStore(new AssignedworkStore(riot,resource,utils,RiotControl,request,resourceUtils))
RiotControl.addStore(new LoginStore(riot,request,utils,RiotControl))
RiotControl.addStore(new ToastStore(riot))
RiotControl.addStore(new AnswerStore(riot,resource,RiotControl))
RiotControl.addStore(new LogStore(riot,resource,RiotControl))
RiotControl.addStore(new MessageStore(riot,resource,utils))
RiotControl.addStore(new FeedbackStore(riot,resource,utils,RiotControl))
RiotControl.addStore(new PrefsStore(riot))


basepath = '/vle'
mode = ''
user = null
groups = null
remounted = null
Routes = null

# activity checker
window.lastinteraction = new Date()
RiotControl.trigger 'message_count'
document.addEventListener "mousemove",->
  window.lastinteraction = new Date()
document.addEventListener "scroll",->
  window.lastinteraction = new Date()

# runs every minute to update activity
setInterval => 
  if new Date() - window.lastinteraction < 1000*60*2
    RiotControl.trigger 'message_count'
,1000*60*5

redirect = (ctx,next,page)->
  page.redirect("/usage/logins/")

suspendCheck = (ctx,next,page)->
  # google analystic
  path = ctx.canonicalPath.replace(/[^\/]$/,"$&/")
  # ga('set', 'page', path)
  # ga('send', 'pageview',{'dimension1':  window.centre_id || 'unknown'})
  if window.gtag
    window.gtag('pageview',{
      centre_id: window.centre_id || 'unknown',
      page_title: document.title,
      page_location: window.location.protocol+"//"+window.location.host+path
    }) 

  return page("/suspended") if user?.org?.suspend == true && ctx.path != "/renew" && ctx.path != "/suspended"
  return page ("/") if user?.org?.suspend != true  && ctx.path == "/suspended"
  next()


app = riot.mount('app',{stores:RiotControl})
riot.mount('change-password') if document.querySelector('change-password')


RiotControl.one 'session_changed',(res)->
  user = res.user
  groups  = user.groups.map (group)-> group.name

  if groups.indexOf('Students')>-1
    mode = 'student'
    Routes = [
      {route:"*",use:suspendCheck}
      {route:"/",path:"/vle/",label:"My Work",tag:"student-workpanel"}
      {route:"/assignment/:assigned_id/:question_id?",tag:"student-panel"}
      # {route:"/progress",path:"/vle/progress",label:"My Progress",tag:"progresspanel"}
      {route:"/browse/:video_id?",path:"/vle/browse",label:"Videos",tag:"browsepanel"}
      {route:"/browse/:video_id/practice/:question_id?",tag:"questionpanel"}
      {route:"/stats/",tag:"student-stats-panel",path:"/vle/stats/",label:"My Progress"}
      {route:"/feedback",path:"/vle/feedback/",label:"Feedback",tag:"studentfeedback"}
      {route:"/extras",tag:"extras",label:"Extras",path:"/vle/extras/student"}
      {route:"/extras/student/",tag:"extras"}
      # {route:"/contact",path:"/vle/contact",label:"Contact",tag:"contactpanel"}
      {route:"/account",tag:"accountpanel"}
      {route:"/suspended",tag:"suspended"}
      
      {route:"*",tag:"notfound"}
    ]
  if groups.indexOf('Teachers')>-1
    mode = 'teacher'
    Routes = [
      {route:"*",use:suspendCheck}
      {route:"/",path:"/vle/",label:"Classes",tag:"classpanel",routes:[
        {route:"/",tag:"classdashboard"}
        {route:"/classes/",tag:"classdashboard"}
        {route:"/classes/:class_id",tag:"redirect-tag"}
        {route:"/classes/:class_id/:type",tag:"classmarks-tabbed",routes:[
          {route:"/edit",tag:"classmodal"}
        ]}
        {route:"/classes/:class_id/:type/:user_id/independent",tag:"student-stats"}
        {route:"/classes/:class_id/:type/:user_id/:assigned_id/:question_id?",tag:"userassignment"}
        {route:"/classes/:class_id/:type/:user_id",tag:"usersmarks"}
      ]}
      {route:"/assignedwork",path:"/vle/assignedwork",label:"Assignments",tag:"assignmentmanagerpanel",routes:[
        {route:"/assignments/:assigned_id/:mode?",tag:"assignedmodal"}
        {route:"/mywork/:assigned_id/:mode?",tag:"myworkmodal"}
      ]}
      {route:"/assignedwork/assignment/:assigned_id/:question_id?",tag:"student-panel"}
      {route:"/assignedwork/exam/:assigned_id/:question_id?",tag:"student-panel"}
      {route:"/users",path:"/vle/users",label:"Users",tag:"userpanel",routes:[
        {route:"/:user_id?",tag:"usermodal"}
      ]}
      {route:"/browse/:video_id?",path:"/vle/browse",label:"Videos",tag:"browsepanel"}
      {route:"/browse/:video_id/practice/:question_id?",tag:"questionpanel"}
      {route:"/usage/",path:"/vle/usage/",label:"Usage",tag:"teacherstatspanel",routes:[
        {route:"/",use:redirect}
        {route:"/logins/",tag:"loginsperday2"}
        {route:"/videos/:year?/:month?/:class_id?/:qualification?",tag:"clipstats"}
        {route:"/questions/:year?/:month?/:class_id?/:qualification?",tag:"questionstats"}
      ]}
      {route:"/feedback/",path:"/vle/feedback/",label:"Feedback",tag:"teachersfeedback"}
      {route:"/extras",tag:"extras",label:"Extras",path:"/vle/extras/teacher"}
      {route:"/extras/teacher/",tag:"extras"}
      {path:"/help",label:"Help"}
      {route:"/account",tag:"accountpanel"}
      {route:"/renew",tag:"renewform"}
      {route:"/suspended",tag:"suspended"}
      {route:"*",tag:"notfound"}
    ]
  
  if groups.indexOf('School Admins')>-1
    mode = 'admin'
    Routes = [
      {route:"*",use:suspendCheck}
      # {route:"/",path:"/vle/",label:"Dashboard",tag:"teacherstatspanel"}
      {route:"/",path:"/vle/",label:"Classes",tag:"classpanel",routes:[
        {route:"/",tag:"classdashboard"}
        {route:"/classlist",tag:"classlist"}
        {route:"/allclasslist",tag:"classlist"}
        {route:"/allclasses/",tag:"classdashboard"}
        {route:"/classes/",tag:"classdashboard"}
        {route:"/classes/:class_id/:type",tag:"classmarks-tabbed",routes:[
          {route:"/edit",tag:"classmodal"}
        ]}
        {route:"/classes/:class_id",tag:"redirect-tag"}
        {route:"/classes/:class_id/:type/:user_id/independent",tag:"student-stats"}
        {route:"/classes/:class_id/:type/:user_id/:assigned_id/:question_id?",tag:"userassignment"}
        {route:"/classes/:class_id/:type/:user_id",tag:"usersmarks"}
        {route:"/allclasses/:class_id/:type",tag:"classmarks-tabbed",routes:[
          {route:"/edit",tag:"classmodal"}
        ]}
        {route:"/allclasses/:class_id",tag:"redirect-tag"}
        {route:"/allclasses/:class_id/:type/:user_id/independent",tag:"student-stats"}
        {route:"/classes/:class_id/:type/:user_id/:assigned_id/:question_id?",tag:"userassignment"}
        {route:"/allclasses/:class_id/:type/:user_id",tag:"usersmarks"}
        {route:"/allclasses/:class_id/:type/:user_id/:assigned_id/:question_id?",tag:"userassignment"}
      ]}
      {route:"/assignedwork",path:"/vle/assignedwork",label:"Assignments",tag:"assignmentmanagerpanel",routes:[
        {route:"/assignments/:assigned_id/:mode?",tag:"assignedmodal"}
        {route:"/mywork/:assigned_id/:mode?",tag:"myworkmodal"}
      ]}
      {route:"/assignedwork/assignment/:assigned_id/:question_id?",tag:"student-panel"}
      {route:"/assignedwork/exam/:assigned_id/:question_id?",tag:"student-panel"}
      {route:"/users",path:"/vle/users",label:"Users",tag:"userpanel",routes:[
        {route:"/:user_id?",tag:"usermodal"}
      ]}
      {route:"/browse/:video_id?",path:"/vle/browse",label:"Videos",tag:"browsepanel"}
      {route:"/browse/:video_id/practice/:question_id?",tag:"questionpanel"}
      {route:"/usage/",path:"/vle/usage/",label:"Usage",tag:"teacherstatspanel",routes:[
        {route:"/",use:redirect}
        {route:"/logins/:year?/:month?/:class_id?/:yeargroup?",tag:"loginsperday2"}
        {route:"/videos/:year?/:month?/:class_id?/:qualification?",tag:"clipstats"}
        {route:"/questions/:year?/:month?/:class_id?/:qualification?",tag:"questionstats"}
      ]}
      {route:"/feedback/",path:"/vle/feedback/",label:"Feedback",tag:"teachersfeedback"}
      {route:"/extras",tag:"extras",label:"Extras",path:"/vle/extras/admin"}
      {route:"/extras/admin",tag:"extras"}
      {path:"/help",label:"Help"}
      {route:"/account",tag:"accountpanel"}
      {route:"/renew",tag:"renewform"}
      {route:"/suspended",tag:"suspended"}
      {route:"*",tag:"notfound"}
    ]
  
  if groups.indexOf('guest')>-1 && !Routes #this gets set by cancel in login dialogue
    mode = 'guest'
    Routes = [
      {route:"/",path:"/vle/",path:"/vle/",label:"Demo Videos",tag:"browsepanel"}
      {route:"/browse/:video_id?",tag:"browsepanel"}
      {route:"/browse/:video_id/practice/:question_id?",tag:"questionpanel"}
      {route:"*",tag:"notfound"}
    ]
  if mode && !remounted
    remounted = true
    app = riot.mount('app',{stores:RiotControl,routeroptions:{hashbang:false,base:basepath},routes:Routes,mode:mode,page:page})
    riot.update()



# change colour of navbar on scroll
navBar = document.querySelector('.navbar')
checkScroll = ->
  navBar = document.querySelector('.navbar')
  navHeight = navBar.getBoundingClientRect().height
  if window.scrollY < navHeight && document.location.pathname == "/"
    navBar.className = navBar.className.replace(/ ?unscrolled ?/,'')+' unscrolled'
  else
    navBar.className = navBar.className.replace(/ ?unscrolled ?/,'')
if(navBar)
  ['scroll','load','resize'].forEach (type)->
    window.addEventListener type,checkScroll

#hydrate youtube videos
[].slice.call(document.querySelectorAll('[youtube]')).forEach (elem)->
  url = elem.getAttribute('youtube');
  style = elem.getAttribute('style');
  html = "" ;
  html += '<div class="img-responsive" style="'+style+'">';
  html += '  <div class="embed-responsive embed-responsive-4by3">';
  html += '    <iframe src="'+url+'"></iframe>';
  html += '  </div>';
  html += '</div>';
  elem.outerHTML = html

videolinks = [].slice.call(document.querySelectorAll('a[href^="https://player.vimeo.com"]'))
extravid = null
if videolinks && videolinks.length > 0
  setTimeout =>
    extravid = riot.mount('extravideo')
  videolinks.forEach (elem)->
    elem.addEventListener "click",(event)=>
      event.preventDefault()
      vimeo_id = event.target.href.match(/[0-9]+$/)
      title = event.target.textContent
      extravid[0].open(vimeo_id,title)

if !window.location.pathname.match(/^\/vle\/?/) 
  riot.mount("*",{stores:RiotControl}) 

module.exports = {RiotControl}