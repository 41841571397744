
    var riot = require('riot')
    
riot.tag2('redirect-tag', '', '', '', function(opts) {
this.on('mount', function() {
  var defaultKey;
  if (!opts.params.type) {
    defaultKey = "homework";
    this.section = window.location.pathname.match('allclasses') ? 'allclasses' : 'classes';
    return opts.page('/vle/' + this.section + '/' + opts.params.class_id + '/' + defaultKey);
  }
});
});
    
  