
    var riot = require('riot')
    
riot.tag2('student-alert', '<alert each="{alerts}" bsstyle="{style}" no-reorder if="{!completed &amp;&amp; days&lt;7}"><a href="/vle/assignment/{id}" class="btn btn-primary btn-sm pull-right btn-{style}">click here to complete</a><span class="fa fa-file-text-o fa-2x pull-left"></span> <p style="margin-top:5px"><strong if="{!allanswered &amp;&amp; days &gt; 0}">"{title}" is due <span if="{days &gt; 0}">very soon.</span></strong><strong if="{allanswered || days &lt; 1}">You still have <span if="{days &gt; 0}">{days} {days<2 && days>0?\'day\':\'days\'} </span><span if="{minutes}">{minutes} {minutes<2 && minutes>0?\'minute\':\'minutes\'} </span><span if="{days &lt; 1 &amp;&amp; !minutes}">time </span><span> to improve your score for "{title}".</span></strong></p> </alert> <alert each="{alerts}" bsstyle="success" no-reorder if="{completed &amp;&amp; (new Date() - finished) &lt; 5*60*1000}" onclose="{handleClose.bind(null,id)}"><span class="fa fa-check-square fa-2x pull-left"></span> <p style="margin-top:5px"> <strong>"{title}" has now been submitted.</strong></p> </alert>', '', '', function(opts) {
this.updater = null;

this.on('mount', function() {
  var dismissed;
  dismissed = window.localStorage.dismissedUserAlerts;
  this.closed = {};
  if (dismissed) {
    this.closed = JSON.parse(dismissed);
  }
  return this.updater = setInterval(((function(_this) {
    return function() {
      return _this.update();
    };
  })(this)), 2000);
});

this.on('unmount', function() {
  if (this.updater) {
    return clearInterval(this.updater);
  }
});

this.on('update', function() {
  var day, minute, ref;
  this.alerts = [];
  day = 24 * 60 * 60 * 1000;
  minute = 60 * 1000;
  if (((ref = opts.assignments) != null ? ref.length : void 0) == null) {
    return;
  }
  this.alerts = opts.assignments.map((function(_this) {
    return function(assignment) {
      var ref1;
      if (assignment.type === "test" && assignment.started) {
        assignment.until = assignment.started.getTime() - new Date().getTime();
        assignment.minutes = 0 + Math.round(assignment.until / minute) + (assignment.timelimit ? assignment.timelimit : assignment.duration);
      } else {
        assignment.until = ((ref1 = assignment.due_at) != null ? ref1.getTime() : void 0) - new Date().getTime();
      }
      assignment.days = 0 + Math.ceil(assignment.until / day);
      if (!assignment.completed) {
        assignment.completed = false;
      }
      if (assignment.type === "test" && (new Date() - assignment.started) / 60000 >= (assignment.timelimit ? assignment.timelimit : assignment.duration)) {
        assignment.completed = true;
      }
      if (assignment.student_percent === 100 && ["homework", "classwork"].includes(assignment.type)) {
        assignment.completed = true;
      }
      if (assignment.lock_at < new Date()) {
        assignment.completed = true;
      }
      assignment.style = (function() {
        switch (false) {
          case !(assignment.days < 2):
            return 'danger';
          case !(assignment.days < 3):
            return 'warning';
          default:
            return 'info';
        }
      })();
      return assignment;
    };
  })(this)).filter((function(_this) {
    return function(assignment) {
      var ref1;
      return assignment.until >= 0 && (!_this.closed[opts.userid] || ((ref1 = _this.closed[opts.userid]) != null ? ref1.indexOf(assignment.id) : void 0) === -1);
    };
  })(this));
  return this.alerts.sort(function(a, b) {
    return a.until - b.until;
  });
});

this.handleClose = (function(_this) {
  return function(assignment_id) {
    if (!_this.closed[opts.userid]) {
      _this.closed[opts.userid] = [];
    }
    if (_this.closed[opts.userid].indexOf(assignment_id) === -1) {
      _this.closed[opts.userid].push(assignment_id);
    }
    return window.localStorage.dismissedUserAlerts = JSON.stringify(_this.closed);
  };
})(this);
});
    
  