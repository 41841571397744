
    var riot = require('riot')
    
riot.tag2('teacherstatspanel', '<div class="row"> <div class="col-xs-12"> <panel heading="Usage" class="row-lg-5 row-xs-2"> <ul class="nav nav-tabs hidden-print hidden-print"> <li class="{active:parent.mode==\'logins\'}"><a onclick="{parent.tab}" name="loginsTab">Daily School Stats</a></li> <li class="{active:parent.mode==\'videos\'}"><a onclick="{parent.tab}" name="videosTab">Videos Watched</a></li> <li class="{active:parent.mode==\'questions\'}"><a onclick="{parent.tab}" name="questionsTab">Practice Questions</a></li> </ul> <routehandler filters="{parent.filters}" stores="{parent.opts.stores}"></routehandler> </panel> </div> </div>', '', '', function(opts) {
this.curpath = window.location.pathname;

this.on('mount', function() {
  opts.stores.on('session_changed', this.sessionUpdated);
  return opts.stores.trigger('session_sync');
});

this.on('unmount', function() {
  return opts.stores.off('session_changed', this.sessionUpdated);
});

this.sessionUpdated = (function(_this) {
  return function(data) {
    var ref;
    _this.groups = ((ref = data.user) != null ? ref.groups : void 0) ? data.user.groups.map(function(group) {
      return group.name;
    }) : [];
    _this.user = data.user;
    if (_this.groups.indexOf("School Admins") > -1) {
      _this.admin = true;
    }
    return _this.update();
  };
})(this);

this.on('update', function() {
  this.pathparts = window.location.pathname.split('/');
  if (this.pathparts) {
    return this.mode = this.pathparts[3];
  }
});

this.handleFilters = (function(_this) {
  return function(filters) {
    var newpath;
    newpath = '/vle/usage/' + _this.mode + '/' + filters.year + '/' + filters.month;
    if (_this.curpath !== newpath && _this.mode) {
      _this.curpath = newpath;
      return setTimeout(function() {
        return opts.page(newpath);
      });
    }
  };
})(this);

this.tab = (function(_this) {
  return function(e) {
    var newpath;
    _this.mode = e.target.name.replace(/Tab$/, '');
    newpath = '/vle/usage/' + _this.mode;
    if (opts.params.year) {
      newpath += '/' + opts.params.year;
    }
    if (opts.params.month) {
      newpath += '/' + opts.params.month;
    }
    opts.page(newpath);
    return _this.update();
  };
})(this);
});
    
  