
    var riot = require('riot')
    
riot.tag2('compass', '<div class="center fa fa-crosshairs"></div> <div riot-style="top:{cmtop}px" class="radius">{cmradius}cm</div> <div name="size" class="handle"> <div class="fa fa-arrows-h"></div> </div> <div name="rotate" class="handle rotate"> <div class="fa fa-arrows-v"></div> </div> <div name="pencil" class="pencil"></div>', 'compass{ border-radius:15px 0 0 15px; width:200px; height:30px; position:absolute; top:100px; left:100px; transform-origin:15px center; cursor:move; background:rgba(210,210,210,0.8); box-shadow:0 0 5px rgba(0,0,0,0.7); z-index:2; } compass .radius{ position:absolute; top:5px; left:25px; color:#444; } compass .center { position:absolute; top:7.5px; left:9px; color:#444; } compass .pencil{ background:#135CA6; height:20px; width:21px; position: absolute; right:0; top:5px; cursor:pointer; } compass .pencil:after{ content:" "; border:10px solid #135CA6; border-width:10px 10px 10px 20px; border-color: transparent transparent transparent #135CA6; position: absolute; left:20px; top:0px; } compass .handle{ position:absolute; right:62px; top:0; bottom:0; background:#FDC641; width:30px; cursor:pointer; color:white; font-size:20px; line-height:30px; text-align:center; } compass .rotate{ right:30px; }', '', function(opts) {
var default_radius, move, radius, rotate;

move = require('../libs/move.coffee');

rotate = require('../libs/rotate.coffee');

this.prevRadians = null;

this.cmradius;

default_radius = null;

this.cmtop = 5;

this.points = [];

this.offset = 0;

radius = default_radius * (opts.scale * 12.83);

this.on('mount', (function(_this) {
  return function() {
    new move(_this.root, _this.root, {
      dragging: function(pos) {
        var center;
        if (opts.snaps) {
          center = {
            left: pos.left + 13,
            top: pos.top + 13
          };
          return opts.snaps.forEach(function(snap) {
            var diff;
            diff = Math.abs(center.left - snap.left) + Math.abs(center.top - snap.top);
            if (diff < 10) {
              _this.root.style.left = (snap.left - 13) + "px";
              return _this.root.style.top = (snap.top - 13) + "px";
            }
          });
        }
      }
    });
    new rotate(_this.rotate, _this.root, {
      center: {
        top: 14,
        left: 14
      },
      onRotate: _this.handleRotate
    });
    new rotate(_this.pencil, _this.root, {
      center: {
        top: 14,
        left: 14
      },
      onRotate: _this.handleDraw,
      dragEnd: _this.drawEnd
    });
    new move(_this.size, _this.size, {
      vertical: false,
      dragging: _this.handleResize,
      minh: -90
    });
    default_radius = opts.scale > 6 ? 2 : 4;
    radius = default_radius * (opts.scale * 12.83);
    _this.root.style.width = radius + "px";
    return _this.update();
  };
})(this));

this.on('update', (function(_this) {
  return function() {
    if (radius < 0) {
      radius = 1;
    }
    _this.root.style.width = radius + "px";
    _this.cmradius = (Math.round(radius * 10 / (opts.scale * 12.83))) / 10;
    return _this.cmtop = _this.cmradius > 1.5 ? 5 : -20;
  };
})(this));

this.handleResize = (function(_this) {
  return function(globalpos, localpos) {
    radius = localpos.left + 92;
    return _this.update();
  };
})(this);

this.handleRotate = (function(_this) {
  return function(startArc) {
    _this.startArc = startArc;
  };
})(this);

this.reset = (function(_this) {
  return function() {
    radius = default_radius * (opts.scale * 12.83);
    _this.size.style = "";
    return _this.update();
  };
})(this);

this.handleDraw = (function(_this) {
  return function(radians) {
    var pos;
    if ((_this.prevRadians != null) && radians !== _this.prevRadians) {
      if (radians - _this.prevRadians > 4) {
        _this.offset -= Math.PI * 2;
        _this.prevRadians += Math.PI * 2;
      }
      if (radians - _this.prevRadians < -4) {
        _this.offset += Math.PI * 2;
        _this.prevRadians -= Math.PI * 2;
      }
      pos = {
        x: _this.root.offsetLeft + 14,
        y: _this.root.offsetTop + 14,
        r: radius + 2,
        rad1: _this.prevRadians,
        rad2: radians,
        anticlockwise: radians < _this.prevRadians
      };
      if (opts.ondraw) {
        opts.ondraw(pos);
      }
    }
    _this.prevRadians = radians;
    return _this.points.push(_this.prevRadians + _this.offset);
  };
})(this);

this.drawEnd = (function(_this) {
  return function() {
    var pos;
    pos = {
      type: 'arc',
      x: _this.root.offsetLeft + 14,
      y: _this.root.offsetTop + 14,
      r: radius + 2,
      rad1: Math.min.apply(Math, _this.points),
      rad2: Math.max.apply(Math, _this.points)
    };
    if (opts.ondrawend) {
      opts.ondrawend(pos);
    }
    _this.prevRadians = null;
    return _this.points = [];
  };
})(this);
});
    
  