module.exports = 

  class SessionStore

    constructor:(riot,Resource)->
      riot.observable(@)
      @data = {}
      @data_state = false
      @resource = new Resource("/duocms/api/users/me")
      @orgResource = new Resource("/duocms/api/orgs")
      window.addEventListener 'storage', (e)=> @localStorageUpdated(e)

      @on 'session_update',@remove
      @on 'session_sync',@sync
      @on 'session_load',@load
      @on 'session_save',@save
      @on 'org_save',@saveorg
      
    load:->
      @data_state = 'loading'
      @resource.get (user)=>
        @data.user = user
        window.localStorage["user_id"] = +user.id
        # window.location.href = "/system/new-password/" if @data.user.changepassword
        @orgResource.get {id:user.org_id},(org)=>
          @data_state = 'loaded'
          @trigger 'login_hide'
          @data.org = org
          window.centre_id = org.centre_id
          window.session = @data
          @sync()
     
    localStorageUpdated:(e)->
      if e.key == "user_id" && e.newValue? && +e.newValue != +@data.user?.id
        window.location.href = "/vle/"

    sync:->
      return @load() if !@data_state
      if @data_state == 'loaded'
        setTimeout => @trigger 'session_changed',@data 

    saveorg:(org)->
      @orgResource.save @data.org,(org)=>
        @data.org = org
        @trigger 'org_saved'
        @sync()

    save:(user)->
      @resource.save user,(user)=>
        @data.user = user
        @sync()