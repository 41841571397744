
    var riot = require('riot')
    
riot.tag2('assignedmodal', '<modal id="assignedmodal" heading="Assignment" bssize="xl" onclose="{handleClose}" onsave="{handleSave}"> <div class="row clearfix"> <div class="col-sm-12"> <div class="row"> <questionpicker stores="{parent.opts.stores}" assignedid="{parent.opts.params.assigned_id}"></questionpicker> <div class="col-md-12"> <panel name="assignment" heading="Assignment Details"> <assignedmodalform stores="{parent.parent.opts.stores}" assignedid="{parent.parent.opts.params.assigned_id}" removeclip="{true}"></assignedmodalform> </panel> </div> </div> </div> </div> </modal>', 'grid{ margin:-15px 0 5px 0; outline:1px solid #ddd; } .gridheader{ outline:1px solid #ddd; }', '', function(opts) {
this.on('mount', function() {
  opts.stores.on('assignedwork_saved', this.handleClose);
  this.cliplist = [];
  this.assignedwork = null;
  if (!opts.params.assigned_id) {
    opts.params.assigned_id = "new";
  }
  opts.stores.on('assignedwork_changed_one', this.handleAssignedWorkChange);
  if (opts.params.assigned_id === "new") {
    opts.stores.trigger('assignedwork_set', {
      questions: [],
      playlist: {}
    });
  } else if (opts.params.assigned_id.match(/^new/)) {
    opts.params.assigned_id = opts.params.assigned_id.replace(/[^0-9]+/g, '');
  }
  this.tags.modal.show();
  return this.questionTotal = 0;
});

this.on('unmount', function() {
  opts.stores.off('assignedwork_changed_one', this.handleAssignedWorkChange);
  return opts.stores.off('assignedwork_saved', this.handleClose);
});

this.handleAssignedWorkChange = (function(_this) {
  return function(assignedwork) {
    var ref;
    _this.questionTotal = assignedwork != null ? (ref = assignedwork.questions) != null ? ref.length : void 0 : void 0;
    return _this.update();
  };
})(this);

this.handleClose = (function(_this) {
  return function() {
    return opts.page('/assignedwork');
  };
})(this);

this.handleSave = (function(_this) {
  return function() {
    if (_this.questionTotal <= 100) {
      return opts.stores.trigger('assignedwork_save');
    } else {
      return opts.stores.trigger('toaster_add', {
        type: 'warning',
        title: 'Error',
        message: "The number of questions per assignment is limited to 100. Please remove some questions."
      });
    }
  };
})(this);
});
    
  