
    var riot = require('riot')
    
riot.tag2('teachersfeedback', '<panel heading="Feedback"> <div class="btn-toolbar"> <div class="btn-group"> <button onclick="{parent.showRecent}" class="btn btn-sm {parent.mode==\'recent\' ? \'btn-primary\' : \'btn-default\'}">Recent & Unread Messages </button> <button onclick="{parent.showAll}" class="btn btn-sm {parent.mode==\'all\' ? \'btn-primary\' : \'btn-default\'}">All Messages</button> </div> </div><br> <grid columns="{parent.columns}" data="{parent.feedback}" height="370px" onselect="{parent.handleSelect}" onedit="{parent.handleSelect}"> <p if="{!parent.feedback}"> <i class="fa fa-spinner fa-pulse"> </i>Loading Data</p> <p if="{!parent.feedback.length}">You have no student feedback</p> </grid> </panel>', '', '', function(opts) {
this.feedback = null;

this.mode = "recent";

this.columns = [
  {
    field: 'read_at',
    label: '',
    width: '3%',
    tag: "gridunread"
  }, {
    field: 'name',
    label: 'From',
    width: '22%'
  }, {
    field: 'title',
    label: 'Assignment Title',
    width: '20%'
  }, {
    field: 'message',
    label: 'Message',
    width: '27%'
  }, {
    field: 'created_at',
    label: 'Created',
    width: '14%',
    dateformat: 'HH:mm dd/MM/yyyy'
  }, {
    field: 'read_at',
    label: 'Read',
    width: '14%',
    dateformat: 'HH:mm dd/MM/yyyy'
  }
];

this.on('mount', function() {
  opts.stores.on('feedback_all_changed', this.feedbackUpdated);
  return opts.stores.trigger('feedback_all');
});

this.on('unmount', function() {
  return opts.stores.off('feedback_all_changed', this.feedbackUpdated);
});

this.feedbackUpdated = (function(_this) {
  return function(feedback) {
    _this.feedback = feedback;
    return _this.update();
  };
})(this);

this.handleSelect = (function(_this) {
  return function(sel) {
    return window.location = "/vle/classes/_/" + sel.type + "/" + sel.user_id + "/" + sel.assignedwork_id + "/" + sel.question_id;
  };
})(this);

this.showRecent = (function(_this) {
  return function() {
    _this.update({
      mode: "recent"
    });
    return opts.stores.trigger('feedback_all');
  };
})(this);

this.showAll = (function(_this) {
  return function() {
    _this.update({
      mode: "all"
    });
    return opts.stores.trigger('feedback_all', true);
  };
})(this);
});
    
  