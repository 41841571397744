
    var riot = require('riot')
    
riot.tag2('freeforminput', '<div name="answers" hide="{opts.useimg &amp;&amp; opts.showanswer}" class="dianswers"> <div each="{v,k in opts_answers}" style="position:absolute;display:none;" name="dianswer{k}" class="{correct:parent.opts.correct}"> <div show="{v.prefix}" class="inputprefix">{v.prefix}</div> <textarea type="text" if="{v.height&gt;40}" placeholder="{v.placeholder || \'\'}" autocomplete="off" spellcheck="false" name="input" onblur="{parent.onchange}" value="{parent.opts.showanswer ? parent.teacher_answers[k] : parent.answer[k]}" style="width:100%;height:100%;resize:none;padding:2px 8px;display:flex;"></textarea> <input type="text" if="{v.height&lt;=40}" placeholder="{v.placeholder || \'\'}" autocomplete="off" spellcheck="false" name="lineinput" onblur="{parent.onchange}" value="{parent.opts.showanswer ? parent.teacher_answers[k] : parent.answer[k]}" style="width:100%;height:100%;resize:none;padding:2px 8px;display:flex;"> <charpicker input="{v.height&gt;40 ? input : lineinput}" if="{v.height&gt;30 &amp;&amp; v.width&gt;30 &amp;&amp; !pinopen}" stores="{parent.opts.stores}"></charpicker> <div show="{v.suffix}" class="inputsuffix">{v.suffix}</div> </div> </div>', '', '', function(opts) {
var getAnswer, getFirstAnswer;

this.pinopen = false;

this.on('mount', function() {
  this.answer = Array.isArray(opts.value) ? opts.value : typeof opts.value === "string" ? [opts.value] : [];
  console.log(this.answer);
  opts.stores.on("prefs_updated", this.handlePrefs);
  opts.stores.trigger("prefs_sync");
  this.imgloaded = false;
  return setTimeout((function(_this) {
    return function() {
      window.addEventListener('resize', _this.positionAnswers, true);
      _this.img = document.querySelector('.img-question');
      if (_this.img) {
        _this.img.addEventListener('load', _this.imageLoaded);
      }
      _this.positionAnswers();
      return _this.moveToImgPanel();
    };
  })(this));
});

this.on('update', function() {
  var opts_answers, ref;
  opts_answers = JSON.parse(opts.answers);
  if (!this.opts_answers && ((typeof opts !== "undefined" && opts !== null ? (ref = opts.answers) != null ? ref.split : void 0 : void 0) != null)) {
    this.opts_answers = opts_answers.map(function(a) {
      a.text = "";
      return a;
    });
  }
  if (opts.showanswer) {
    this.teacher_answers = opts_answers.map(function(a) {
      return getAnswer(a.text);
    });
  }
  return this.setAnswerColor();
});

this.handlePrefs = (function(_this) {
  return function(prefs) {
    _this.pinopen = prefs.pinned;
    return _this.update();
  };
})(this);

this.setAnswerColor = (function(_this) {
  return function() {
    return _this.opts_answers.forEach(function(answer, k) {
      if (!_this['dianswer' + k]) {
        return;
      }
      return _this['dianswer' + k].style.color = opts.showanswer ? "red" : "black";
    });
  };
})(this);

getFirstAnswer = function(txt) {
  var ref;
  return (ref = txt.match(/"([^"]+)/)) != null ? ref[1] : void 0;
};

getAnswer = function(txt) {
  var lines;
  lines = txt.split(/[\r\n]/g);
  lines = lines.map(function(line) {
    var ref;
    return line != null ? (ref = line.match(/^[^"]?"([^"]+)"/)) != null ? ref[1] : void 0 : void 0;
  }).filter(function(line) {
    return line != null;
  });
  return lines != null ? lines[(lines != null ? lines.length : void 0) - 1] : void 0;
};

this.on('unmount', function() {
  var ref;
  window.removeEventListener('resize', this.positionAnswers);
  opts.stores.off("prefs_updated", this.handlePrefs);
  this.answers.remove();
  if ((ref = document.querySelector('.dianswers')) != null ? ref.remove : void 0) {
    return document.querySelector('.dianswers').remove();
  }
});

this.imageLoaded = (function(_this) {
  return function() {
    _this.img.removeEventListener('load', _this.imageLoaded);
    _this.positionAnswers();
    return _this.moveToImgPanel();
  };
})(this);

this.onchange = (function(_this) {
  return function(e) {
    if (!Array.isArray(_this.answer)) {
      _this.answer = [];
    }
    _this.answer[e.item.k] = e.target.value;
    return opts.onupdate({
      name: opts.id,
      value: _this.answer
    });
  };
})(this);

this.positionAnswers = (function(_this) {
  return function() {
    var offset, ref, ref1;
    if (!((ref = _this.opts_answers) != null ? ref.forEach : void 0) || !((ref1 = _this.img) != null ? ref1.offsetWidth : void 0)) {
      return;
    }
    _this.scale = _this.img.offsetWidth / 570;
    offset = {
      top: _this.img.offsetTop,
      left: _this.img.offsetLeft
    };
    _this.opts_answers.forEach(function(answer, k) {
      if (_this['dianswer' + k]) {
        _this['dianswer' + k].style.top = (answer.y * _this.scale) + offset.top + "px";
        _this['dianswer' + k].style.left = (answer.x * _this.scale) + offset.left + "px";
        _this['dianswer' + k].style.width = answer.width * _this.scale + "px";
        _this['dianswer' + k].style.height = answer.height * _this.scale + "px";
        _this['dianswer' + k].style.display = "block";
        return _this['dianswer' + k].style.color = opts.showanswer ? "red" : "black";
      }
    });
    return _this.imgloaded = true;
  };
})(this);

this.moveToImgPanel = (function(_this) {
  return function() {
    if (_this.opts_answers.length) {
      if (_this.img != null) {
        return _this.img.parentNode.appendChild(_this.answers);
      }
    }
  };
})(this);
});
    
  