
    var riot = require('riot')
    
riot.tag2('awardmodal', '<modal show="{true}" title="{title}" onclose="{handleClose}"> <div class="row"> <div class="col-xs-6"> <div class="award large {parent.type.replace(/-/g,\' \').replace(/year$/,\'\')}"> <p>Awarded for {parent.pos} Highest Marks in the {parent.awardtype} for the {parent.period}s Listed</p> </div> </div> <div class="col-xs-6"> <table class="table table-striped"> <thead> <tr> <th>{parent.period} </th> <th>Marks </th> </tr> </thead> <tbody> <tr each="{parent.data}"> <td> <dateformat date="{new Date(new Date(when)-12*60*60*1000)}" format="{parent.parent.dateformat}"></dateformat> </td> <td>{marks}</td> </tr> </tbody> </table> </div> </div> </modal>', '', '', function(opts) {
this.dateformat = 'dd/MM/yyyy';

this.title = "";

this.handleClose = (function(_this) {
  return function() {
    return _this.tags.modal.hide();
  };
})(this);

this.ucFirst = (function(_this) {
  return function(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
})(this);

this.show = (function(_this) {
  return function(type1, data) {
    var level, period, ref, type;
    _this.type = type1;
    _this.data = data;
    _this.dateformat = _this.type.includes('month') ? 'MMM yyyy' : _this.type.endsWith('year') ? 'yyyy' : 'dd/MM/yyyy';
    ref = _this.type.split("-"), type = ref[0], level = ref[1], period = ref[2];
    _this.awardtype = type === "year" ? "Yeargroup" : _this.ucFirst(type);
    _this.title = _this.awardtype + " " + _this.ucFirst(level) + " Award for the " + _this.ucFirst(period);
    console.log(type, level, period, _this.title);
    _this.period = _this.ucFirst(period);
    if (_this.type.includes("gold")) {
      _this.pos = "1st";
    }
    if (_this.type.includes("silver")) {
      _this.pos = "2nd";
    }
    if (_this.type.includes("bronze")) {
      _this.pos = "3rd";
    }
    _this.tags.modal.show();
    return _this.update();
  };
})(this);
});
    
  