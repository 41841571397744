<script>import api from "duo-kit/src/shared/api.js";
import { videos } from "../stores.js";
import { onMount } from "svelte";
import Progress from "duo-kit/src/shared/progress.svelte";
import Dateformat from "duo-kit/src/shared/dateformat.svelte";
import Search from "duo-kit/src/shared/search.svelte";
import Formrow from "duo-kit/src/shared/formrow.svelte";
import DonutCount from "./donut-count.svelte";
import Tip from "./tip.svelte";
export let userid;
let stats = [],
  user = {},
  sort_by = "clip_id",
  sort_dir = true,
  searchResults = [];
let filters = {
  qualification: "",
  level: "",
  tier: ""
};
let qualifications = [];
let levels = [];
let grades = [];
let topics = [];
let watched = 0;
let answered = 0;
let ques_count = 0;
let acquired = 0;
let mastered = 0;
const last_monday = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 6) % 7)).setHours(0, 0, 0));
const previous_monday = new Date(new Date(last_monday).setDate(last_monday.getDate() - 7));
const next_monday = new Date(new Date(new Date().setDate(new Date().getDate() + (7 - new Date().getDay()) % 7 + 1)).setHours(0, 0, 0));
const year_start = new Date().getMonth() >= 8 ? new Date(new Date().getFullYear(), 8, 1) : new Date(new Date().getFullYear() - 1, 8, 1);
function resetFilters() {
  console.log("resetFilters");
  filters.level = "";
  filters.tier = "";
  filters.topic = "";
}
function updateDefaultQualification(classrooms) {
  let classes = classrooms.filter(c => c.year);
  if (qualifications.length == 1) {
    filters.qualification = qualifications[0];
  }
  if (!classes.length || !$videos || !qualifications.length) return;
  let yr = +classes[0].year;
  let qual = "";
  qualifications.forEach(q => {
    if (yr < 10 && q == "KS3" || (yr == 10 || yr == 11) && q.match(/GCSE/) || yr > 11 && q.match(/AS Level/)) {
      qual = q;
    }
  });
  if (qual) {
    filters.qualification = qual;
  }
}
async function load() {
  if (userid) {
    user = (await api.load({
      url: `/duocms/api/users/${userid}`
    })).data;
    stats = (await api.load({
      url: `/duocms/api/logs?user_id=${userid}&filter=studentProgress`
    })).data;
  } else {
    user = (await api.load({
      url: `/duocms/api/users/me`
    })).data;
    stats = (await api.load({
      url: `/duocms/api/logs?filter=studentProgress`
    })).data;
  }
  let classes = user.classrooms.filter(c => c.qualification);
  if (classes.length) {
    filters.qualification = classes[0].qualification;
    filters.level = classes[0].level || "";
  } else {
    updateDefaultQualification(user.classrooms);
  }
}
function setupFilters(videos) {
  if (!videos.length) return;
  qualifications = [...new Set(videos.map(v => v.qualification))].sort();
  grades = [...new Set(videos.filter(v => v.qualification == filters.qualification).map(v => v.grade))].sort();
  topics = [...new Set(videos.filter(v => v.qualification == filters.qualification).map(v => v.topic))].sort();
}
function modeFromMarks({
  total,
  marks
}) {
  let perc = marks / total * 100;
  if (perc < 25) return "danger";
  if (perc < 90) return "warning";
  return "success";
}
function levelFilter(video) {
  if (!filters.level) return true;
  if (filters.qualification.match(/gcse/i) && filters.level == "Higher" && video.grade >= 3) return true;
  if (video.level == filters.level || video.level.match(filters.level)) return true;
  return false;
}
function timeFilter(dt) {
  if (!filters.time) return true;
  if (!dt) return false;
  if (filters.time == "lastweek") dt > previous_monday && dt < last_monday;
  if (filters.time == "thisweek") return dt > last_monday && dt < next_monday;
  if (filters.time == "year") return dt > year_start;
  let month = filters.time - 1;
  return dt.getMonth() == month;
}
function handleSort(e) {
  if (sort_by != e.target.closest("th").getAttribute("name")) {
    sort_by = e.target.closest("th").getAttribute("name");
    sort_dir = e.target.closest("th").getAttribute("defaultorder") == "true";
  } else {
    sort_dir = !sort_dir;
  }
  console.log("handleSort", {
    sort_by,
    sort_dir
  });
}
function headerStyle(name) {
  if (name == sort_by && sort_dir) return "fa fa-caret-up";
  if (name == sort_by && !sort_dir) return "fa fa-caret-down";
  return "";
}
function filterVideos() {
  if (!$videos.length) return [];
  let statsByVideo = {};
  stats.forEach(stat => statsByVideo[stat.id] = stat);
  let vids_per_qual = {};
  let answers_by_vidid = {};
  let filtered = $videos.filter(v => filters.qualification && v.qualification == filters.qualification && (!filters.grade || v.grade == filters.grade) && (!filters.topic || v.topic == filters.topic) && levelFilter(v)).map(v => {
    let answer = statsByVideo[v.id] || {};
    // counts views / answered per qualification
    if (!vids_per_qual[v.qualification]) vids_per_qual[v.qualification] = 0;
    if (answer.last_attempted || answer.last_watched) vids_per_qual[v.qualification]++;
    // remaps data to include marks, views
    let user_marks = answer.marks ? answer.marks : 0;
    if (user_marks > v.ques_marks) user_marks = v.ques_marks;
    return {
      clip_id: v.clip_id,
      video_id: v.id,
      name: v.name,
      qualification: v.qualification,
      grade: v.grade,
      level: v.level,
      total: v.ques_marks,
      topic: v.topic,
      last_watched: answer.last_watched ? new Date(answer.last_watched) : "",
      last_attempted: answer.last_attempted ? new Date(answer.last_attempted) : "",
      marks: +user_marks,
      ques_count: v.ques_count,
      answered: answer.answered,
      views: answer.views,
      omm_views: answer.omm_views,
      last_omm: answer.last_omm,
      mastered: +v.ques_marks > 0 && +user_marks == +v.ques_marks,
      acquired: +v.ques_marks > 0 && +user_marks == +v.ques_marks || answer.last_watched && answer.standard_correct > 1 && answer.difficult_correct > 1
    };
  }).filter(v => timeFilter(v.last_attempted) || timeFilter(v.last_watched)).sort((a, b) => typeof a[sort_by] == "string" ? sortByString(a, b) : sortByValue(a, b));
  watched = filtered.filter(row => row.last_watched).length;
  answered = filtered.reduce((a, b) => a + (b && b.answered ? +b.answered : 0), 0);
  ques_count = filtered.reduce((a, b) => a + (b ? b.ques_count : 0), 0);
  mastered = filtered.filter(row => row.mastered == true).length;
  acquired = filtered.filter(row => row.acquired == true).length;
  return filtered;
}
function sortByString(a, b) {
  return sort_dir ? ("" + a[sort_by]).localeCompare("" + b[sort_by], {}, {
    usage: "sort",
    sensitivity: "base",
    numeric: true
  }) : ("" + b[sort_by]).localeCompare("" + a[sort_by], {}, {
    usage: "sort",
    sensitivity: "base",
    numeric: true
  });
}
function sortByValue(a, b) {
  return sort_dir ? a[sort_by] - b[sort_by] : b[sort_by] - a[sort_by];
}
videos.loadAll();
load(userid);
$: filtered = filterVideos($videos, stats, filters, sort_by, sort_dir);
$: setupFilters($videos, filters.qualification);</script>

<div class="student-stats">
  <div class="row" style="max-width:90vw;">
    <div class="col-sm-3 col-xs-6">
      <Tip tip="Number of topic videos watched within the selected time period. {watched}/{filtered.length}" placement="bottom" style="position:relative;display:block">
        <DonutCount subtitle="Topics Watched" total={filtered.length} count={watched}>
          <div class="circle"><div class="fa fa-play"></div></div>
        </DonutCount>
      </Tip>
    </div>

    <div class="col-sm-3 col-xs-6">
      <Tip tip="Number of interactive questions answered correctly within the selected time period. {answered}/{ques_count}" placement="bottom" style="position:relative;display:block">
        <DonutCount subtitle="Questions Answered" total={ques_count} count={answered} delay="{1}">
          <div class="circle"><div class="fa fa-question"></div></div>
        </DonutCount>
      </Tip>
    </div>

    <div class="col-sm-3 col-xs-6">
      <Tip tip="A skill/topic is acquired by watching its video and correctly answering 2 of its standard interactive questions as well as 2 of its harder interactive questions. {acquired}/{filtered.length}" placement="bottom" style="position:relative;display:block">
        <DonutCount subtitle="Acquired Skills" total={filtered.length} count={acquired} delay="{2}">
          <div class="circle"><div class="fa fa-check"></div></div>
        </DonutCount>
      </Tip>
    </div>

    <div class="col-sm-3 col-xs-6">
      <Tip tip="A skill/topic is mastered by answering correctly all of its interactive questions.  {mastered}/{filtered.length}" placement="bottom" style="position:relative;display:block">
        <DonutCount subtitle="Mastered Skills" total={filtered.length} count={mastered} delay="{3}">
          <div class="circle"><div class="fa fa-star"></div></div>
        </DonutCount>
      </Tip>
    </div>
  </div>
  <br>

{#if qualifications.length}
  <div class="searchbar">
    <Formrow vertical label="Search By Video Name"><Search bind:data={filtered} bind:filtered={searchResults}/></Formrow>
    <Formrow vertical label="Qualification" type="select" on:change={resetFilters} bind:value={filters.qualification}>
      {#each qualifications as qualification}
        <option value={qualification} selected={qualification==filters.qualification}>{qualification}</option>
      {/each}
    </Formrow>
    
    {#if filters.qualification.match(/gcse/i)}
      <Formrow vertical label="Tier" type="select" bind:value={filters.level}>
        <option value="">All </option>
        <option value="Foundation" selected="{filters.level == 'Foundation'}">Foundation </option>
        {#if filters['qualification'].match(/wjec/i)}
          <option value="Intermediate" selected="{filters.level == 'Intermediate'}"> Intermediate </option>
        {/if}
        <option value="Higher" selected="{filters.level == 'Higher'}">Higher</option>
      </Formrow>
    {/if}

    {#if filters.qualification=="KS3"}
      <Formrow vertical label="Module" type="select" bind:value={filters.level}>
        <option value="">All</option>
        <option value="Emerging">Emerging</option>
        <option value="Expected">Expected</option>
        <option value="Exceeding">Exceeding</option>
        <option value="Excelling">Excelling</option>
      </Formrow>
    {:else if filters.qualification=="Primary"}
      <Formrow vertical label="Year" type="select" bind:value={filters.level}>
        <option value="">All</option>
        <option value="4">Year 4</option>
        <option value="5">Year 5</option>
        <option value="6">Year 6</option>
      </Formrow>
    {:else}
      <Formrow vertical label={filters.qualification.match(/^AS/) ? "Module" : "Grade"} type="select" bind:value={filters.grade}>
        <option value="">All</option>
        {#each grades as grade}
        {#if grade}<option value={grade}>{grade}</option>{/if}
        {/each}
      </Formrow>
    {/if}
    <Formrow vertical label="Topic" type="select" bind:value={filters.topic}>
      <option value="">All</option>
      {#each topics as topic}
        {#if topic}<option value={topic}>{topic}</option>{/if}
      {/each}
    </Formrow>
    <Formrow vertical label="Time Period" type="select" bind:value={filters.time}>
      <option value="">All</option>
      <option value="lastweek"> Last Week</option>
      <option value="thisweek"> This Week</option>
      <option value="year"> Academic Year</option>
      <option value="1"> January</option>
      <option value="2"> February</option>
      <option value="3"> March</option>
      <option value="4"> April</option>
      <option value="5"> May</option>
      <option value="6"> June</option>
      <option value="7"> July</option>
      <option value="8"> August</option>
      <option value="9"> September</option>
      <option value="10"> October</option>
      <option value="11"> November</option>
      <option value="12"> December</option>
    </Formrow>
  </div>
{/if} 

  <div class="table-responsive">
    <table class="table">
      <thead> 
        <tr style="cursor:pointer;"> 
          <th on:click={handleSort} name="clip_id" defaultorder="true"># <span class={headerStyle("clip_id",sort_by,sort_dir)} /></th> 
          <th>Skill</th> 
          <th on:click={handleSort} name="name" defaultorder="true" class="col-xs-4">Video <span class={headerStyle("name",sort_by,sort_dir)} /></th> 
          <th on:click={handleSort} name="last_watched" defaultorder="false"> Last Watched <span class={headerStyle("last_watched",sort_by,sort_dir)} /></th> 
          <th on:click={handleSort} name="views" defaultorder="false" style="text-align:center"> <tip tip="Number of times you have watched this video">Views </tip> <span class={headerStyle("views",sort_by,sort_dir)} /></th> 
          <th on:click={handleSort} name="omm_views" defaultorder="false" style="text-align:center"> <tip tip="Number of times you have watched the One Minute Maths version of this video">OMM </tip> <span class={headerStyle("omm_views",sort_by,sort_dir)} /></th> 
          <th on:click={handleSort} name="marks" defaultorder="false" class="col-xs-2"> Interactive Questions <span class={headerStyle("marks",sort_by,sort_dir)} /></th> 
          <th on:click={handleSort} name="last_attempted" defaultorder="false"> Last Attempted <span class={headerStyle("last_attempted",sort_by,sort_dir)} /></th>
        </tr> 
      </thead>
      <tbody>
        {#each searchResults as item}
          <tr> 
            <td>{item.clip_id} </td> 
            <td>
              {#if item.mastered}
              <tip tip="You have mastered this skill."><span class="fa fa-star circle"> </span> </tip> 
              {:else if item.acquired}
                <tip tip="You have acquired this skill."><span class="fa fa-check circle"> </span> </tip> 
              {/if}
            </td> 
            <td><a href="/vle/browse/{item.video_id}">{item.name} </a></td> 
            <td>{#if item.last_watched}<Dateformat date={item.last_watched} format="H:mm dd/MM/yyyy" locale="en-GB" />{/if}</td> 
            <td style="text-align:center">{item.views || "0"}</td>  
            <td style="text-align:center">{item.omm_views || "0"}</td> 
            <td> 
              <a href="/vle/browse/{item.video_id}/practice" class="question_link" title="view interactive questions">
                <Progress mode={modeFromMarks(item)} max={item.total || 10} value={item.marks}>{#if item.marks}{item.marks} / {item.total}{/if}</Progress>
              </a>
            </td> 
            <td>{#if item.last_attempted}<Dateformat date={item.last_attempted} format="H:mm dd/MM/yyyy" locale="en-GB" />{/if}</td> 
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>

<style>
  .student-stats{
    max-width:calc(100vw - 40px);
  }
  .searchbar{
    background-color: #e7f5ff;
    display:flex;
    flex-wrap:wrap;
    padding:0 10px 10px 10px;
  }
  .searchbar :global(> *){
    flex:1;
    min-width:200px;
  }
  .searchbar :global(.form-row .form-row){
    margin:0
  }
  .table thead{
    background: #3fa9f5;
    color: #fff;
  }

  .fa.circle{
    background:#fec600;
    color:white;
    display:inline-block;
    border-radius:50%;
    line-height:20px;
    min-width:20px;
    text-align:center;
    margin:0 10px;
  }
  .question_link:hover{
    text-decoration:none;
    filter:brightness(1.1);
  }

</style>