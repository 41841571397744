
    var riot = require('riot')
    
riot.tag2('nexttabs', '<yield></yield> <li><a onmousedown="{repeater.bind(this,prev)}" ontouchstart="{repeater.bind(this,prev)}" aria-label="Previous" class="btn {disabled:from==0}"> <span aria-hidden="true">&laquo;</span></a></li> <li each="{v,k in range}" no-reorder class="{active:+parent.opts.active == +v.id,hiddentab:(parent.opts.hiddenids &amp;&amp; parent.opts.hiddenids.includes(v.id))}"> <div if="{v.feedback_added}" style="position:absolute;top:-4px;left:0px;right:8px;text-align:right;z-index:2"> <div riot-style="{\'color:#\'+(v.feedback_unread ? \'fec600\' : \'ccc\')+\';width:20px;font-size:10px\'}" class="fa fa-comment"></div> </div><a onclick="{parent.opts.ontabchange.bind(null,v.id,k+from+1)}" class="btn"><span class="{\'label label-success\':parent.opts.iscorrect(v),\'label label-warning\':parent.opts.hasanswer(v),\'practicecorrect\':parent.opts.practiceCorrect(v)}">{k+from+1}</span></a> </li> <li><a onmousedown="{repeater.bind(this,next)}" ontouchstart="{repeater.bind(this,next)}" aria-label="Next" class="btn rightbutton {disabled:opts.collection.length-from-1 &lt; max}"> <span aria-hidden="true">&raquo;</span></a></li>', '.label.label-success { background-color: #5cb85c; } [riot-tag=nexttabs].nav > li > a.disabled { color: #eee; } [riot-tag=nexttabs].nav li.hiddentab a { position: relative; } [riot-tag=nexttabs].nav li.hiddentab a:after { content: "\\00d7"; position: absolute; top: 0px; right: 5px; color: #f00; font-weight: bold; } @media (max-width: 767px) { [riot-tag=nexttabs].nav > li > a { padding: 10px 5px; min-width: 45px; } } @media (min-width: 768px) { [riot-tag=nexttabs].nav > li > a { padding: 10px 5px; min-width: 45px; } } @media (min-width: 922px) { [riot-tag=nexttabs].nav > li > a { padding: 10px 5px; min-width: 42px; } } @media (min-width: 1200px) { [riot-tag=nexttabs].nav > li > a { padding: 10px 5px; min-width: 56px; } }', '', function(opts) {
this.on('mount', function() {
  this.reset();
  if (opts.colwidth) {
    return window.addEventListener('resize', this.refreshOnResize.bind(this));
  }
});

this.reset = (function(_this) {
  return function() {
    if (!_this.max) {
      _this.max = +opts.max || 10;
    }
    _this.from = 0;
    _this.to = _this.max;
    _this.index = null;
    return _this.update();
  };
})(this);

this.on('unmount', function() {
  if (opts.colwidth) {
    return window.removeEventListener('resize', this.refreshOnResize.bind(this));
  }
});

this.refreshOnResize = (function(_this) {
  return function() {
    var box, rb, ref, rightbutton, space;
    if (_this.lastTimeout) {
      clearTimeout(_this.lastTimeout);
    }
    rightbutton = _this.root.querySelector('.rightbutton');
    if (!rightbutton) {
      return;
    }
    rb = rightbutton.getBoundingClientRect();
    box = _this.root.getBoundingClientRect();
    space = box.right - rb.right;
    if (_this.root.offsetHeight > 70) {
      _this.max--;
      return _this.lastTimeout = setTimeout((function() {
        return _this.update();
      }), 10);
    } else if (space > 60 && ((ref = opts.collection) != null ? ref.length : void 0) > _this.max) {
      _this.max++;
      return _this.lastTimeout = setTimeout((function() {
        return _this.update();
      }), 10);
    }
  };
})(this);

this.on('updated', this.refreshOnResize);

this.on('update', function() {
  var ref, ref1;
  if (!this.max) {
    this.max = +opts.max || 10;
  }
  this.half = Math.ceil(this.max / 2);
  if (opts.collection) {
    this.index = null;
    opts.collection.forEach((function(_this) {
      return function(elem, index) {
        if (+opts.active === +elem.id) {
          return _this.index = index;
        }
      };
    })(this));
    if (this.index > ((ref = opts.collection) != null ? ref.length : void 0) - 1) {
      this.index = ((ref1 = opts.collection) != null ? ref1.length : void 0) - 1;
    }
    if (this.index < 0) {
      this.index = 0;
    }
    this.autoScroll();
    return this.range = opts.collection.slice(this.from, this.to);
  }
});

this.repeater = (function(_this) {
  return function(cback) {
    document.addEventListener("mouseup", _this.stoprepeater);
    document.addEventListener("touchend", _this.stoprepeater);
    cback();
    _this.update();
    return _this.interval = setInterval(function() {
      cback();
      return _this.update();
    }, 200);
  };
})(this);

this.stoprepeater = (function(_this) {
  return function() {
    document.removeEventListener("mouseup", _this.stoprepeater);
    document.removeEventListener("touchend", _this.stoprepeater);
    return clearInterval(_this.interval);
  };
})(this);

this.next = (function(_this) {
  return function() {
    var ref;
    if (_this.to < ((ref = opts.collection) != null ? ref.length : void 0)) {
      _this.setFrom(_this.from + 1);
      return _this.setTo(_this.to + 1);
    }
  };
})(this);

this.prev = (function(_this) {
  return function() {
    if (_this.from > 0) {
      _this.setFrom(_this.from - 1);
      return _this.to = _this.from + _this.max;
    }
  };
})(this);

this.setFrom = (function(_this) {
  return function(val) {
    if (val < 0) {
      return _this.from = 0;
    }
    return _this.from = val;
  };
})(this);

this.setTo = (function(_this) {
  return function(val) {
    var ref, ref1;
    if (val > ((ref = opts.collection) != null ? ref.length : void 0)) {
      return _this.to = (ref1 = opts.collection) != null ? ref1.length : void 0;
    }
    return _this.to = val;
  };
})(this);

this.autoScroll = (function(_this) {
  return function() {
    var ref;
    if (_this.to > ((ref = opts.collection) != null ? ref.length : void 0)) {
      _this.from = 0;
    }
    return _this.setTo(_this.from + _this.max);
  };
})(this);

this.setItem = (function(_this) {
  return function() {
    var elem;
    elem = opts.collection[_this.index];
    return opts.ontabchange(elem.id, _this.index, {
      item: {
        v: elem,
        k: _this.index
      }
    });
  };
})(this);
});
    
  