
    var riot = require('riot')
    
riot.tag2('clippicker2', '<div class="form-horizontal clearfix"> <formselect id="qualification" if="{download}" width1="5" width2="7" onupdate="{handleChange}" default="Please Select" value="{chosen.qualification}" label="Qualification" options="{filters.qualifications}" addonafter="&lt;a title=&quot;download list of clips&quot; class=&quot;fa fa-download&quot; target=&quot;_blank&quot; href=&quot;{download}&quot;&gt;&lt;/a&gt;"></formselect> <formselect id="qualification" if="{!download}" width1="5" width2="7" onupdate="{handleChange}" default="Please Select" value="{chosen.qualification}" label="Qualification" options="{filters.qualifications}"></formselect> <formselect id="level" width1="5" width2="7" if="{labels.level}" onupdate="{handleChange}" default="All" value="{chosen.level}" label="{labels.level}" options="{filters.levels}"></formselect> <formselect id="grade" width1="5" width2="7" if="{labels.grade}" onupdate="{handleChange}" default="All" value="{chosen.grade}" label="{labels.grade}" options="{filters.grades}"></formselect> <formselect id="topic" width1="5" width2="7" if="{labels.topic}" onupdate="{handleChange}" default="All" value="{chosen.topic}" label="{labels.topic}" options="{filters.topics}" class="{&quot;disabled&quot;:chosen.qualification==&quot;AS Level&quot; &amp;&amp; (filters.topics.length == 0 || !chosen.grade)}"></formselect> <forminput width1="5" width2="7" if="{labels.topic}" name="search" onupdate="{handleChange}" label="Search" value="{chosen.search}"> </forminput> <formcheckbox width1="5" width2="7" if="{chosen.qualification==\'GCSE (WJEC)\'}" name="numeracy" onupdate="{handleChange}" label="Only Numeracy" value="{chosen.numeracy}"> </formcheckbox> </div>', 'clippicker2 .input-group-addon { background: transparent; border: 0px; } clippicker2 .disabled { opacity: 0.5; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; filter: alpha(opacity=50); pointer-events: none; display: block; }', '', function(opts) {
var collection, downloads, filters, labels;

filters = {
  qualification: '',
  level: '',
  clip_id: '',
  grade: '',
  topic: '',
  search: '',
  numeracy: false
};

downloads = {
  'GCSE': '/downloads/list_of_clips_v15.pdf',
  'GCSE (Legacy)': '/downloads/list_of_clips_mathswatch_legacy.pdf',
  'GCSE (WJEC)': '/downloads/list_of_clips_wjec_v16.pdf',
  'KS3': '/downloads/list_of_clips_ks3.pdf',
  'Primary': '/downloads/list_of_clips_primary.pdf',
  'IGCSE (Cambridge)': '/downloads/list_of_clips_cambridge_igcse_v1.pdf',
  'IGCSE (Edexcel)': '/downloads/list_of_clips_edexcel_igcse_v1.pdf'
};

this.labels = {};

this.search = '';

this.chosen = {};

labels = require('../../client/labels.js');

collection = require('../libs/collection.coffee');

this.on('mount', function() {
  this.restored = false;
  return opts.stores.on('session_changed', this.sessionUpdated);
});

this.on('unmount', function() {
  return opts.stores.off('session_changed', this.sessionUpdated);
});

this.sessionUpdated = (function(_this) {
  return function(session) {
    var classes, isAvailable, ref, ref1, ref2, ref3, ref4, saved;
    _this.session = session;
    if (!opts.all) {
      return;
    }
    try {
      saved = JSON.parse((ref = window.localStorage) != null ? ref.browseChosen : void 0);
    } catch (error) {
      saved = null;
    }
    if ((((ref1 = _this.session.user) != null ? (ref2 = ref1.classrooms) != null ? ref2.map : void 0 : void 0) != null) && !_this.chosen.qualification && !_this.chosen.level) {
      classes = _this.session.user.classrooms.filter(function(c) {
        return c.qualification;
      });
      isAvailable = (classes != null ? classes.length : void 0) && ((ref3 = (ref4 = _this.filters) != null ? ref4.qualifications.filter(function(q) {
        return q.value === classes[0].qualification;
      }) : void 0) != null ? ref3.length : void 0) > 0;
      if ((classes != null ? classes.length : void 0) && !(saved != null ? saved.qualification : void 0) && isAvailable) {
        _this.handleChange({
          name: "qualification",
          value: classes[0].qualification
        });
        return _this.handleChange({
          name: "level",
          value: classes[0].level
        });
      } else {
        return _this.updateDefaultQualification(_this.session.user.classrooms);
      }
    }
  };
})(this);

this.updateDefaultQualification = (function(_this) {
  return function(classrooms) {
    var classes, qual, ref, ref1, yr;
    classes = classrooms.filter(function(c) {
      return c.year;
    });
    if (!classes.length || !((ref = _this.filters) != null ? ref.qualifications : void 0)) {
      return;
    }
    yr = +classes[0].year;
    qual = "";
    _this.filters.qualifications.forEach(function(q) {
      if ((yr < 10 && q.value === "KS3") || ((yr > 6 || yr < 12) && q.value.match(/GCSE/)) || (yr > 11 && q.value.match(/AS Level/))) {
        return qual = q.value;
      }
    });
    if (!qual) {
      qual = (ref1 = _this.filters.qualifications[0]) != null ? ref1.value : void 0;
    }
    if (qual) {
      return _this.handleChange({
        name: "qualification",
        value: qual
      });
    }
  };
})(this);

this.on('update', (function(_this) {
  return function() {
    if (opts.all && !_this.restored) {
      _this.restored = true;
      return setTimeout(function() {
        var ref;
        if ((ref = window.localStorage) != null ? ref.browseChosen : void 0) {
          _this.chosen = JSON.parse(window.localStorage.browseChosen);
          _this.qualificationChange(_this.chosen.qualification);
          _this.download = downloads[_this.chosen.qualification];
          _this.filterOptions(_this.chosen.qualification);
          if (opts.onfilter) {
            opts.onfilter(_this.filter(_this.chosen));
          }
        } else {
          _this.filterOptions();
        }
        opts.stores.trigger('session_sync');
        return _this.update();
      });
    }
  };
})(this));

this.filterOptions = (function(_this) {
  return function(qualification) {
    var gradefilt, levelfilt, qualfilt, ref, ref1, ref2, ref3, ref4, ref5;
    if (!opts.all) {
      return;
    }
    qualfilt = opts.all.filter(function(row) {
      return row.qualification === qualification;
    });
    levelfilt = ((ref = _this.chosen) != null ? ref.level : void 0) ? qualfilt.filter(function(row) {
      return row.level.match(_this.chosen.level) || (row.grade > 2 && _this.chosen.level === "Higher");
    }) : qualfilt;
    gradefilt = ((ref1 = _this.chosen) != null ? ref1.grade : void 0) ? levelfilt.filter(function(row) {
      return row.grade === _this.chosen.grade;
    }) : levelfilt;
    _this.filters = {};
    _this.filters.qualifications = collection.groupSort(opts.all, 'qualification');
    _this.filters.levels = collection.groupSort(qualfilt, 'level', qualification === 'KS3' || (qualification && qualification.match(/gcse/i)) ? false : true);
    _this.filters.grades = collection.groupSort(levelfilt, 'grade');
    _this.filters.topics = qualification === "AS Level" ? collection.groupSort(gradefilt, 'topic') : collection.groupSort(levelfilt, 'topic');
    if (!((ref2 = _this.chosen) != null ? ref2.level : void 0) || !_this.hasLabel(_this.filters.levels, _this.chosen.level)) {
      _this.chosen.level = "";
    }
    if (!((ref3 = _this.chosen) != null ? ref3.grade : void 0) || !_this.hasLabel(_this.filters.grades, _this.chosen.grade)) {
      _this.chosen.grade = "";
    }
    if (!((ref4 = _this.chosen) != null ? ref4.topic : void 0) || !_this.hasLabel(_this.filters.topics, _this.chosen.topic) || (!((ref5 = _this.chosen) != null ? ref5.grade : void 0) && qualification === "AS Level")) {
      return _this.chosen.topic = "";
    }
  };
})(this);

this.hasLabel = function(collection, label) {
  return collection.filter(function(row) {
    return row.label === label;
  }).length > 0;
};

this.qualificationChange = (function(_this) {
  return function(qualification) {
    _this.labels = {
      level: labels.level[qualification],
      grade: labels.grade[qualification],
      topic: labels.topic[qualification]
    };
    return _this.update();
  };
})(this);

this.handleChange = (function(_this) {
  return function(row) {
    if (row.name === 'qualification') {
      _this.qualificationChange(row.value);
      _this.chosen = {
        qualification: '',
        level: '',
        clip_id: '',
        grade: '',
        topic: '',
        search: '',
        numeracy: false
      };
      _this.tags.search.root.querySelector('input').value = '';
    }
    if (row.name == null) {
      return;
    }
    _this.chosen[row.name] = row.value;
    _this.download = downloads[_this.chosen.qualification];
    if (window.localStorage) {
      window.localStorage.browseChosen = JSON.stringify(_this.chosen);
    }
    _this.filterOptions(_this.chosen.qualification);
    if (opts.onfilter) {
      opts.onfilter(_this.filter(_this.chosen));
    }
    return _this.update();
  };
})(this);

this.filter_by_qualification = function(row) {
  if (this.chosen.qualification === row.qualification) {
    return true;
  }
  return false;
};

this.filter_by_level = function(row) {
  var ref;
  if (!this.chosen.level && !this.chosen.grade) {
    return true;
  }
  if (this.chosen.level === "Higher" && this.chosen.qualification === "GCSE" && !this.chosen.grade && row.grade > 2) {
    return true;
  }
  if ((((ref = row.level) != null ? ref.match(this.chosen.level) : void 0) && !this.chosen.grade) || this.chosen.grade === row.grade) {
    return true;
  }
  return false;
};

this.filter_by_topic = function(row) {
  if (!this.chosen.topic) {
    return true;
  }
  if (this.chosen.topic === row.topic) {
    return true;
  }
};

this.filter_by_numeracy = function(row) {
  if (!this.chosen.numeracy) {
    return true;
  }
  if (+row.numeracy === +this.chosen.numeracy) {
    return true;
  }
  return false;
};

this.filter = (function(_this) {
  return function(chosen) {
    var data, filt1, filtered, reg, str;
    data = opts.all.slice();
    if (!chosen || !chosen.qualification) {
      return _this.filtered = [];
    }
    filtered = [];
    if (chosen.search) {
      str = _this.escapeRegExp(chosen.search.replace(/ ?clip ?/i, ''));
      reg = new RegExp(str.split(' ').join(".+"), "ig");
    }
    filt1 = data.filter(function(row) {
      var ref;
      return row.clip_id.match(reg) || row.name.match(reg) || ((ref = row.description) != null ? ref.match(reg) : void 0);
    });
    return filt1.filter(function(row) {
      return _this.filter_by_qualification(row) && _this.filter_by_level(row) && _this.filter_by_topic(row) && _this.filter_by_numeracy(row);
    });
  };
})(this);

this.escapeRegExp = (function(_this) {
  return function(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  };
})(this);
});
    
  