
    var riot = require('riot')
    
riot.tag2('sub-reminder', '<alert bsstyle="danger" if="{daysLeft&lt;20 &amp;&amp; showAlert}"> <p>Your subscription expires in <strong>{parent.daysLeft} {parent.daysLeft>1?⁗days⁗:⁗day⁗}</strong>, <a href="/vle/renew" class="alert-link">please click here to renew and avoid loss of service.</a></p> </alert> <alert bsstyle="warning" if="{daysLeft&lt;=30 &amp;&amp; daysLeft&gt;=20 &amp;&amp; showAlert}"> <p>Your subscription expires in {parent.daysLeft} days,<a href="/vle/renew"> please click here to renew.</a></p> </alert> <div style="position:absolute;top:-29px;right:28px;z-index:1001;color:#fff;font-size:11px;" if="{daysLeft}">{daysLeft} {daysLeft>1?⁗days⁗:⁗day⁗} until renewal</div>', 'sub-reminder{ position:relative; display:block }', '', function(opts) {
this.status = '';

this.on('mount', function() {
  opts.stores.on('session_changed', this.sessionUpdated);
  return opts.stores.trigger('session_sync');
});

this.on('unmount', function() {
  opts.stores.off('session_changed', this.sessionUpdated);
  return opts.stores.off('user_saved', this.userUpdated);
});

this.sessionUpdated = (function(_this) {
  return function(data) {
    var groups, ref, showAlert;
    groups = ((ref = data.user) != null ? ref.groups : void 0) ? data.user.groups.map(function(group) {
      return group.name;
    }) : [];
    if (data.org) {
      _this.daysLeft = Math.round((data.org.subscription_end - new Date()) / 1000 / 60 / 60 / 24);
      _this.org = data.org;
      showAlert = false;
      if (groups.indexOf('School Admins') > -1 || groups.indexOf('Teachers') > -1) {
        showAlert = true;
      }
    }
    return _this.update({
      user: data.user,
      groups: groups,
      showAlert: showAlert
    });
  };
})(this);
});
    
  