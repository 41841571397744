module.exports = 

  class AnswerStore

    constructor:(riot,Resource,@stores)->
      riot.observable(@)
      @data = null
      @cache = []
      @vidcache = []
      @answerCounts = null
      @attempts = []
      @filtered = []
      @data_state = []
      @saving = false
      @tools = rulerShow:false,compassShow:false,protractorShow:false

      @resource = new Resource("/duocms/api/answers")
      @on 'answer_sync',@sync
      @on 'answer_get',@get
      @on 'answer_get_assignment',@get_assignment
      @on 'answer_save',@save
      @on 'answer_count_load',@loadAnswerCount
      @on 'answer_count_sync',@answerCountSync
      @on 'answer_toolchange',@toolChange
      @on 'answer_toolsync',@toolSync
      @on 'answer_setscale',@setScale
      @on 'answer_process',@processAnswer
      @on 'answer_trigger_drawing_undo',@undoing
      @on 'answer_trigger_drawing_updated',@drawingUpdated
      @on 'answer_trigger_drawing_clear',@drawingClear

    load:->
      #@data_state[answer_id] = 'loading'
      #if @vidcache[answer_id]?
      #  @data = @vidcache[answer_id]
      #  @sync(answer_id)
      #@resource.get {answer_id:answer_id},(@data)=>
      #  console.log 'answer loaded',@data
      #  answer_id = @data[0].answer_id
      #  @vidcache[answer_id] = @data
      #  @sync(answer_id)

    get:(question_id,assignedwork_id)-> #get single answer by question_id,assignedwork_id
      return if !question_id || !assignedwork_id
      cacheid = question_id+"-"+assignedwork_id
      if @cache[cacheid]?
        @data = @cache[cacheid]
        setTimeout => @trigger 'answer_one',@data
      @resource.get {question_id:question_id,assignedwork_id:assignedwork_id},(@data)=>
        @cache[cacheid] = @data[0]#just use first match
        setTimeout => @trigger 'answer_one',@data 

    get_assignment:(assignedwork_id)-> 
      cacheid = "assignment_"+assignedwork_id
      if @cache[cacheid]?
        @data = @cache[cacheid]
        setTimeout => @trigger 'answer_assignment',@data
      @resource.get {assignedwork_id:assignedwork_id},(@data)=>
        @data.forEach (q)=> 
          if q.answer?.forEach?
            q.answer.forEach (a)=>
              a.prefix = @escapeBraces(a.prefix) if a?.prefix
              a.text = @escapeBraces(a.text) if a?.text
              a.suffix = @escapeBraces(a.suffix) if a?.suffix
        @cache[cacheid] = @data#just use first match
        setTimeout => @trigger 'answer_assignment',@data 

    sync:(answer_id)->
      return if !answer_id
      return @load(answer_id) if !@data_state[answer_id]
      return if !@data
      setTimeout => @trigger 'answer_changed',@data

    
    save:(answer)->
      # prevent answer being saved twice here!!!
      return if @saving == true
      @saving = true
      @savingTimeout = setTimeout => 
        @saving = false
      ,2000
      @resource.save answer,(@answer)=>
        if @answer?.question_id && @answer?.assignedwork_id
          cacheid = @answer.question_id+"-"+@answer.assignedwork_id
          @cache[cacheid] = @answer 
        @trigger 'answer_saved',@answer #closes modal
        @saving = false
        clearTimeout(@savingTimeout) if @savingTimeout
        @sync()

    answerCountSync:->
      if @answerCounts
        return @stores.trigger 'answer_count_changed',@answerCounts
      else
        @loadAnswerCount()

    loadAnswerCount:->
      @resource.get {answercount:true},(rows)=>
        @answerCounts = rows
        setTimeout => @stores.trigger 'answer_count_changed',@answerCounts

    toolChange:(tools)->
      @tools = tools
      @toolSync()

    toolSync:->
      @trigger 'answer_tools_updated',@tools

    escapeBraces:(str)->
      return str.replace(/(^|[^\\])((\\\\)*\{)/g,'$1\\$2') if str && typeof str == "string"
      return str

    undoing:->
      @trigger 'answer_drawing_undo'

    drawingUpdated:(data)->
      @trigger 'answer_drawing_updated',data
    
    drawingClear:->
      @trigger 'answer_drawing_clear'
    
    setScale:(scale)->
      # if(scale!=@scale) # disabled due to drawing tool scale not updating between components #345
      @trigger 'answer_scale_update',scale
      @scale = scale