<script>import { createEventDispatcher } from 'svelte';
import Button from "./button.svelte";
import Formrow from "./formrow.svelte";
import SearchIcon from "../icons/search.svelte";
import CancelIcon from "../icons/cancel.svelte";
export let data;
export let value = "";
export let filtered;
export let placeholder = "Search";
const dispatch = createEventDispatcher();
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
const search = (query = "", data = []) => {
  if (query) {
    let esc_query = escapeRegExp(query);
    let searches = new RegExp("^(?=.*" + esc_query.replace(/\s/, ")(?=.*") + ").*$", "i");
    return data.filter(item => JSON.stringify(item).match(searches));
  } else if (data) {
    return data.slice();
  }
};
$: filtered = search(value, data);</script>

<Formrow type="text" bind:value={value} {placeholder}>
  <span slot="suffix">
    <Button on:click={(e)=> value=""} mode={value ? 'warning' : 'primary'}>{#if value}<CancelIcon />{:else}<SearchIcon />{/if}</Button>
  </span>
</Formrow>