
    var riot = require('riot')
    
riot.tag2('barchart', '<canvas name="canv" height="{opts.height || 400}" width="{opts.width || 400}"></canvas>', 'barchart{display:block;}', '', function(opts) {
var Chart;

Chart = require('chart.js');

this.on('mount', function() {
  var ctx;
  Chart.defaults.global.responsive = true;
  ctx = this.canv.getContext("2d");
  return this.chart = new Chart(ctx).Bar(opts.data, opts.options);
});

this.on('update', (function(_this) {
  return function() {
    if (_this.chart && opts.data.datasets) {
      return _this.chart.initialize(opts.data, opts.options);
    }
  };
})(this));
});
    
  