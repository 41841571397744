 module.exports = 

  updateFromId:(collection,data)->
    idx = @indexFromId(collection,data.id)
    if idx > -1
      collection[idx] = data
    else
      collection.push(data)

  indexFromId:(collection,id)->
    for row,key in collection
      return key if id == row.id
    return -1

  removeFromId:(collection,id) ->
    idx = @indexFromId(collection,id)
    if idx > -1
      collection.splice(idx, 1)
    return collection