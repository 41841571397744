
    var riot = require('riot')
    
riot.tag2('handle', '<div onclick="{draw}" class="dragger"></div>', 'handle { position:absolute; cursor:pointer; z-index:2; } handle .dragger{ position:absolute; top:-20px; left:-20px; border-radius:50%; width:40px; height:40px; background:rgba(0,0,0,0.1) } @media (hover: none) and (pointer: coarse) { handle .dragger{ top:15px; border-radius:0 20px 20px 20px; transform:rotate(45deg); background:rgba(0,0,0,0.2) } }', '', function(opts) {
var move;

move = require('../libs/move.coffee');

this.on('mount', (function(_this) {
  return function() {
    return new move(_this.root, _this.root, {
      dragging: function(pos) {
        var snaps;
        if (opts.snaps) {
          snaps = opts.snaps.filter((function(_this) {
            return function(snap) {
              return snap.point !== opts.point;
            };
          })(this));
          snaps.forEach((function(_this) {
            return function(snap) {
              var diff;
              diff = Math.abs(pos.left - snap.left) + Math.abs(pos.top - snap.top);
              if (diff < 10) {
                pos.left = snap.left;
                return pos.top = snap.top;
              }
            };
          })(this));
        }
        if (opts.update) {
          return opts.update({
            point: opts.point,
            x: pos.left,
            y: pos.top
          });
        }
      }
    });
  };
})(this));
});
    
  