module.exports = 

  class LoginStore

    constructor:(riot,@request,@utils,@stores)->
      riot.observable(@)
      @data = 
        show:false
        afterLogin:[]
        username:''
        password:''

      @on 'login_hide',@hide
      @on 'login_attempt',@attempt
      @on 'login_sync',@sync
      @on 'login_autherror',@authError
      @on 'logout',@logout
      @on 'login_reset',@reset

    sync:->
      @trigger 'login_changed',@data

    authError:(callback)->
      @data.afterLogin.push(callback) if callback
      @data.show = true
      @sync()

    hide:->
      @data.show = false
      @sync()
   
    attempt:(user)->
      credentials = {username:user.username,password:user.password}
      @request.post('/duocms/api/login').send(credentials).csrf().end (err,res)=>
        if(!err && res?.status==200)
          @data.user = res.body.data
          @data.loggedin = true
          centre_id = @data.user.username.replace(/^[^@]+@/,'')
          if centre_id
            window.centre_id = centre_id
            # ga('send', {hitType: 'event', eventCategory: 'account', eventAction: 'login', eventLabel: centre_id});
            window.gtag('event','login',{centre_id}) if window.gtag
          @data.afterLogin.forEach (callback)-> 
            callback() if typeof callback == "function"
          @data.afterLogin.length = 0 
          @trigger 'login_success' #closes modal
          @hide()
        else if(res.clientError || err)
          if res.body?.message?.match(/CSRF/)
            @updateCSRF()
            @trigger "login_attempt",credentials
            return
            # res.body.message = "Sorry, your session has expired, please try logging in again after the page has refreshed."
            # setTimeout (-> window.location.reload()),3500
          @stores.trigger 'toaster_add',
            title:'Error',
            message:res.body?.message || err
            type:'warning'
          @data.show = true
          @sync()

    reset:(user)->
      console.log("reset",user)
      credentials = {username:user.username}
      @request.post('/duocms/api/forgot_password').send(credentials).csrf().end (err,res)=>
        if(!err && res?.status==200)
          @stores.trigger 'toaster_add',
            title:'Information',
            message:res.body?.data?.message || "Please check your email"
            type:'info'
          @trigger 'reset_success' #closes reset modal
        else if(res.clientError || err)
          if res.body?.message?.match(/CSRF/)
            @updateCSRF()
            @trigger "login_reset",credentials # retry
            return
          @stores.trigger 'toaster_add',
            title:'Error',
            message:res.body?.message || err
            type:'warning'

    logout:(type)->
      if type=="wonde"
        window.localStorage["user_id"] = 0
        window.location.href = "https://edu.wonde.com/sso/logout?client_id=670243"
      else if type=="mylogin"
        window.localStorage["user_id"] = 0
        window.location.href = "https://app.mylogin.com/oauth/logout?client_id=ac62f4f3-b4f1-491b-ba5e-d9439e915909"
      else
        @request.get '/duocms/api/logout', (err,res)=>
          window.localStorage["user_id"] = 0
          window.location.href = "/vle/"

    onUpdate:(key,value)->
      @data[key] = value
      @trigger(@data)

    updateCSRF:->
      csrfcookie = document.cookie.match(/_csrf=([^ ;]+)/)
      if csrfcookie && csrfcookie.length > 0
        window.csrf = csrfcookie[1]  