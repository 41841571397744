
    var riot = require('riot')
    
riot.tag2('nextprev', '<div if="{opts.label}" onclick="{set}" class="title pointer">{opts.label}</div> <div class="btn-toolbar {active:active}"><span onclick="{prev}" onmousedown="{repeater.bind(this,prev)}" ontouchstart="{repeater.bind(this,prev)}" class="btn fa fa-caret-left {disabled:+start==1}"></span><span onclick="{set}" class="btn range">{start} of {opts.total}</span><span onclick="{next}" onmousedown="{repeater.bind(this,next)}" ontouchstart="{repeater.bind(this,next)}" class="btn fa fa-caret-right {disabled:+start==+opts.total}"></span></div>', '', '', function(opts) {
this.offset = 0;

this.start = 1;

this.on('mount', function() {
  return this.idx = 0;
});

this.on('update', function() {
  var start;
  this.offset = opts.offset != null ? opts.offset : 0;
  start = opts.index + 1 - this.offset;
  if (start > 0 && start <= opts.total) {
    this.start = start;
    this.active = true;
  } else {
    this.idx = 0;
    this.active = false;
  }
  if (this.idx >= opts.total) {
    this.idx = opts.total - 1;
  }
  if (this.start > opts.total) {
    this.start = opts.total;
  }
  if (start <= 0) {
    return this.start = 1;
  }
});

this.prev = (function(_this) {
  return function() {
    if (_this.idx > 0) {
      _this.idx--;
    }
    return _this.set();
  };
})(this);

this.set = (function(_this) {
  return function() {
    if (opts.onchange) {
      opts.onchange(_this.idx + _this.offset);
    }
    return _this.update();
  };
})(this);

this.repeater = (function(_this) {
  return function(cback) {
    document.addEventListener("mouseup", _this.stoprepeater);
    document.addEventListener("touchend", _this.stoprepeater);
    return _this.interval = setInterval(function() {
      cback();
      return _this.update();
    }, 200);
  };
})(this);

this.stoprepeater = (function(_this) {
  return function() {
    document.removeEventListener("mouseup", _this.stoprepeater);
    document.removeEventListener("touchend", _this.stoprepeater);
    return clearInterval(_this.interval);
  };
})(this);

this.next = (function(_this) {
  return function() {
    if (_this.idx < opts.total - 1) {
      _this.idx++;
    }
    return _this.set();
  };
})(this);
});
    
  