
    var riot = require('riot')
    
riot.tag2('toaster', '<div each="{slices}" class="toast-slice toast-{type} {transition}"><a onclick="{parent.close}" class="toast-close">&times;</a> <h3>{title}</h3> <p class="pre">{message}</p> </div>', '', '', function(opts) {
this.handleChange = (function(_this) {
  return function(slices) {
    _this.slices = slices;
    return _this.update();
  };
})(this);

this.on('mount', function() {
  return opts.stores.on('toaster_changed', (function(_this) {
    return function(slices) {
      _this.slices = slices;
      return _this.update();
    };
  })(this));
});

this.on('unmount', function() {
  return opts.stores.off('toaster_changed');
});

this.close = (function(_this) {
  return function(event) {
    return opts.stores.trigger('toaster_remove', event.item);
  };
})(this);
});
    
  