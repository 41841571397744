
    var riot = require('riot')
    
riot.tag2('menu', '<li if="{label}" each="{opts.routes}" class="{\'active\':isActive(path)}"><a href="{path}" onclick="{goto}">{label}</a></li><yield></yield>', '', '', function(opts) {
this.isActive = (function(_this) {
  return function(path) {
    var wlp;
    wlp = window.location.pathname;
    return (wlp.substr(0, path.length) === path && path !== "/vle/") || path === wlp;
  };
})(this);

this.goto = (function(_this) {
  return function(e) {
    if (opts.clicked) {
      opts.clicked(e);
    }
    setTimeout(function() {
      return _this.update();
    });
    return true;
  };
})(this);
});
    
  