
    var riot = require('riot')
    
riot.tag2('importmodal', '<modal id="importsmodal" onclose="{handleClose}" onsave="{handleSave}" savetext="Request Class Import" title="New Class From MIS"> <form class="form-horizontal"> <table class="table"> <thead> <tr> <td>Class Name </td> <td>Class Year </td> </tr> </thead> <tbody> <tr each="{classroom,idx in parent.classes}"> <td class="col-xs-8"> <input required type="text" oninput="{parent.parent.handleChange.bind(this,idx,\'name\')}" value="{classroom.name}" class="form-control"> </td> <td class="col-xs-3"> <input required type="text" oninput="{parent.parent.handleChange.bind(this,idx,\'year\')}" value="{classroom.year}" class="form-control"> </td> <td class="col-xs-1"> <button onclick="{parent.parent.removeClass.bind(this,idx)}" class="btn btn-danger"> <div class="fa fa-trash"></div> </button> </td> </tr> </tbody> <tfoot> <tr> <td colspan="3"> <button onclick="{parent.addClass}" class="btn btn-default">Add Class</button> </td> </tr> </tfoot> </table> </form> <div class="alert alert-info"> Enter your class details above as they appear in your MIS and we will process your class creation as part of the next import from Wonde. </div> </modal>', '', '', function(opts) {
var request;

request = require('superagent');

this.data = {};

this.classes = [
  {
    name: "",
    year: ""
  }
];

this.on('mount', function() {
  this.classes = [
    {
      name: "",
      year: ""
    }
  ];
  opts.stores.on('session_changed', this.sessionUpdated);
  return opts.stores.trigger('session_sync');
});

this.show = (function(_this) {
  return function() {
    return _this.tags.modal.show();
  };
})(this);

this.handleClose = (function(_this) {
  return function() {
    return _this.tags.modal.hide();
  };
})(this);

this.addClass = (function(_this) {
  return function() {
    _this.classes.push({
      name: "",
      year: ""
    });
    return _this.update();
  };
})(this);

this.sessionUpdated = (function(_this) {
  return function(session) {
    _this.session = session;
    _this.data.email = _this.session.user.email;
    _this.data.orgname = _this.session.org.name;
    return _this.update();
  };
})(this);

this.handleSave = (function(_this) {
  return function() {
    var data;
    _this.tags.modal.root.querySelector("form").reportValidity();
    if (!_this.tags.modal.root.querySelector("form").checkValidity()) {
      return;
    }
    data = {
      subject: "Class Import Request",
      message: "Please import the following classes from wonde \n\n"
    };
    data.message += _this.classes.map(function(c) {
      return c.name + " - yr " + c.year;
    }).join("\n");
    return request.post("/duocms/api/contact").send(data).csrf().end(function(err, res) {
      if (+res.status === 200) {
        opts.stores.trigger('toaster_add', {
          title: 'Success',
          type: 'success',
          message: res.body.data.message
        });
        _this.classes = [
          {
            name: "",
            year: ""
          }
        ];
        _this.handleClose();
      } else {
        opts.stores.trigger('toaster_add', {
          title: 'Error',
          type: 'error',
          message: res.body.message
        });
        if (+res.status === 401) {
          document.querySelector('login')._tag.show();
        }
      }
      return _this.update();
    });
  };
})(this);

this.handleSaved = (function(_this) {
  return function() {
    return _this.handleClose();
  };
})(this);

this.handleChange = (function(_this) {
  return function(index, name, e) {
    _this.classes[index][name] = e.target.value;
    console.log(_this.classes);
    return _this.update();
  };
})(this);

this.removeClass = (function(_this) {
  return function(index, e) {
    _this.classes = _this.classes.filter(function(row, idx) {
      return idx !== index;
    });
    if (!_this.classes.length) {
      _this.classes = [
        {
          name: "",
          year: ""
        }
      ];
    }
    return _this.update();
  };
})(this);
});
    
  