<script>import Formrow from "duo-kit/src/shared/formrow.svelte";
import Dropdown from "duo-kit/src/shared/dropdown.svelte";
import Button from "duo-kit/src/shared/button.svelte";
import IconClose from "duo-kit/src/icons/cancel.svelte";
import IconTick from "duo-kit/src/icons/tick.svelte";
import { createEventDispatcher } from "svelte";
export let value,
  options = [],
  open = false,
  placeholder = "",
  disabled,
  required;
let optionsById = {};
let multiform;
let dispatch = createEventDispatcher();
function toggle({
  option,
  event
}) {
  if (event) event.stopPropagation();
  console.log(value);
  if (!value) value = [];
  if (value.includes(option)) {
    value = value.filter(item => item != option);
    dispatch("change", value);
  } else {
    value = value.concat(option);
    dispatch("change", value);
  }
}
function getOptionsById() {
  optionsById = {};
  options.forEach(opt => optionsById[opt.value] = opt.label);
}
$: getOptionsById(options);
$: open == false && dispatch("change", value);</script>

<div class="multiselect" class:disabled={disabled} >
  <Dropdown bind:open>
    <Formrow {...$$restProps} value="">
      <div class="inputwrap">
        <div class="form-control">
          <input type="text" value={value} {required} class="hiddeninput" />
   
            <div class="tags">
              {#if value && value.length}
                {#each value as item}
                  <Button mode="primary" size="sm" on:click={(e)=> toggle({event:e,option:item})} >{optionsById[item]} <IconClose /></Button>
                {/each}
              {/if}
              {#if !value || !value.length}
                <div class="placeholder">{placeholder}</div>
              {/if}
            </div>
       
        </div>
      </div>
    </Formrow>
    <div slot="menu">
      {#each options as option}
        <button type="button" on:click={(e)=>toggle({event:e,option:option.value})} ><span class="tick" class:visible={value && value.includes(option.value)}><IconTick /> </span> {option.label}</button>
      {/each}
    </div>
  </Dropdown>
</div>

<style>
  .multiselect :global(.dropdown){
    display:block;
  }
  .multiselect :global(.dropdown-menu){
    width:auto;
    left:5px;
    right:5px;
    max-height:200px;
  }
  .tick{
    visibility: hidden;
    display:inline-block;
  }
  .tick.visible{
    visibility: visible;
  }

  .tags{
    --s-button-margins:0;
    display:flex;
    gap:4px;
    flex-wrap: wrap;
  }
  .placeholder{
    line-height:26px;
    padding:0 5px;
    font-weight: normal;
    color:#777;
  }

  .form-control{
    padding:3px 20px 3px 3px;
    height:auto;
    min-height:34px;
    background:white url("data:image/svg+xml;utf8,<svg fill='currentColor' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z' fill='grey' /><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat right 50%
  }

  .disabled{
    pointer-events: none;
    opacity:0.7;
  }
  .hiddeninput{
    position:absolute;
    opacity:0
  }

</style>

