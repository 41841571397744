
<script>import IconLink from "duo-kit/src/icons/link.svelte";
import Button from "duo-kit/src/shared/button.svelte";
import IconUnlink from "duo-kit/src/icons/unlink.svelte";
import { createEventDispatcher } from "svelte";
export let checked, title;
let dispatch = createEventDispatcher();
function toggle() {
  checked = !checked;
  dispatch("change", checked);
}</script>

<Button on:click={toggle} title={title} >
  {#if checked}<IconLink />{:else}<span style="opacity:0.5"><IconUnlink /></span>{/if}
</Button>

