
    var riot = require('riot')
    
riot.tag2('modal', '<div if="{visible}" style="display:block" class="{modal:true,fade:true,in:move}"> <div class="modal-dialog {opts.bssize ? \'modal-\'+opts.bssize : \'\'}"> <div class="modal-content"> <div if="{opts.title || opts.heading}" class="modal-header"> <button if="{opts.onclose}" type="button" onclick="{close}" data-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">×</span></button> <h4 class="modal-title">{opts.title || opts.heading}</h4> </div> <div class="modal-body"><yield></yield></div> <div if="{opts.onclose || opts.onsave}" class="modal-footer"> <button type="button" if="{opts.onclose}" onclick="{close}" class="btn btn-default btnclose">{opts.closetext ||\'Close\'}</button> <button type="button" if="{showsave}" onclick="{save}" class="btn btn-primary btnsave">{opts.savetext ||\'Save changes\'}</button> </div> </div> </div> </div> <div show="{visible &amp;&amp; opts.backdrop}" class="{modal-backdrop:true,fade:true,in:move}"></div>', '', '', function(opts) {

/*
Enhancements
1 - esc key to close
2 - background click to close
 */
this.on('mount', (function(_this) {
  return function() {
    if (opts.esckey) {
      return document.addEventListener('keydown', _this.keydown);
    }
  };
})(this));

this.on('unmount', (function(_this) {
  return function() {
    document.removeEventListener('click', _this.click);
    if (opts.esckey) {
      document.removeEventListener('keydown', _this.keydown);
    }
    return _this.hide();
  };
})(this));

this.on('update', function() {
  return this.showsave = (opts.onsave != null) && typeof opts.onsave === "function" ? true : false;
});

this.keydown = (function(_this) {
  return function(e) {
    if (e.which === 27) {
      return _this.close();
    }
  };
})(this);

this.click = (function(_this) {
  return function(e) {
    if (e.target.className.match(/modal[^-]/)) {
      return _this.close();
    }
  };
})(this);

this.show = (function(_this) {
  return function() {
    _this.parentNode = _this.root.parentNode;
    document.body.appendChild(_this.root);
    _this.visible = true;
    _this.update();
    document.body.className += " modal-open";
    return setTimeout(function() {
      _this.move = true;
      return _this.update();
    }, 250);
  };
})(this);

this.save = (function(_this) {
  return function() {
    if ((opts.onsave != null) && typeof opts.onsave === "function") {
      return opts.onsave();
    }
  };
})(this);

this.close = (function(_this) {
  return function() {
    return _this.hide(function() {
      if ((opts.onclose != null) && typeof opts.onclose === "function") {
        return opts.onclose();
      }
    });
  };
})(this);

this.hide = (function(_this) {
  return function(cback) {
    var ref, ref1;
    if (document.querySelector('.modal')) {
      document.querySelector('.modal').removeEventListener('click', _this.click);
    }
    _this.move = false;
    _this.update();
    setTimeout(function() {
      _this.visible = false;
      _this.update();
      if (_this.parentNode) {
        _this.parentNode.appendChild(_this.root);
      }
      if (typeof cback === 'function') {
        return cback();
      }
    }, 250);
    if (((ref = document.body) != null ? (ref1 = ref.className) != null ? ref1.replace : void 0 : void 0) != null) {
      return document.body.className = document.body.className.replace(/ ?modal-open ?/, '');
    }
  };
})(this);
});
    
  