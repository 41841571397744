module.exports = 

  class toasterStore

    constructor:(riot)->
      riot.observable(@)
      @data = slices:[]
      @on 'toaster_add',@add
      @on 'toaster_remove',@remove

    sync:->
      @trigger 'toaster_changed',@data.slices

    add:(slice)->
      slice.transition = "toast-in"
      slice.title = "" if !slice.title?
      slice.message = "" if !slice.message?
      slice.type = "info" if !slice.type?
      slice.timeOut = 5 if !slice.timeOut?
      # add slide to top
      @data.slices.unshift slice
      @sync()
      if(slice.timeOut)
        setTimeout =>
          lastSlice = @data.slices[(@data.slices.length-1)]
          @remove(lastSlice)
        ,(slice.timeOut*1000)


    remove:(item,timeOut=500)=>
      index = @data.slices.indexOf(item)
      return if index < 0
      # hide slide
      @data.slices[index].transition = 'toast-out'
      @sync()
      do (item)=>
        setTimeout =>
          # remove form dom
          index = @data.slices.indexOf(item)
          @data.slices.splice(index,1)
          @sync()
        ,timeOut
      
