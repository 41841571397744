
    var riot = require('riot')
     require('riot-kit/src/form')
riot.tag2('change-password', '<form onsubmit="{submit}" class="form-horizontal"> <p>Please enter a new password and confirm by retyping it.</p> <forminput name="password" label="Password" onupdate="{handleUpdate}" type="password" required width1="2" width2="10"></forminput> <forminput name="passwordConfirm" label="Retype Password" onupdate="{handleUpdate}" type="password" required min="8" width1="2" width2="10"></forminput> <div class="col-xs-offset-2 col-xs-10"> <div class="col-xs-12"> <div if="{error}" class="alert alert-danger">{errorMessage.message}</div> <div if="{success}" class="alert alert-success">{successMessage}</div> <button class="btn btn-primary">Change Password</button> </div> </div> </form>', '', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource(opts.src || '/duocms/api/users/me', true);

this.on("mount", function() {
  if (window.location.search.match(/editmode=/)) {
    return this.root.innerHTML = "Loading Password Reset Form...";
  }
});

this.user = {};

this.handleUpdate = (function(_this) {
  return function(input) {
    return _this.user[input.name] = input.value;
  };
})(this);

this.submit = (function(_this) {
  return function(e) {
    e.preventDefault();
    if (!_this.user.password || _this.user.password.length < 6) {
      return _this.showError({
        message: 'Your Password is too short, please ensure it is over 6 characters'
      });
    }
    if (_this.user.password === _this.user.passwordConfirm) {
      _this.error = false;
      _this.success = false;
      _this.update();
      return api.save(_this.user, function(user, err) {
        if (err != null) {
          _this.showError(err);
        }
        if (err == null) {
          _this.showSuccess('Your password has been successfully changed');
          return setTimeout(function() {
            if (opts.onsuccess != null) {
              return window.location.href = opts.onsuccess;
            }
          }, 2000);
        }
      });
    } else {
      return _this.showError({
        message: 'Passwords do not match'
      });
    }
  };
})(this);

this.showError = (function(_this) {
  return function(errorMessage) {
    _this.errorMessage = errorMessage;
    _this.error = true;
    return _this.update();
  };
})(this);

this.showSuccess = (function(_this) {
  return function(successMessage) {
    _this.successMessage = successMessage;
    _this.success = true;
    setTimeout(function() {
      return window.location.href = "/vle/";
    }, 2000);
    return _this.update();
  };
})(this);
});
    
  